import React from 'react';
import PropTypes from 'prop-types';

const Member = ({ name, image, title }) => (
  <div className="member">
    <img src={image} alt={name} />
    <div className="bold">{name}</div>
    <div className="title">{title}</div>
  </div>
);

Member.propTypes = {
  name: PropTypes.string.isRequired,
  image: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
};

export default Member;
