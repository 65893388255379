import GameObject from "../../Engine/GameObject";
import SpriteRenderer from "../../Engine/Components/SpriteRenderer";
import { CIRCUIT_TAG } from "./Circuit";
import Sprite from "../../Engine/Sprite";
import PlayerStar from "./PlayerStar";

export const TAG_VEHICLE = "Vehicle";

const SPOT_RADIUS = 5;
const DELTA_TIME_POW = 62.5;
const BOT_SPRITE = "/images/full_vehicle_body/sedan_00_%color%_1_4.png";
const AVAILABLE_COLORS = [3, 5, 6, 7, 8, 10];

export default class Vehicle extends GameObject {
  loaded = false;

  isPlayer = false;
  isBot = false;
  username = null;
  sprite = new Image();

  speed = 0;
  nextSpeed = 0;
  acc = 0;

  finished = false;
  direction = 1;
  deltaTime = 0;

  sections = [];
  currentSectionIndex = 0;
  totalDistance = 0;
  distanceTraveled = 0;
  toTravel = 0;
  currentLap = 0;

  // Circuit spots
  circuitSpots = null;
  currentSpot = null;
  nextSpot = null;
  nextSpotIndex = 0;

  spriteRenderer = null;
  raceManager = null;
  circuit = null;

  constructor(data) {
    super();

    this.tag = TAG_VEHICLE;

    this.username = data.username;
    this.isBot = data.bot;
    this.isPlayer = data.player;
    this.sections = data.sections;

    const image = new Image();
    image.src = this.isBot ? this.getBotSpriteUrl() : data.image;

    this.addComponent(
      () =>
        new SpriteRenderer({
          sprite: new Sprite({
            image: image,
            width: 28,
            height: 14,
          }),
          rotation: 180,
        }),
      "SpriteRenderer"
    );
    this.spriteRenderer = this.getComponent("SpriteRenderer");
    this.spriteRenderer.active = false;
  }

  getBotSpriteUrl() {
    const index = Math.floor(Math.random() * AVAILABLE_COLORS.length);
    return BOT_SPRITE.replace("%color%", AVAILABLE_COLORS[index]);
  }

  start() {
    if (this.isPlayer) {
      this.addChild(() => new PlayerStar(), "PlayerStar");
    }

    this.raceManager = this.scene.getGameObject("RaceManager");
    this.circuit = this.scene.getGameObjectByTag(CIRCUIT_TAG);

    this.toTravel = this.sections[0];
    this.totalDistance = this.circuit.length * this.raceManager.laps;
    this.nextSpeed = Math.floor(
      this.sections[this.currentSectionIndex] / this.circuit.deltaTime
    );
    this.acc = this.nextSpeed;

    this.circuitSpots = this.circuit.spots;
    this.currentSpot = this.circuitSpots[this.nextSpotIndex];
    this.nextSpot = this.circuitSpots[this.nextSpotIndex];

    this.transform.position.x = this.currentSpot.x;
    this.transform.position.y = this.currentSpot.y;
  }

  update(event) {
    this.loaded = this.spriteRenderer.sprite.loaded;
    if (this.loaded === false || this.raceManager.ready === false) {
      return;
    }

    this.spriteRenderer.active = this.loaded;
    this.deltaTime = event.deltaTime;
    this.updateCurrentAndNextSpot();
    this.updateSpeed();
    this.moveVehicle();
  }

  updateCurrentAndNextSpot() {
    if (this.isTouchingSpot()) {
      if (this.nextSpotIndex === 0) {
        this.currentLap++;
        this.finished = this.currentLap > this.raceManager.laps;
      }
      this.currentSpot = this.circuitSpots[this.nextSpotIndex];
      this.nextSpotIndex =
        this.nextSpotIndex === this.circuitSpots.length - 1
          ? 0
          : this.nextSpotIndex + 1;
      this.nextSpot = this.circuitSpots[this.nextSpotIndex];
    }
  }

  isTouchingSpot() {
    let minX = this.nextSpot.x - SPOT_RADIUS;
    let maxX = this.nextSpot.x + SPOT_RADIUS;
    let minY = this.nextSpot.y - SPOT_RADIUS;
    let maxY = this.nextSpot.y + SPOT_RADIUS;
    let position = this.transform.position;

    this.direction = this.nextSpot.x > position.x ? -1 : 1;

    return (
      position.x >= minX &&
      position.x <= maxX &&
      position.y >= minY &&
      position.y <= maxY
    );
  }

  updateSpeed() {
    if (this.finished === true) {
      return;
    }

    if (this.distanceTraveled >= this.toTravel) {
      this.currentSectionIndex++;
      this.toTravel += this.sections[this.currentSectionIndex];
      this.nextSpeed = Math.floor(
        this.sections[this.currentSectionIndex] / this.circuit.deltaTime
      );

      this.acc = this.nextSpeed - this.speed;
    }

    if (this.speed !== this.nextSpeed) {
      this.speed = Math.max(
        Math.min(this.speed + this.acc * this.deltaTime, this.nextSpeed),
        this.nextSpeed
      );
    } else {
      this.speed = this.nextSpeed;
    }
  }

  moveVehicle() {
    if (this.speed <= 0) {
      return;
    }

    if (this.finished === true) {
      this.reduceSpeed();
    }

    let angle = Math.atan2(
      this.nextSpot.y - this.transform.position.y,
      this.nextSpot.x - this.transform.position.x
    );

    const distance = {
      x: Math.cos(angle) * this.speed * this.deltaTime,
      y: Math.sin(angle) * this.speed * this.deltaTime,
    };

    this.distanceTraveled += Math.hypot(distance.x, distance.y);
    this.transform.position.x += distance.x;
    this.transform.position.y += distance.y;

    this.transform.rotation = -angle * (180 / Math.PI);
  }

  reduceSpeed() {
    this.speed -= this.speed * 0.08;

    if (this.speed <= 1) {
      this.speed = 0;
    }
  }
}
