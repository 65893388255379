import React from 'react';
import PropTypes from 'prop-types';
import SuccessMessage from '../SuccessMessage/SuccessMessage';
import './LinkWallet.css';
import { SETTINGS } from '../../config/Routes';

const WalletLinked = ({ address }) => (
  <div className="wallet-linked">
    <SuccessMessage
      title="Success!"
      message={[
        <p key="p1">
          {'The address '}
          {' '}
          <span className="bold">{ address }</span>
          {' '}
          {' has been successfully linked!'}
        </p>,
      ]}
      link={SETTINGS}
      linkText="Back to settings"
    />
  </div>
);

WalletLinked.propTypes = {
  address: PropTypes.string.isRequired,
};

export default WalletLinked;
