import React from 'react';
import { Link } from 'react-router-dom';
import './DesignTerms.css';
import { PRIVACY_POLICY } from '../../config/Routes';

const DesignTerms = () => (
  <div className="center design-terms">
    <h1>Design Challenge Terms and conditions</h1>
    <h2>Introduction</h2>
    <p>
      CryptoMotors is proud to announce the second edition of the “CryptoMotors Design Challenge”
      that runs on its platform. Everyone who is interested in car design is invited to be a part
      of this competition by presenting design proposals or voting for the work from other
      participants.The winning designs will be 3D modeled in Autodesk Alias by the CryptoMotors
      team, and then visualized and tokenized, and put on sale in CryptoMotors marketplace,
      making these models the next generation of cars to be sold in CryptoMotors’ platform and
      stored in the Ethereum blockchain.
    </p>
    <p>
      For this edition of the CryptoMotors Design Challenge, we have partnered with Gravity Sketch.
      Thanks to the integration of Gravity Sketch’s VR sketching software, we are introducing a
      futuristic design tool that will allow the winners of the Design Challenge to explore their
      designs in VR.
    </p>
    <p>
      We have also partnered with Auctionity, the world&apos;s largest blockchain auction house for
      crypto collectibles. Auctionity will host auctions for special editions of cars that come
      as a result of the different CryptoMotors design challenges.
    </p>
    <h2>Entering the “Design Challenge”</h2>
    <p>
      It’s not necessary or mandatory to make any purchase or payment in order to enter and
      participate in any Design Challenge as a “designer” or “user” on the CryptoMotors platform.
      The only requirement for Designers is to register to the competition with a nickname (or
      real name if you prefer) and an email address. For the “users” who are voting, 3 votes will
      be available per stage.
    </p>
    <p>
      By selecting &quot;I agree the terms and conditions&quot; at the time of submission of the
      registration
      form you agree that your submission (the &quot;Design&quot;) will be used as a starting point
      to the
      development of the 3D data of a crypto-collectible (ERC721 Token) by CryptoMotors UG
      (haftungsbeschränkt) ”CryptoMotors” or “we”; and that it will be offered on the platform
      (https://www.cryptomotors.io) for sale or in any affiliate platform and you agree to its
      possible use on video games, movies, vehicle production, 3d printing, toys, scale models,
      clothing apparels, posters, and other items including crypto collectibles parts and
      marketing material you agree to the following terms and conditions (the &quot;Design
      Submission
      Terms and Conditions&quot;) and you indicate your agreement to be bound by these terms and
      conditions.:
    </p>
    <p>
      For a Design to be eligible, it must not have be previously presented in any other commercial
      or non-commercial competition or contest where its entry would permit that the
      contest/competition or the other entity/company present any legal claim or rights
      to the Design prior to submission in this Design Challenge. Any entries that arrive after
      the closing submission date, incorrect, incomplete, lost or misdirect will be disqualified
      from the preselection of the first stage, and CryptoMotors (and its agents) will not be
      responsible for such entries, after “pre-selection phase” a public voting will decide during
      the competition who are the winning designs to be 3D designed/modeled by CryptoMotors team
      and later on tokenized and sold in the platform. You understand that CryptoMotors may
      receive multiple design submissions that may be similar one to another, not excluding your
      own Design and that CryptoMotors reserves the right disqualify a Design or exclude it from
      the competition for any reason during pre-selection or during the second stage of
      submission, including but not limited to late submission, poor design execution, copyright
      infringement, indecent or profane content incorporated into the Design or violation of
      the Design Submission Terms and Conditions. Designs will not be returned to entrants, and
      you understand that not every Design will be selected during the preselection stage. More
      than one Design is allowed per entrant in the same Design Contest. CryptoMotors is expressly
      permitted to disclose your Design at any time and place under these Design Submission Terms
      and Conditions.
    </p>
    <h2>Who can participate and who is eligible</h2>
    <p>
      CryptoMotors Design Challenge is open to residents of any country except employees of
      CryptoMotors and their close relatives and anyone otherwise connected with the organization
      or judging of the competition. You are not obliged to have any diploma or certification, and
      there are no borders or restrictions for your creativity. The only thing you need is passion.
      By uploading and sharing your Design, you declare that you are age eighteen (18) or over and
      agree on all the terms and conditions of the CryptoMotors Design Challenge. Multiple entries
      from the same person are accepted. You also declare that your design is created by your own
      and that you are the sole owner of all the rights of the Design. You either declare that you
      not had or will not violate any design rights of a third person or party. You declare that
      your design wasn&apos;t previously in commercial use and that you not transferred any rights
      of
      the Design to any person or party.
    </p>
    <p>
      As a participant of the Design Challenge, you agree that your Design will be shown up on
      CryptoMotors’ platform and the platforms of CryptoMotors’s partners. CryptoMotors is allowed
      to use your Design for advertising and marketing purposes. If your Design is one of the final
      winners you will help and support the CryptoMotors design team with further input for the
      creation of the 3D data, and you are aware that the Design can be modified during this
      development process. CryptoMotors team intends to follow as much as possible the design
      intention, but you agree that the final output can have several differences to the original 2D
      sketch or the input and that the ultimate 3D creation belongs to the CryptoMotors UG company
      having all the rights to it.
    </p>
    <p>
      By submitting your design, you agree that you will not use the same Design for other
      competitions or commercial and non-commercial purposes for the next 8 months after joining the
      competition.
    </p>
    <p>
      You can request that we stop using the design by notifying CryptoMotors by email at
      {' '}
      <a href="mailto:info@cryptomotors.io">info@cryptomotors.io</a>
      .
    </p>
    <p>
      You warrant that you, as one of the winners of the Design Challenge, grant CryptoMotors and
      the partners of CryptoMotors the permission and a worldwide transferable license to modify,
      use, tokenize, visualize, commercialize and manufacture the design. You agree that the
      royalties of the winning design are 10% of each direct sell (“brand new cars”) of your Design
      on our platform and crypto-collectible exchanges like Opensea.io. And you are entitled to a
      10% royalty in case that the Design is sold to a third party by CryptoMotors.
    </p>
    <p>
      You declare that you comply with the laws of our jurisdiction of Germany and that your
      participation is not prohibited by the laws of the jurisdiction of your residence country.
    </p>
    <h2>How the competition and the winner selection works</h2>
    <p>
      Our design competition is open to students, enthusiasts, freelancers, and professionals in the
      design industry. The design challenge is divided into three (3) stages, including the
      submission, ideation process, and pre-selection phase. The design brief has been prepared by
      CryptoMotors (scenario, persona, sound & vibe) to guide designers during the ideation process.
    </p>
    <p>
      The first stage (ideation stage) lasts a maximum of 3 weeks where the designers have to submit
      a minimum of -but not limited to- 3 design PSD renderings of the car per entry. The three
      sketches have to include a 3/4 front view, a 3/4 rear view, and a side view. More than one
      entry can be submitted per person, but they must be uploaded as individual entries. Once the
      first stage has been closed, CryptoMotors will select the proposals that will go to the next
      stage. The selection criteria will be based on how well the design proposal fits the brief and
      CryptoMotor’s quality standards (judged internally by CryptoMotors team). Once you have been
      selected to move to stage two, your work will be displayed on our website, and you will get a
      personal notification. The public voting, carried by the community and open to anyone
      registered on the website, starts as soon as your proposal is uploaded to CryptoMotors
      platform. After the last day of the public voting, the five participants whose design has
      obtained the most votes will be declared the winners of the Design Challenge. The winners’
      name will be displayed on CryptoMotors website and will get a personal notification.
    </p>
    <p>
      In the second stage (VR sketching), CryptoMotors will ask the winning designers to proceed
      with their design proposals to further develop in VR using Gravity Sketch software. This VR
      model will help CryptoMotors Alias modelers to kick start the Surfacing modeling process.
    </p>
    <p>
      The third stage is the 3D modeling, visualization, and tokenization phase where CryptoMotors
      will transform the design from the first 3 winners into a 3D Alias Surface Model made by
      CryptoMotors highly professional modeling team, and it will become an ERC-721 crypto
      collectible. The winning designers commits to support the CryptoMotors team until the end of
      the process, being this an exciting opportunity for the winner. The designs will be modeled
      one at a time, starting with 1st prize and then moving to 2nd and 3rd prize. After the design
      has been transformed into a 3D model, CryptoMotors visualization team will take care of its
      final photorealistic look. The car will be visualized in exciting environments, tokenized and
      launched for sale on CryptoMotors platform. Read further to find out what the prizes are.
    </p>
    <p>
      Timeline:
      <br />
      9:00 AM GMT+1 - 8th of July 2019: the opening of Design Challenge and registration
      <br />
      9:00 AM GMT+1 - 15th of July 2019: the opening of the Design Challenge submission
      <br />
      12:00 PM GMT+1 - 28st of July 2019: Closing of Design Challenge submissions
    </p>
    <p className="bold">
      9:00 AM GMT+1 - 31st of July 2019: Public Voting starts
      <br />
      12:00 PM GMT+1 - 4th of August 2019: Public Voting ends
      <br />
      6:00 PM GMT+1 - 5th of August 2019: Announcement of Winners
      <br />
    </p>
    <p>
      9:00 AM GMT+1 - 6th of August: 2nd Stage / VR sketching using Gravity Sketch (approx 3 weeks)
    </p>
    <p>
      Date and Time To be defined : Stage 3 is done by the CryptoMotors design team (with the
      support of the winning designers) where the car is 3D modeled, visualized and tokenized.
    </p>
    <p>
      To be eligible for the competition please follow these rules.
      <ol>
        <li>The design challenge is open to students and professionals from any design industry</li>
        <li>Submit your work on time. You’ll find the exact dates on the timeline</li>
        <li>
          Submit your work in the right format. Aspect ratio: 16:9, image quality: 1920x1080 px,
          format: jpg or png
        </li>
        <li>
          For the first stage, you must submit a minimum of 3 views of the car: 3/4 front view,
          3/4 rear view, side view.
        </li>
        <li>
          In case of winning, you commit to develop your design using Gravity Sketch software.
        </li>
        <li>
          In order to vote, users must log into CryptoMotors.io. They’ll have a limited amount of
          3 votes per stage.
        </li>
        <li>The design work must be relevant to the competition&apos;s topic and 100% original.</li>
      </ol>
    </p>
    <p>
      Each user gets three votes rights that cannot be assigned just to one participant and must be
      distributed between different proposals.
    </p>
    <h2>Prize and Payment conditions</h2>
    <p>
      Thanks to our collaboration with Gravity Sketch, you will get a FREE Gravity Sketch software
      license for a period of 12 months. The top two designers will additionally be provided with VR
      headsets to use during the Design Challenge
    </p>
    <p>
      As a winner of the Design Challenge, you must count with a crypto-wallet that supports ERC-721
      and ERC-20 tokens. We suggest users to create this wallet using Metamask (plug-in for Chrome
      or
      Firefox browsers). It’s is free of charge and it’s your sole responsibility to secure your
      passwords and private keys.
    </p>
    <p>
      The first three (3) winning designs will be 3D modeled in Autodesk Alias by the CryptoMotors
      design team and later on visualized, tokenized and put on sale in CryptoMotors platform and
      other crypto collectibles platforms.
    </p>
    <p>
      The first three (3) winners will receive a 10% commission of each sale generated from the
      ERC-721 CryptoMotors digital collectible associated to his initial design idea. The
      CryptoMotors
      Market smart contract will automatically send the prize in ETH (ether) to the winner’s crypto
      wallet, this is the reason why it’s a must to set up an ether wallet to receive the prize. The
      smart contract will stop transacting the 10% commission once the last unit of the
      crypto-collectible lot is sold.
    </p>
    <p>
      As a part of the prize, the top 3 winners will receive one (1) CryptoMotors ERC-721 digital
      collectible associated with their design idea.
    </p>
    <p>
      Special mentions: Participants who end up on places 4 and 5 of the Design Challenge will be
      entitled to one (1) CryptoMotors ERC-721 collectible associated with one of the winning
      designs.
    </p>
    <p>
      If you&apos;d like to be featured later on a CryptoMotors after-movie, we urge you to take
      some
      screenshots and photos of your design process.
    </p>
    <p>
      You will comply with all the tax obligations applicable in your jurisdiction which arise from
      the prize of the competition, purchase, the procurement or the exchange of cross-platform
      articles, if necessary.
    </p>
    <h2>Winner Notification</h2>
    <p>
      The winners will be notified by email and/or DM on Twitter/Facebook and/or email within a
      maximum of 3 days to participate in the Stage two (2) and how to receive a VR google to
      complete
      the VR sketching stage. The three (3) winners acknowledges that he/she must participate and
      collaborate actively with the CryptoMotors team during the 3rd stage by providing all the
      needed
      input.
    </p>
    <p>
      By entering this competition, an entrant is indicating his/her agreement to be bound by these
      terms and conditions.
    </p>
    <p>
      CryptoMotors is not responsible for any delay or failure to receive notification for any
      reason,
      including inactive email account(s), technical difficulties associated with, or a winner’s
      failure to adequately monitor any email account.
    </p>
    <p>
      A VR headset will be temporarily given to the winner designers if he/she doesn’t have access
      to
      one. The headset must be handled carefully and must be returned to CryptoMotors or Gravity
      Sketch company as soon as the Stage two (2) is completed.
    </p>
    <p>
      Any winner notification not responded to or returned as undeliverable may result in prize
      forfeiture. The potential prize winner may be required to sign and return an affidavit of
      eligibility and release of liability, and a Publicity Release (collectively &quot;the Prize
      Claim Documents&quot;). No substitution or transfer of a prize is permitted except by
      CryptoMotors.
    </p>
    <h2>Liability</h2>
    <p>
      CryptoMotors shall endeavor to offer the service for access without interruptions as far as
      possible. Even when applying the greatest care, it is not possible to prevent downtimes when
      the
      web server cannot be accessed via the Internet on account of technical or other problems which
      are beyond the control of CryptoMotors. The user acknowledges that the full availability
      (100%)
      of the website cannot be technically realized and CryptoMotors UG is not responsible for any
      technical, hardware or software failures whether caused by CryptoMotors or you.
    </p>
    <p>
      CryptoMotors reserves the right to cancel or amend the competition and these terms and
      conditions without notice in the event of a catastrophe, war, civil or military disturbance,
      act
      of God or any actual or anticipated breach of any applicable law or regulation or any other
      event outside of the promoter’s control. Any changes to the competition will be notified to
      entrants as soon as possible by CryptoMotors.
    </p>
    <p>
      1. As a participant Designer, winner designer or user you are responsible for the
      implementation
      of reasonable measures to safeguard the Wallet, safe or another storage mechanism that you use
      for the purchase, exchange or use of platform-internal elements, tokens and/or other objects
      on
      the CryptoMotors platform, currently consisting of an app and a website, including all the
      required private passwords or other access data that are required for access to these storage
      mechanisms. As a platform organizer, CryptoMotors is not responsible and shall remain not
      accountable for losses entailed in the loss of your access data or in third-party access to
      your
      access data.
    </p>
    <p>
      2. Through the participation on design challenges and the use of the CryptoMotors platform,
      you
      declare and guarantee the following:
    </p>
    <p>
      a) You have a sufficient understanding of and experience with cryptographic tokens
      (particularly
      Ethereum), token storage mechanisms (e.g., token Wallets) and blockchain technology in order
      to
      understand these terms and understand the risks and effects of the acquisition, the
      procurement
      and the exchange of platform-internal elements on Ethereum.
      <br />
      b) You have read and understood these terms (including all the annexes, if available).
      <br />
      c) You have received sufficient information on the CryptoMotors platform to make an informed
      decision on the purchase, holding or exchange of platform-internal articles and/or tokens.
      <br />
      d) You will not purchase, hold or exchange platform-internal articles and/or tokens for
      purposes
      other than to take part in the CryptoMotors platform or that of our partners and the platform
      functions declared in these Terms of Use on the website and/or on the app.
      <br />
      e) Your purchase, your procurement and your exchange of articles and tokens tied to the
      platform
      comply with applicable laws and regulations in your place of jurisdiction, including but not
      limited to (i) the legal capacity and all other threshold requirements in your jurisdiction
      for
      the use and payment of Ether and the conclusion of contracts with the platform organiser, (ii)
      all the foreign currency or regulatory restrictions for this purchase and (iii) all the
      government or other approvals that must be obtained.
      <br />
      f) You will comply with all the tax obligations applicable in your jurisdiction which arise
      from
      the purchase, the procurement or the exchange of cross-platform articles, if necessary.
      <br />
      g) If you purchase, hold or exchange platform-independent articles or tokens in the name of a
      natural or legal person, you are entitled to accept these terms in the name of this natural or
      legal person and this natural or legal person shall be responsible for any infringement of
      these
      terms by you or this natural or legal person or another employee or representative of this
      person.
      <br />
      h) You agree that when using the platform, there is a risk that digital assets in the form of
      cryptocurrency can be lost, particularly through the loss of private keys, and that the
      platform
      organizer is not liable to you for any such loss.
    </p>
    <p>
      The winner agrees that by accepting the prize CryptoMotors UG and its employees, contractors,
      and its officers and directors will have no liability for any injuries whatsoever, losses, or
      damages of any kind resulting from the winners’ acceptance, possession or use of the prize, or
      this Design Challenge. All the prizes are awarded “as is” and without any warranty of any sort
      (express or implied).
    </p>
    <p>
      The winner has the right to remain anonymous and he agrees to the use of his/her nickname in
      any
      publicity material, as well as their entry. Any personal data relating to the winner or any
      other entrants will be used solely in accordance with current German data protection
      legislation
      and will not be disclosed to a third party without the entrant’s prior consent.
    </p>
    <p>
      The competition and these terms and conditions will be governed by the laws of Germany and any
      disputes will be subject to the exclusive jurisdiction of the courts of Germany.
    </p>
    <h2>Data protection and Social Network Disclaimer</h2>
    <p>
      This promotion is in no way sponsored, endorsed or administered by, or associated with,
      Facebook, Twitter or any other Social Network. You are providing your information to
      CryptoMotors UG (haftungsbeschränkt) and not to any other party.
    </p>
    <p>
      All personal data are collected, saved, processed and used in accordance with applicable data
      protection laws and our Privacy Statement. To read our Privacy Statement, please click here:
      {' '}
      <Link to={PRIVACY_POLICY}>https://www.cryptomotors.io/privacy-policy</Link>.
    </p>
    <h2>Amendments to the Terms of Use</h2>
    <p>
      We reserve the right to make changes to our services such as the website and the app and amend
      sets of rules, conditions, including these Design Submission Terms of Use at any time. They
      are
      subject to the respective conditions in force at the time when you use our services.
    </p>
    <h2>General Conditions</h2>
    <p>
      Should individual provisions of these Design Submission Terms and conditions be invalid, this
      shall not affect the validity of the remaining provisions. Invalid provisions shall be
      replaced
      by those that most closely approximate the meaning intended by the invalid provision. The same
      applies in the event of the occurrence of any gaps which need to be filled. If, for any
      reason,
      the Design Challenge is not capable of running as planned for reasons which may include
      without
      limitation, infection by computer virus, tampering, unauthorized intervention, fraud,
      technical
      failures, or any other causes which may corrupt or affect the administration, security,
      fairness, integrity or proper conduct of this Design Challenge, CryptoMotors reserves the
      right
      at its sole discretion to cancel, terminate, modify or suspend the Design Challenge in whole
      or
      in part. In such event, CryptoMotors shall immediately suspend all prize awards, and
      CryptoMotors reserves the right to award any remaining prizes in a manner deemed fair and
      equitable by CryptoMotors. CryptoMotors shall not have any further liability to any
      participant
      in connection with the Design Challenge. If a user is a merchant, the place of jurisdiction
      for
      all disputes arising under contractual relationships between the user and the company is the
      registered office of the company. CryptoMotors can stop this Design Challenge. In the event of
      any non-compliance with any requirement stated herein, the winner&apos;s Prize may be
      forfeited and awarded to an alternate winner. The competition and these terms and conditions
      will be governed by the laws of Germany and any disputes will be subject to the exclusive
      jurisdiction of the courts of Germany. Any attempt by any person to damage the website or
      undermine the legitimate operation of the Contest may be a violation of criminal and civil
      law, and, should such an attempt be made, CryptoMotors reserves the right to seek damages
      (including attorneys fees) and any other remedies from any such person to the fullest extent
      permitted by law. Failure by CryptoMotors to enforce any provision of these Official Rules
      shall not constitute a waiver of that provision.
    </p>
  </div>
);

export default DesignTerms;
