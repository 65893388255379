import React from "react";
import { Link, Redirect } from "react-router-dom";
import PropTypes from "prop-types";
import "../../../node_modules/react-responsive-carousel/lib/styles/carousel.css";
import ImageGallery from "../ImageGallery/ImageGallery";
import Headline from "../Headline/Headline";
import SplitSection from "../SplitSection/SplitSection";
import "./MarketCar.css";
import {
  MARKET_CAR,
  MODEL_ABYSSUS,
  MODEL_NEON_ROADSTER,
  MODEL_RETRO_ROCKET,
  MODEL_SEDAN,
  MODEL_SPORT_COUPE,
  MODEL_SUV,
  NOT_FOUND,
} from "../../config/Routes";
import MODELS from "../../config/Models";
import StatBar from "../StatBar/StatBar";

const MarketCar = ({
  match: {
    params: { model },
  },
}) =>
  !MODELS[model] ? (
    <Redirect to={NOT_FOUND} />
  ) : (
    <div className={`market-car ${model}`}>
      <div className="market-car-menu">
        <ul>
          <li>
            <Link
              to={MARKET_CAR.replace(":model", MODEL_SEDAN)}
              className={model === MODEL_SEDAN ? "active" : ""}
            >
              Sedan
            </Link>
          </li>
          <li>
            <Link
              to={MARKET_CAR.replace(":model", MODEL_SPORT_COUPE)}
              className={model === MODEL_SPORT_COUPE ? "active" : ""}
            >
              Sport Coupe
            </Link>
          </li>
          <li>
            <Link
              to={MARKET_CAR.replace(":model", MODEL_SUV)}
              className={model === MODEL_SUV ? "active" : ""}
            >
              SUV
            </Link>
          </li>
          <li>
            <Link
              to={MARKET_CAR.replace(":model", MODEL_ABYSSUS)}
              className={model === MODEL_ABYSSUS ? "active" : ""}
            >
              Abyssus
            </Link>
          </li>
          <li>
            <Link
              to={MARKET_CAR.replace(":model", MODEL_NEON_ROADSTER)}
              className={model === MODEL_NEON_ROADSTER ? "active" : ""}
            >
              Neon Roadster
            </Link>
          </li>
          <li>
            <Link
              to={MARKET_CAR.replace(":model", MODEL_RETRO_ROCKET)}
              className={model === MODEL_RETRO_ROCKET ? "active" : ""}
            >
              Retro Rocket
            </Link>
          </li>
        </ul>
        <hr />
      </div>
      <div className="market-car-main-image">
        <img src={MODELS[model].images.front} alt="main" />
      </div>
      <div className="market-car-header">
        <h1>{MODELS[model].name}</h1>
        <h2>
          {model === MODEL_RETRO_ROCKET
            ? "Design Legends Generation"
            : `Generation - ${MODELS[model].generation}`}
        </h2>
        <hr />
        <a
          href={MODELS[model].openSea}
          target="_blank"
          rel="noopener noreferrer"
          className="button primary big"
        >
          {model === MODEL_RETRO_ROCKET ? "View at opensea" : "Buy now"}
        </a>
      </div>
      <SplitSection
        title={MODELS[model].title1}
        description={MODELS[model].description1}
        image={MODELS[model].images.side}
        theme="white"
        inverted
      />
      <SplitSection
        title={MODELS[model].title2}
        description={MODELS[model].description2}
        image={MODELS[model].images.back}
        theme="white"
      />
      <div className="statistics">
        <h3>Statistics</h3>
        <div className="white left padding-vertical-small">
          <div className="center">
            <div className="stats">
              <div className="stats-image">
                <span className="title">top view</span>
                <hr />
                <img alt="stats" src={MODELS[model].images.stats} />
              </div>
              <div className="stats-chart">
                <span className="title">stats</span>
                <hr />
                <StatBar
                  min={MODELS[model].stats.speed.min}
                  max={MODELS[model].stats.speed.max}
                  name="Max Speed"
                />
                <StatBar
                  min={MODELS[model].stats.acceleration.min}
                  max={MODELS[model].stats.acceleration.max}
                  name="acceleration"
                />
                <StatBar
                  min={MODELS[model].stats.grip.min}
                  max={MODELS[model].stats.grip.max}
                  name="grip"
                />
                <StatBar
                  min={MODELS[model].stats.braking.min}
                  max={MODELS[model].stats.braking.max}
                  name="braking"
                />
                <StatBar
                  min={MODELS[model].stats.handling.min}
                  max={MODELS[model].stats.handling.max}
                  name="handling"
                />
              </div>
            </div>
            <div className="description-block">
              <p className="title">
                <span className="nominator">
                  {MODELS[model].highlightedStat.integer}.
                  {MODELS[model].highlightedStat.decimal}
                </span>
                <span className="stat view-car mb-5">
                  {MODELS[model].highlightedStat.name}
                </span>
              </p>
              <hr />
              <p className="description">{MODELS[model].description3}</p>
            </div>
          </div>
        </div>
      </div>
      {model !== MODEL_RETRO_ROCKET && (
        <div className="gallery">
          <ImageGallery images={MODELS[model].gallery} />
        </div>
      )}
      <Headline showArrow={false} />
    </div>
  );

MarketCar.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({ model: PropTypes.string.isRequired }).isRequired,
  }).isRequired,
};

export default MarketCar;
