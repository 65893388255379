import React, {createContext, useState, useEffect} from 'react';

export const RaceContext = createContext();

const RaceProvider = (props) => {

    const [lastrace, setLastRace] = useState('');
    
    useEffect(() => {        
        if(lastrace !== ''){            
            localStorage.setItem('lastRace', lastrace);
        }else{
            const lastRace = localStorage.getItem('lastRace');                    
            if(lastRace !== '' && lastRace !== null && lastRace !== 'null'){
                setLastRace(lastRace);                         
            }            
        }    
    }, [lastrace]);
    
    const saveLastRace = link => {                  
        setLastRace(link);
    }

    return (
        <RaceContext.Provider
            value={{
                lastrace,
                saveLastRace
            }}
        >
            {props.children}
        </RaceContext.Provider>
    )
}

export default RaceProvider;