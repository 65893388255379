import Component from "../Component";
import Vector2 from "../Helper/Vector2";

export default class Transform extends Component {
  position = new Vector2(0, 0);
  scale = new Vector2(1, 1);
  innerPosition = new Vector2(0, 0);
  rotation = 0;
  parentTransform = null;

  start() {
    this.update();
  }

  update() {
    if (this.parentTransform === null && this.gameObject.parent !== null) {
      this.parentTransform = this.gameObject.parent.transform;
    }

    if (this.parentTransform !== null && this.gameObject.parent === null) {
      this.parentTransform = null;
    }

    if (this.parentTransform !== null) {
      this.position.x = this.parentTransform.position.x + this.innerPosition.x;
      this.position.y = this.parentTransform.position.y + this.innerPosition.y;
      this.translateFromParent();
    } else {
      this.innerPosition.x = this.position.x;
      this.innerPosition.y = this.position.y;
    }
  }

  translateFromParent() {
    const angle = (this.parentTransform.rotation * Math.PI) / 180.0;
    const radius = Math.hypot(
      this.position.x - this.parentTransform.position.x,
      this.position.y - this.parentTransform.position.y
    );

    this.position.x =
      this.parentTransform.position.x + radius * Math.cos(angle);
    this.position.y =
      this.parentTransform.position.y - radius * Math.sin(angle);
    this.rotation = this.parentTransform.rotation;
  }
}
