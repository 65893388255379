import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import {
  ABOUT, FAQ, HOME, IMPRESSUM, MARKET, PRIVACY_POLICY, TERMS_OF_USE, VISION,
} from '../../config/Routes';
import './FooterMobile.css';

const FooterMobile = ({ pathname }) => (
  <div className="center footer-mobile">
    <div className="links-left">
      <ul>
        <li>
          <Link
            to={HOME}
            className={pathname === HOME ? 'active' : ''}
          >
            Home
          </Link>
        </li>
        <li>
          <Link
            to={MARKET}
            className={pathname.includes('/market/') ? 'active' : ''}
          >
            Market
          </Link>
        </li>
        <li>
          <Link
            to={ABOUT}
            className={pathname === ABOUT ? 'active' : ''}
          >
            About
          </Link>
        </li>
        <li>
          <Link
            to={VISION}
            className={pathname === VISION ? 'active' : ''}
          >
            Vision
          </Link>
        </li>
      </ul>
    </div>
    <div className="links-right">
      <ul>
        <li>
          <Link
            to={IMPRESSUM}
            className={pathname === IMPRESSUM ? 'active' : ''}
          >
            Impressum
          </Link>
        </li>
        <li>
          <Link
            to={TERMS_OF_USE}
            className={pathname === TERMS_OF_USE ? 'active' : ''}
          >
            Terms of use
          </Link>
        </li>
        <li>
          <Link
            to={PRIVACY_POLICY}
            className={pathname === PRIVACY_POLICY ? 'active' : ''}
          >
            Privacy policy
          </Link>
        </li>
        <li>
          <Link
            to={FAQ}
            className={pathname === FAQ ? 'active' : ''}
          >
            Faq
          </Link>
        </li>
      </ul>
    </div>
    <div className="isologotype">
      <img src="/images/logo-white.svg" alt="isologo" />
    </div>
    <div className="social">
      <a href="//www.instagram.com/CryptoMotors/" target="_blank" rel="noopener noreferrer"><i className="fab fa-instagram" /></a>
      <a href="//twitter.com/cryptomotors_io" target="_blank" rel="noopener noreferrer"><i className="fab fa-twitter" /></a>
      <a href="//www.facebook.com/CryptoMotors/" target="_blank" rel="noopener noreferrer"><i className="fab fa-facebook-f" /></a>
      <a href="//t.me/cryptomotors" target="_blank" rel="noopener noreferrer"><i className="fab fa-telegram-plane" /></a>
    </div>
    <div className="copyright">
      Copyright © 2019 CryptoMotors UG (haftungsbeschränkt).
      <br />
      All rights reserved.
    </div>
  </div>
);

FooterMobile.propTypes = {
  pathname: PropTypes.string.isRequired,
};

export default FooterMobile;
