import React, { Component } from 'react';
import { Redirect } from 'react-router';
import { Link } from 'react-router-dom';
import AuthContext from '../../context/AuthContext';
import './LogIn.css';
import {
  HOME,
  SIGN_UP,
  RECOVER_PASSWORD,
  ORIGIN_DESIGN_CHALLENGE,
  DESIGN_CHALLENGE_DASHBOARD,
} from '../../config/Routes';
import { login } from '../../requests/Authentication';
//import { FB_AUTH_URL } from '../../config/Config';

const INVALID_USER_OR_PASSWORD = (
  <span>
    The email and password provided are not valid. Did you forget your password? Click
    {' '}
    <Link to={RECOVER_PASSWORD} className="secondary">Here</Link>
    {' '}
    to recover it.
  </span>
);

class LogIn extends Component {
  static contextType = AuthContext;

  state = {
    isLoading: false,
    email: '',
    password: '',
  };

  handleChangeEmail = ({ target: { value } }) => {
    this.setState({ email: value });
  };

  handleChangePassword = ({ target: { value } }) => {
    this.setState({ password: value });
  };

  handleLogin = (e) => {
    e.preventDefault();
    const { email, password } = this.state;

    this.setState({ isLoading: true });

    login(email, password)
      .then(({
        data:
        {
          expiresAt,
          token,
          refreshToken,
          user: { username },
        },
      }) => {
        this.setState({
          isLoading: false,
        });

        this.context.login(token, refreshToken, expiresAt, username);
      })
      .catch(() => {
        this.setState({
          errorMessage: INVALID_USER_OR_PASSWORD,
          isLoading: false,
        });
      });
  };

  render() {
    const { authenticated } = this.context;
    const {
      email,
      password,
      errorMessage,
      isLoading,
    } = this.state;

    const origin = localStorage.getItem('origin');
    if (authenticated && origin === ORIGIN_DESIGN_CHALLENGE) {
      return <Redirect to={DESIGN_CHALLENGE_DASHBOARD} />;
    }

    if (authenticated) {
      return <Redirect to={HOME} />;
    }

    return (
      <div className="log-in">
        <div className="center">
          <h1>Log in</h1>
          <form onSubmit={this.handleLogin}>
            <p>
              <input
                type="text"
                name="email"
                placeholder="Email"
                onChange={this.handleChangeEmail}
                defaultValue={email}
              />
            </p>
            <p>
              <input
                type="password"
                name="password"
                placeholder="Password"
                onChange={this.handleChangePassword}
                defaultValue={password}
              />
            </p>
            <p className="error-message">
              {errorMessage}
            </p>
            <div className="log-in-div">              
              <button type="submit" className="primary" onClick={this.handleLogin}>
                {isLoading
                  ? <i className="fas fa-spinner fa-spin" />
                  : 'Log in'
                }
              </button>
            </div>
          </form>
          <div className="create-an-account">
            Not registered yet? Sign up
            { ' ' }
            <Link className="tertiary" to={SIGN_UP}>here</Link>
          </div>
        </div>
      </div>
    );
  }
}

export default LogIn;
