import React, { Component, Fragment } from 'react';
import { Link, matchPath } from 'react-router-dom';
// import { Link as ScrollLink, Element } from 'react-scroll';
import styled from 'styled-components';
import { Parallax, ParallaxProvider } from 'react-scroll-parallax';
import Masonry from 'react-masonry-css';
import './DesignChallengeDashboard.css';
import Modal from 'react-responsive-modal';

import axios from 'axios';
import AuthContext from '../../context/AuthContext';
import {
  DESIGN_CHALLENGE, DESIGN_CHALLENGE_DASHBOARD,
  DESIGN_CHALLENGE_DASHBOARD_MODAL,
  //ORIGIN_DESIGN_CHALLENGE,
  //SIGN_UP,
} from '../../config/Routes';
import DesignCard from './DesignCard';
import { API_URL, CHALLENGE_UUID, DESIGN_IMAGE_URL } from '../../config/Config';
import { getAuthenticatedUser } from '../../requests/User';

const Content = styled.div`
  display: flex;
  background: #F0EFEF;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-weight: regular;
  padding: 0 2rem;
  
  h2 {
    font-size: 30px;
    color: black;
    margin-bottom: 41px;
    margin-top: 41px;
  }

  h3 {
    font-size: 16px;
    color: black;
    margin-top: 0px;
  }
`;

const ArrowDown = styled.div`
  width: 9px;
  height: 9px;
  margin-top: 3.5rem;
  border-right-style: solid;
  border-bottom-style: solid;
  border-right-color: black;
  border-bottom-color: black;
  border-right-width: 2px;
  border-bottom-width: 2px;
  transform: rotateZ(45deg);
  margin-bottom: 4rem;
`;

const Header = styled.div`
  background: url('/images/design-challenge-2/header_1920.jpg') no-repeat center center;
  background-size: auto 100%;
  background-color: #000000;
  height: 40rem;
  width: 100%;
  padding-bottom: 3rem;
  display: flex;
  justify-content: center;
  align-items: center;

  h1 {
    margin: 0 0 2.5rem 0;
    font-size: 7.5rem;
  }

  h2 {
    margin: .5rem 0;
    font-size: 3rem;
  }

  @media screen and (max-width: 786px) {
    h1 {
      font-size: 3.5rem;
    }

    h2 {
      font-size: 2rem;
    }
  }
`;

const ButtonsContainer = styled.div`
  > a {
    margin: 10px;
  }

  @media screen and (max-width: 786px) {
      display: flex;
      flex-direction: column;
  }
  
`;

const IMAGES_BASE_PATH = '/challenge/';

class DesignChallengeDashboard extends Component {
  static contextType = AuthContext;

  state = {
    isLoading: true,
    isModalOpen: false,
    designs: [],
    currentDesignId: null,
    userVotes: 0,
    votes: [],
  };

  componentDidMount() {
    this.getDesignIdFromUrl();
    axios({
      method: 'GET',
      url: `${API_URL}/challenges/${CHALLENGE_UUID}/users?${new Date().getTime()}`,
    })
      .then(response => this.setState({
        isLoading: false,
        designs: response.data,
      }));

    this.context.checkToken();
    if (!this.context.authenticated) {
      return;
    }

    getAuthenticatedUser(this.context.token)
      .then(({
        data: {
          countVotes,
          votes,
        },
      }) => this.setState({
        userVotes: countVotes,
        votes: votes.map(vote => vote.design.id),
      }));
  }

  componentDidUpdate() {
    const design = this.getDesignIdFromUrl();
    if (this.state.currentDesignId !== design) {
      this.setState({ currentDesignId: design, isModalOpen: true });
    }
  }

  getDesignIdFromUrl = () => {
    const match = matchPath(window.location.pathname, {
      path: DESIGN_CHALLENGE_DASHBOARD_MODAL,
    });

    if (!match) {
      return null;
    }

    return match.params.design;
  };

  getDesignById = (id) => {
    const { designs } = this.state;

    return designs.find(design => parseInt(design.id, 10) === parseInt(id, 10));
  };

  modalOpenHandler = (currentDesignId) => {
    this.setState({ isModalOpen: true, currentDesignId });
    window.history.pushState('DesignChallenge', 'CryptoMotors', `${DESIGN_CHALLENGE_DASHBOARD}/${currentDesignId}`);
  };

  modalCloseHandler = () => {
    this.setState({ isModalOpen: false, currentDesignId: null });
    window.history.pushState('DesignChallenge', 'CryptoMotors', `${DESIGN_CHALLENGE_DASHBOARD}/`);
  };

  hasVote = (designId) => {
    const { votes } = this.state;

    const vote = votes.find(v => v === designId);

    return vote !== undefined;
  };

  handleVote = (designId) => {
    // votes are closed
    return;

    /* const { token, authenticated } = this.context;
    if (!authenticated) {
      window.location = `${SIGN_UP}?origin=${ORIGIN_DESIGN_CHALLENGE}`;

      return;
    }

    const { votes, designs } = this.state;
    let { userVotes } = this.state;
    const isVote = votes.indexOf(designId) === -1;

    if (isVote) {
      if (userVotes >= 3) {
        return;
      }

      userVotes += 1;
      votes.push(designId);
      designs.forEach((design) => {
        if (design.id === designId) {
          design.countVotes += 1;
        }
      });
    } else {
      votes.splice(votes.indexOf(designId), 1);
      userVotes -= 1;
      designs.forEach((design) => {
        if (design.id === designId) {
          design.countVotes -= 1;
        }
      });
    }

    this.setState({ userVotes });
    this.setState({ votes });

    axios({
      method: 'POST',
      url: `${API_URL}/users/challenges/${designId}/${isVote ? 'vote' : 'unvote'}`,
      headers: { Authorization: token },
    }); */

  };

  render() {
    //const { authenticated } = this.context;
    //const signUp = `${SIGN_UP}?origin=${ORIGIN_DESIGN_CHALLENGE}`;
    const {
      designs, isModalOpen, currentDesignId, isLoading,
    } = this.state;

    if (isLoading) {
      return <i className="fas fa-spinner fa-spin standalone-loading" />;
    }

    let currentDesign = null;
    if (currentDesignId !== null) {
      currentDesign = this.getDesignById(currentDesignId);
    }

    return (
      <ParallaxProvider>
        <Modal
          open={isModalOpen}
          center
          onClose={this.modalCloseHandler}
          styles={{ modal: { padding: 0, maxWidth: '70rem' } }}
        >
          {currentDesign && (
          <div className="modal-design-challenge-vote">
            <div className="user-info">
              <h1>{currentDesign.user.username}</h1>
              {currentDesign.instagram !== '' && (
              <div>
                <a href={currentDesign.instagram}>
                  <i className="fab fa-instagram" />
                  {' '}
                      Instagram
                </a>
              </div>
              )}
              {currentDesign.facebook !== '' && (
              <div>
                <a href={currentDesign.facebook}>
                  <i className="far fa-user" />
                  {' '}
                      Portfolio
                </a>
              </div>
              )}
              <hr />
              <div onClick={() => this.handleVote(currentDesign.id)}>
                <i className={`${this.hasVote(currentDesign.id) ? 'fas' : 'far'} fa-heart`} />
                {' '}
                { currentDesign.countVotes }
              </div>
            </div>
            <div className="images">
              {currentDesign.images.map(image => (
                <div key={image}>
                  <img
                    alt={`design from user ${currentDesign.user.id}`}
                    src={`${DESIGN_IMAGE_URL}${IMAGES_BASE_PATH}${currentDesign.user.id}/${currentDesign.id}/${image}`}
                  />
                </div>
              ))}
            </div>
          </div>
          )}
        </Modal>
        <Fragment>
          <Header>
            <Parallax className="custom-class" y={[-50, 20]} tagOuter="figure">
              <div>
                <h2>2nd Edition</h2>
                <h1>Design Challenge</h1>
                <ButtonsContainer>
                  {/*!authenticated ? (
                    <Link
                      to={signUp}
                      className="button primary big"
                    >
                      Vote now
                    </Link>
                  ) : (
                    <ScrollLink
                      className="button primary big"
                      activeClass="active"
                      to="vote-now"
                      spy
                      smooth
                      offset={0}
                      duration={500}
                    >
                      Vote now
                    </ScrollLink>
                  )*/}
                  <Link
                    to={DESIGN_CHALLENGE}
                    className="button primary big"
                  >
                    Design Brief
                  </Link>
                </ButtonsContainer>
              </div>
            </Parallax>
          </Header>
          <Content>
            { /* <Element name="vote-now">
              <h2>Choose your favorite design</h2>
            </Element> */ }
            {/*authenticated ? (
              <h3>
              You have used
                {' '}
                {this.state.userVotes || 0}
                {' '}
              of your 3 votes
              </h3>
            ) : (
              <h3 className="vote-h3">
                <Link to={`${SIGN_UP}?origin=${ORIGIN_DESIGN_CHALLENGE}`}>Log In</Link>
                {' '}
                to vote
              </h3>
            )*/}
            <ArrowDown />
            <Masonry
              breakpointCols={{
                default: 4,
                1180: 3,
                995: 2,
                660: 1,
              }}
              className="my-masonry-grid"
              columnClassName="my-masonry-grid_column"
            >
              {
                designs.map((design, index) => (
                  <DesignCard
                    key={design.id}
                    design={{
                      designId: design.id,
                      userId: design.user.id,
                      designer: design.user.username,
                      instagram: { url: design.instagram },
                      portfolio: design.facebook,
                      votes: design.countVotes,
                      images: design.images,
                      hasVote: this.hasVote(design.id),
                    }}
                    onClickImageHandler={this.modalOpenHandler}
                    handleVote={this.handleVote}
                    index={index}
                  />
                ))
              }
            </Masonry>
          </Content>
        </Fragment>
      </ParallaxProvider>
    );
  }
}
export default DesignChallengeDashboard;
