const MODELS = {
  sedan: {
    name: "Sedan",
    price: 0.25,
    title1: "Strength and dynamic look",
    generation: 0,
    description1:
      "The GEN0 Sedan is the first car ever built by CryptoMotors for this segment. Soft surfaces, visual strength and a dynamic look give this car a clean, sporty look.",
    title2: "Maximized performance",
    description2:
      "The Sedan has a solid and aerodynamic body that helps it maximize its performance on the road. It's great grip and handling make it an ideal car for races with sharp curves and hard turns.",
    description3:
      "With a balanced distribution of speed and acceleration, this Sedan becomes a safe option for intermediate drivers.",
    images: {
      front: "/images/market/sedan.png",
      side: "/images/market/sedan-side.png",
      back: "/images/market/sedan-back.png",
      stats: "/images/sedan/stats.jp2",
    },
    gallery: [
      "/images/sedan/1.jp2",
      "/images/sedan/2.jp2",
      "/images/sedan/3.jp2",
      "/images/sedan/4.jp2",
      "/images/sedan/5.jp2",
      "/images/sedan/6.jp2",
      "/images/sedan/7.jp2",
      "/images/sedan/8.jp2",
    ],
    openSea:
      "https://opensea.io/assets/cryptomotors?query=abyssus&search[query]=Sedan",
    stats: {
      speed: {
        min: 24,
        max: 76,
      },
      acceleration: {
        min: 21,
        max: 71,
      },
      grip: {
        min: 45,
        max: 80,
      },
      handling: {
        min: 20,
        max: 77,
      },
      braking: {
        min: 20,
        max: 60,
      },
    },
    highlightedStat: {
      name: "handling",
      integer: 8,
      decimal: 0,
    },
  },
  sportcoupe: {
    name: "Sport Coupé",
    price: 1,
    title1: "The GEN 0 beast",
    generation: 0,
    description1:
      "The GEN0 Sport Coupé is a pureblood stallion with the heritage of the iconic racers. It presents a long, lightweight body the gives the car a powerful and elegant look. ",
    title2: "High speed racer",
    description2:
      "Its great acceleration and max speed make it an ideal vehicle for drag racing. Short ground clearance and an aerodynamic design give the Sport Coupé a significant edge on high-speed races. Its performance improves in dry tracks and arid climates.",
    description3:
      "The Sport Coupé it’s an overall beast in terms of speed, handling and braking. A real deal for demandant drivers looking for the best of the best. ",
    images: {
      front: "/images/market/sportcoupe.png",
      side: "/images/market/sportcoupe-side.png",
      back: "/images/market/sportcoupe-back.png",
      stats: "/images/sport-coupe/stats.jp2",
    },
    gallery: [
      "/images/sport-coupe/1.jp2",
      "/images/sport-coupe/2.jp2",
      "/images/sport-coupe/3.jp2",
      "/images/sport-coupe/4.jp2",
      "/images/sport-coupe/5.jp2",
      "/images/sport-coupe/6.jp2",
      "/images/sport-coupe/7.jp2",
      "/images/sport-coupe/8.jp2",
    ],
    openSea:
      "https://opensea.io/assets/cryptomotors?query=abyssus&search[query]=Sport%20Coup%C3%A9",
    stats: {
      speed: {
        min: 49,
        max: 94,
      },
      acceleration: {
        min: 48,
        max: 92,
      },
      grip: {
        min: 50,
        max: 80,
      },
      handling: {
        min: 55,
        max: 87,
      },
      braking: {
        min: 37,
        max: 82,
      },
    },
    highlightedStat: {
      name: "speed",
      integer: 9,
      decimal: 4,
    },
  },
  suv: {
    name: "SUV",
    price: 0.5,
    title1: "Off-road perfection",
    generation: 0,
    description1:
      "The GEN0 SUV delivers a sharp and fresh take into the utility vehicle segment. A robust shape with an Intricate surface and a medium ground clearance make this car the perfect mix of classic and modern styles.",
    title2: "Versatile and resistant",
    description2:
      "Although the SUV does not characterize for reaching the highest speeds, Its balanced acceleration and handling make it a great choice for off-road races on extreme weather conditions.",
    description3:
      "Its strong and compact body makes the SUV a versatile and resistant car, excellent for drivers who are looking for a challenge.",
    images: {
      front: "/images/market/suv.png",
      side: "/images/market/suv-side.png",
      back: "/images/market/suv-back.png",
      stats: "/images/suv/stats.jp2",
    },
    gallery: [
      "/images/suv/1.jp2",
      "/images/suv/2.jp2",
      "/images/suv/3.jp2",
      "/images/suv/4.jp2",
      "/images/suv/5.jp2",
      "/images/suv/6.jp2",
      "/images/suv/7.jp2",
      "/images/suv/8.jp2",
    ],
    openSea:
      "https://opensea.io/assets/cryptomotors?query=abyssus&search[query]=SUV",
    stats: {
      speed: {
        min: 29,
        max: 70,
      },
      acceleration: {
        min: 34,
        max: 80,
      },
      grip: {
        min: 32,
        max: 60,
      },
      handling: {
        min: 39,
        max: 78,
      },
      braking: {
        min: 28,
        max: 65,
      },
    },
    highlightedStat: {
      name: "acceleration",
      integer: 8,
      decimal: 0,
    },
  },
  abyssus: {
    name: "Abyssus",
    price: 1,
    title1: "Fast and versatile",
    generation: 0,
    description1:
      "The GEN1 Abyssus Virtual Racer is the first-ever CryptoMotors model created from the winning design of our decentralized Design Challenge. The aerodynamic shape of the Abyssus allows it to reach the highest speeds in straight lines, and its versatile design gives the car perfect handling to smoothly take hard curves and sharp turns.",
    title2: "Maximum Control",
    description2:
      "This model has space for one driver, who controls the car in a Superman-like position. This position allows the driver to fully integrate with the car and to take maximum control over the movements of the vehicle.",
    description3:
      "The Abyssus Virtual Racer is an absolute beast in street racing. Its explosive acceleration and high velocity make the Abyssus the perfect choice for speed addicts.",
    images: {
      front: "/images/market/abyssus-event-front.png",
      side: "/images/market/abyssus-side.png",
      back: "/images/market/abyssus-back.png",
      stats: "/images/abyssus/stats.png",
    },
    gallery: [],
    openSea: "https://opensea.io/assets/cryptomotors?query=abyssus",
    stats: {
      speed: {
        min: 60,
        max: 89,
      },
      acceleration: {
        min: 60,
        max: 89,
      },
      grip: {
        min: 30,
        max: 79,
      },
      handling: {
        min: 30,
        max: 79,
      },
      braking: {
        min: 30,
        max: 79,
      },
    },
    highlightedStat: {
      name: "acceleration",
      integer: 8,
      decimal: 9,
    },
  },
  retrorocket: {
    name: "Retro Rocket",
    price: 1,
    title1: "High-end racer",
    generation: 1,
    description1:
      "Handcrafted in a nearby Multiverse by a skilled team of gearheads with a passion for the past and a technical eye towards the future, The Retro Rocket Razer is a perfect blend of classic design and high-end technology.",
    title2: "Extreme power",
    description2:
      "Utilizing an electric propulsion system to maximize torque and a supercapacitor hybrid system to complement the graphene technology woven throughout the monocoque construction, the Retro Rocket packs an extreme punch off the line.",
    description3:
      "The Retro Rocket is made for the most demanding pilots. Featuring in-wheel suspension, motors, and brakes in all four wheels, this killer machine poses no driver-assist to aid you in your quest for the winner's circle.",
    images: {
      front: "/images/market/retro-rocket.png",
      side: "/images/market/retro-rocket-side.png",
      back: "/images/market/retro-rocket-back.png",
      stats: "/images/market/retro-rocket-stats.png",
    },
    gallery: [
      "/images/retrorocket/1.png",
      "/images/retrorocket/2.png",
      "/images/retrorocket/3.png",
      "/images/retrorocket/4.png",
    ],
    openSea:
      "https://opensea.io/assets/cryptomotors?search[sortAscending]=false&search[sortBy]=CREATED_DATE&search[toggles][0]=IS_NEW",
    stats: {
      speed: {
        min: 75,
        max: 87,
      },
      acceleration: {
        min: 72,
        max: 85,
      },
      grip: {
        min: 70,
        max: 76,
      },
      handling: {
        min: 68,
        max: 80,
      },
      braking: {
        min: 69,
        max: 75,
      },
    },
    highlightedStat: {
      name: "acceleration",
      integer: 8,
      decimal: 7,
    },
  },
  neonroadster: {
    name: "Neon Roadster",
    price: 1,
    title1: "Fast and Strong",
    generation: 1,
    description1:
      "The GEN 1 Neon Roadster is the second CryptoMotors car created out from our first Design Challenge. The compact, yet aerodynamic body gives this vehicle the perfect balance between acceleration, top speed, and handling. Its remarkable grip and braking capabilities make this car perfect for races with narrow tracks and sharp curves.",
    title2: "Control and Comfort",
    description2:
      "The aircraft-like design of the car allows air to flow through the body, reducing air drag at a minimum and making it stand out over its competitors on the road. The Neon Roadster has enough interior space for a driver and a passenger, giving the pilot maximum control over the car while and the possibility to relax and face the race with full concentration.",
    description3:
      "The Neon Roadster is a flawless killing machine. Its high speed and explosive acceleration make the Neon Roadster one of the few cars capable of competing head to head with the Abyssus on street racing.",
    images: {
      front: "/images/market/neon-roadster.png",
      side: "/images/market/neon-roadster-side.png",
      back: "/images/market/neon-roadster-back.png",
      stats: "/images/neon-roadster/stats.png",
    },
    gallery: [
      "/images/neon-roadster/1.jpg",
      "/images/neon-roadster/2.png",
      "/images/neon-roadster/3.png",
      "/images/neon-roadster/4.png",
    ],
    openSea:
      "https://opensea.io/assets/cryptomotors?search[stringTraits][0][name]=Drop&search[stringTraits][0][values][0]=Neon%20Roadster%203rd%20Drop&search[toggles][0]=BUY_NOW",
    stats: {
      speed: {
        min: 62,
        max: 86,
      },
      acceleration: {
        min: 61,
        max: 90,
      },
      grip: {
        min: 31,
        max: 79,
      },
      handling: {
        min: 32,
        max: 75,
      },
      braking: {
        min: 31,
        max: 74,
      },
    },
    highlightedStat: {
      name: "acceleration",
      integer: 9,
      decimal: 0,
    },
  },
};

export default MODELS;
