import React, { useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import { PlayAudioContext } from "../../../../context/PlayAudioContext";
import { RaceContext } from "../../../../context/RaceContext";
import Tooltip from "./Tooltip";

const modifiers = [
  {
    name: "offset",
    enabled: true,
    options: {
      offset: [0, 15],
    },
  },
];

const UserDetails = (props) => {
  const {
    username,
    score,
    canGoBack,
    backToStage,
    handleMusicPlay,
    canPlayStop,
    showLastRace,
    hideScore,
    isInRank,
    circuitName,
    racingsLeftToRank,
  } = props;
  const { playpause } = useContext(PlayAudioContext);
  const { lastrace } = useContext(RaceContext);

  useEffect(() => {
    //Re-render on change
  }, [playpause, lastrace]);

  return (
    <div className="row user-data">
      <div className="user-data-wrapper">
        {canGoBack ? (
          <span
            className="fa fa-chevron-circle-left fa-2x d-lg-none d-block icons"
            onClick={backToStage}
          />
        ) : null}
        <h5 className="user-details">
          <span className="username">{username}</span>
          {!hideScore ? (
            <span className="score d-none d-lg-block">
              {!isInRank ? `RACE ${racingsLeftToRank} TIMES TO RANK` : null}
              {isInRank ? (
                <>
                  {" "}
                  {`${circuitName} - `}
                  <Tooltip
                    placement="bottom"
                    trigger="hover"
                    tooltip="CryptoMotors Ranking Unit"
                    modifiers={modifiers}
                  >
                    CMR
                  </Tooltip>
                </>
              ) : null}
            </span>
          ) : null}
          {!hideScore ? <span className="divider d-lg-none" /> : null}
          {!hideScore && isInRank ? (
            <span className="points">{score}</span>
          ) : null}
          {showLastRace ? (
            <span className="d-none d-lg-block last-race">
              Check last race{" "}
              <Link className="last-race-link" to={lastrace}>
                here
              </Link>
            </span>
          ) : null}
        </h5>
        {canPlayStop ? (
          <span
            className={
              playpause
                ? "fa fa-pause-circle fa-2x d-lg-none d-block controls"
                : "fa fa-play-circle fa-2x d-lg-none d-block controls"
            }
            onClick={handleMusicPlay}
          />
        ) : null}
      </div>
    </div>
  );
};

export default UserDetails;
