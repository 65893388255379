import React from 'react';
import Headline from '../Headline/Headline';
import CarSection from '../CarSection/CarSection';
import SplitSection from '../SplitSection/SplitSection';
import Video from '../Video/Video';
import {
  ABOUT, MARKET_CAR, MODEL_SPORT_COUPE, MODEL_SUV,
} from '../../config/Routes';
import './Home.css';

const Home = () => (
  <div className="home">
    <Video />
    <CarSection />
    <SplitSection
      title="One car, one owner"
      description="CryptoMotors are built by putting together professional automotive design with the latest blockchain technology. Each vehicle is one-of-a-kind and has its own ID, stats and features. Just like in the real world, some cars are rarer than others. And, hence, more valuable."
      image="/images/home/suv-hero-721.png"
      link={MARKET_CAR.replace(':model', MODEL_SUV)}
      linkText="buy now"
      theme="dark-gray"
    />
    <SplitSection
      title="Collect & Drive"
      description="Thanks to ERC-721 technology you’ll be able to use your crypto vehicles in different 2D, 3D, and VR games and platforms. Or you can go even further and build a real-life size prototype of your car."
      image="/images/home/sportcoupe-hero-721.png"
      link={MARKET_CAR.replace(':model', MODEL_SPORT_COUPE)}
      linkText="buy now"
      inverted
    />
    <SplitSection
      title="Meet the team"
      description="We are a team of experienced designers, 3D modelers, visual artists, and software engineers. Our goal is to bring quality assets to the next generation of crypto games by connecting car design with the latest blockchain technology."
      image="/images/home/video.jp2"
      inverted
      theme="white"
      linkInImage
      link="https://www.youtube.com/watch?v=k_7v9V-JPHA"
    />
    <SplitSection
      title="Leandro Bellone, CEO"
      description="„We want to give designers from all around the world the possibility of showing their talent without any physical or creative limitation. We want them to take part in the exciting process of designing a car for the digital world and get rewarded for it.“"
      image="/images/leandro.png"
      theme="light-gray"
      link={ABOUT}
      linkText="about"
    />
    <div className="partners">
      <h2>Partners</h2>
      <hr />
      <div className="logos">
        <div>
          <a href="//www.autodesk.com/" target="_blank" rel="noopener noreferrer">
            <img alt="autodesk" src="/images/partners/autodesk.png" />
          </a>
        </div>
        <div>
          <a href="//www.auctionity.com/" target="_blank" rel="noopener noreferrer">
            <img alt="auctionity" src="/images/partners/auctionity.png" />
          </a>
        </div>
        <div>
          <a href="//opensea.io/" target="_blank" rel="noopener noreferrer">
            <img alt="opensea" src="/images/partners/opensea.png" />
          </a>
        </div>
        <div className="neoworld">
          <a href="//www.neoworld.io/" target="_blank" rel="noopener noreferrer">
            <img alt="neoworld" src="/images/partners/neoworld.png" />
          </a>
        </div>
        <div className="gravity-sketch">
          <a href="//www.gravitysketch.com/" target="_blank" rel="noopener noreferrer">
            <img alt="gravity_sketch" src="/images/partners/gravity_sketch.png" />
          </a>
        </div>
      </div>
    </div>
    <div className="partners-mobile">
      <h2>Partners</h2>
      <hr />
      <div className="logos">
        <div>
          <a href="//www.autodesk.com/" target="_blank" rel="noopener noreferrer">
            <img alt="autodesk" src="/images/partners/autodesk_mobile.png" />
          </a>
        </div>
        <div>
          <a href="//www.auctionity.com/" target="_blank" rel="noopener noreferrer">
            <img alt="auctionity" src="/images/partners/auctionity_mobile.png" />
          </a>
        </div>
        <div>
          <a href="//www.gravitysketch.com/" target="_blank" rel="noopener noreferrer">
            <img alt="gravity_sketch" src="/images/partners/gravity_sketch_mobile.png" />
          </a>
        </div>
        <div>
          <a href="//opensea.io/" target="_blank" rel="noopener noreferrer">
            <img alt="opensea" src="/images/partners/opensea_mobile.png" />
          </a>
        </div>
        <div>
          <a href="//www.neoworld.io/" target="_blank" rel="noopener noreferrer">
            <img alt="neoworld" src="/images/partners/neoworld_mobile.png" />
          </a>
        </div>
      </div>
    </div>
    <Headline showArrow={false} />
  </div>
);

export default Home;
