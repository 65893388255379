import React, { Component } from 'react';
import Layout from './components/Layout/Layout';
import AuthContext from './context/AuthContext';
import { postRefreshToken } from './requests/User';
import PlayAudioProvider from './context/PlayAudioContext';
import RaceProvider from './context/RaceContext';
import { ToastProvider } from 'react-toast-notifications';
import CustomToast from './helpers/CustomToast';

class App extends Component {
  state = {
    authenticated: localStorage.getItem('token') !== null,
    token: localStorage.getItem('token') || null,
    refreshToken: localStorage.getItem('refreshToken') || null,
    expiresAt: localStorage.getItem('expiresAt') || null,
    username: localStorage.getItem('username') || null,
  };

  loginHandler = (token, refreshToken, expiresAt, username) => {
    this.setState({
      authenticated: true,
      token,
      refreshToken,
      expiresAt,
      username,
    });

    localStorage.setItem('token', token);
    localStorage.setItem('refreshToken', refreshToken);
    localStorage.setItem('expiresAt', expiresAt);
    localStorage.setItem('username', username);
  };

  logoutHandler = () => {
    this.setState({
      authenticated: false,
      token: null,
      refreshToken: null,
      expiresAt: null,
      username: null,
    });

    localStorage.clear();
  };

  checkTokenHandler = () => {
    
    if (
      !this.state.authenticated
      || this.state.expiresAt - 1600 > Math.floor(new Date().getTime() / 1000.0)
    ) {
      return;
    }

    postRefreshToken(this.state.token, this.state.refreshToken)
      .then(({ data: { refreshToken, token, expiresAt } }) => {
                
        this.setState({ token, refreshToken, expiresAt });

        localStorage.setItem('token', token);
        localStorage.setItem('refreshToken', refreshToken);
        localStorage.setItem('expiresAt', expiresAt);
      })
      .catch(() => {        
        this.logoutHandler();
      });
  };

  updateUsernameHandler = (username) => {
    this.setState({ username });
    localStorage.setItem('username', username);
  };

  componentDidMount = () => this.checkTokenHandler();

  render() {
    return (
      <AuthContext.Provider value={{
        authenticated: this.state.authenticated,
        token: this.state.token,
        refreshToken: this.state.refreshToken,
        username: this.state.username,
        expiresAt: this.state.expiresAt,
        checkToken: this.checkTokenHandler,
        login: this.loginHandler,
        logout: this.logoutHandler,
        updateUsername: this.updateUsernameHandler,
      }}
      >
        <ToastProvider components={{ Toast: CustomToast }}>
          <PlayAudioProvider>
            <RaceProvider>
              <Layout />
            </RaceProvider>
          </PlayAudioProvider>
        </ToastProvider>
      </AuthContext.Provider>
    );
  }
}

export default App;
