import React from 'react';
import { Link } from 'react-router-dom';
import './MarketMenu.css';
import PropTypes from 'prop-types';
import {
  AUCTIONS,
  MARKET,
} from '../../config/Routes';

const MarketMenu = ({ isAuctions }) => (
  <div className="market-menu">
    <ul>
      <li>
        <Link
          to={MARKET}
          className={!isAuctions ? 'active' : ''}
        >
            From studio
        </Link>
      </li>
      <li>
        <Link
          to={AUCTIONS}
          className={isAuctions ? 'active' : ''}
        >
            Auctions
        </Link>
      </li>
    </ul>
  </div>
);

MarketMenu.propTypes = {
  isAuctions: PropTypes.bool,
};

MarketMenu.defaultProps = {
  isAuctions: false,
};

export default MarketMenu;
