import React, { Component } from 'react';
import axios from 'axios';
import { API_URL } from '../../config/Config';

class Contact extends Component {
  state = {
    isLoading: false,
    isSuccess: false,
  };

  handleSubmit = (e) => {
    e.preventDefault();

    const { isSuccess } = this.state;
    if (isSuccess) {
      return;
    }

    this.setState({ isLoading: true });

    const email = e.target.email.value;
    const message = e.target.message.value;

    axios({
      method: 'POST',
      url: `${API_URL}/contact`,
      data: {
        email,
        message,
      },
    })
      .then(() => this.setState({
        isLoading: false,
        isSuccess: true,
      }))
      .catch(() => this.setState({
        isLoading: false,
      }));
  };

  render() {
    const { isLoading, isSuccess } = this.state;

    return (
      <div className="contact">
        <h2>Contact</h2>
        <hr />
        <form onSubmit={this.handleSubmit}>
          <div>
            <input type="email" placeholder="Email" name="email" required />
          </div>
          <div>
            <textarea placeholder="Your message" rows={8} name="message" required minLength={20} />
          </div>
          { isSuccess && <p>Message sent. We&apos;ll be in contact with you soon.</p> }
          { !isSuccess && (
          <div className="send">
            <button type="submit" className="primary">
              {isLoading
                ? <i className="fas fa-spinner fa-spin" />
                : 'Send'
              }
            </button>
          </div>
          ) }
        </form>
      </div>
    );
  }
}

export default Contact;
