import React from 'react';
// import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { Parallax, ParallaxProvider } from 'react-scroll-parallax';
import './DesignChallenge.css';
import { Link as ScrollLink, Element } from 'react-scroll';
import {
  DESIGN_CHALLENGE_DASHBOARD,
  DESIGN_CHALLENGE_TERMS,
  // DESIGN_CHALLENGE_UPLOAD,
  HOME,
  // ORIGIN_DESIGN_CHALLENGE,
  // SIGN_UP,
} from '../../config/Routes';
// import AuthContext from '../../context/AuthContext';

const DesignChallenge = () => {
  // const authContext = useContext(AuthContext);
  // const { authenticated } = authContext;
  // const signUp = `${SIGN_UP}?origin=${ORIGIN_DESIGN_CHALLENGE}`;
  // const registered = !!localStorage.getItem('designChallengeRegistered');
  // const registered = false; // this don't apply for open uploads

  return (
    <ParallaxProvider>
      <div className="design-challenge">
        <div className="header">
          <Parallax className="custom-class" y={[-50, 20]} tagOuter="figure">
            <div>
              <h2>2nd Edition</h2>
              <h1>Design Challenge</h1>
              <div>
                {
                  <Link
                    to={DESIGN_CHALLENGE_DASHBOARD}
                    className="button primary big"
                  >
                    View results
                  </Link>
                }
                {/* <ScrollLink
                  activeClass="active"
                  to="about"
                  spy
                  smooth
                  offset={0}
                  duration={500}
                >
                  <button type="button" className="secondary big">
                    Learn more
                  </button>
                </ScrollLink> */}
              </div>
            </div>
          </Parallax>
        </div>
        <div className="arrow" />
        <Element name="about">
          <div className="about center">
            <h3>About</h3>
            <hr />
            <div className="two-column">
              <p>
                At CryptoMotors we are excited to announce the launch of our second Design
                Challenge. We’ve teamed up with the British company Gravity Sketch to take everybody
                on the amazing journey of creating the next digital cars in our lineup, as well as
                the digital show cars for Gravity Sketch.
              </p>
              <p>
                Our mission at CryptoMotors is to create an open platform with no boundaries for
                creativity where we can build high-quality cars for the digital world to be
                implemented in gaming and VR experiences. Thanks to the integration of Gravity
                Sketch’s VR sketching software, we are introducing a futuristic design tool that
                will allow the winners of the Design Challenge to explore their designs in VR.
              </p>
              <p>
                For our second Design Challenge, we will ask participants to design an SUV or a
                Crossover SUV for a futuristic environment. The top three designs will be modeled in
                3D by our professional Alias modeling team, and later on, they will be visualized,
                tokenized and put on sale on our platform, holding a commission for the winners of
                the contest.
              </p>
              <p>
                The time has come to create the next generation of digital cars. We’ll need every
                single one of you: enthusiasts and designers to create the car of your dreams and
                get rewarded for it. Also, the whole community will get to choose the winning
                designs with their votes.
              </p>
            </div>
          </div>
        </Element>
        <div className="design-brief">
          <div className="center">
            <div className="brief">
              <div>
                <h3>Design brief</h3>
                <hr />
                <p>
                  For this second Design Challenge, we ask you to design the exterior for an SUV or
                  a Crossover SUV. The car’s design has to fit the story we’ve created about Jan and
                  Mallow, two pioneers exploring a unique looking planet called 51 PEGASI b.
                </p>
                <p>
                  Each designer will have to come up with a spacious, luxurious, and
                  technology-driven design for the car. The volume and therefore the layout is
                  entirely up to you, but it should be unique. We will only ask you to keep a few
                  guidelines in mind: The car must have four wheels and space for at least two
                  passengers. 51 PEGASI b has the same gravity conditions than the real world.
                  Pioneers want to observe nature around them and discover while driving; the design
                  must confer them with the freedom and space they wish. Feel free to choose the
                  powertrain as well as any possible future technologies you’d like to integrate.
                </p>
                <p>
                  We’ve created a PERSONA and an ENVIRONMENT for you to fully dive into the scenario
                  for the car. To set the perfect vibe, we have also added a music playlist to get
                  you inspired.
                </p>
                <p>
                  <b>
                    (You are more than welcome to include the interior design of the car to your
                    proposals but that being optional.)
                  </b>
                </p>
              </div>
            </div>
            <div className="design-brief-image" />
          </div>
        </div>
        <div className="pioneer-banner">
          <div>
            <h2>Pioneer</h2>
          </div>
        </div>
        <div className="persona center">
          <div>
            <h3>Persona</h3>
            <hr />
            <div>
              <p className="grey">
                <span>Pioneer</span>
                <br />
                /pʌɪəˈnɪə/
                <br />
                a person who is among the first to explore or settle a new country
                or area.
              </p>
              <div className="persona-mobile">
                <img
                  src="/images/design-challenge-2/persona_1_460.jpg"
                  alt="persona_1"
                  className="shadow"
                />
              </div>
              <p>
                Jan and Mallow are pioneers. Driven by their desire of exploring new life and
                environments, they realize that life on primary planets simply wasn’t exciting
                anymore. As pioneers, they see discovering and being one of the first as a privilege
                and a luxury. Jan and Mallow spend most of their time analyzing biodiversity,
                allowing them to design objects and modules to be used in everyday life.
              </p>
              <p>
                Their pioneer suits are both fitted for space travel and everyday exploration jobs.
                Technology is fully integrated into their clothing but only a few details give away
                the materials full potential.
              </p>
              <img
                src="/images/design-challenge-2/persona_1_460.jpg"
                alt="persona_1"
                className="shadow persona-desktop"
              />
            </div>
          </div>
          <div className="persona-right-image" />
        </div>
        <div className="environment-banner" />
        <div className="environment">
          <div className="center">
            <h3>Environment</h3>
            <hr />
            <div className="two-column">
              <p>
                51 PEGASI b is an uninhabited planet. The high contrast between white crystals and
                black lava stone is very intriguing for the curious pioneers. They think it’s a
                viable planet.
              </p>
              <p>
                Average temperatures are fairly low, around 2° Celsius, and humidity is high due to
                the great amount of living biomass. 51 PEGASI b is a diverse planet with a unique
                looking vegetation and soil, making it an exciting landscape for exploring and
                discovering new things.
              </p>
              <div className="environment-image-1-mobile">
                <img
                  src="/images/design-challenge-2/environment_1_460.jpg"
                  alt="environment-1"
                  className="shadow"
                />
              </div>
              <p>
                The explorer habitat consists of a so-called loft-lab equipped with all the
                technology required to create anything using the planet resources. It also has all
                the technical innovations to allow space travel as well as land exploration. All
                technologies are nearly invisible at first sight, landing gear seamlessly becomes
                part of the building&apos;s architecture.
              </p>
            </div>
            <div className="images">
              <div className="environment-image-1">
                <img src="/images/design-challenge-2/environment_1_460.jpg" alt="environment-1" />
              </div>
              <div className="environment-image-2">
                <img src="/images/design-challenge-2/environment_2_460.jpg" alt="environment-2" />
              </div>
            </div>
          </div>
        </div>
        <div className="music center">
          <div className="text-left">
            <h3>Music</h3>
            <hr />
            <div>
              <p>
                To set you in the perfect atmosphere of the pioneers exploring the endless beauty
                and possibilities of this exciting planet, we’ve put together a music playlist for
                you to listen to while designing the car. We believe that perfect design can only be
                achieved when you really get to feel the emotional vibe of the persona you are
                designing the car for.
              </p>
              <p>
                For our second Design Challenge, the musical theme is: “Pioneer” The music displays
                synergies between emotional and unique beats. Just hit play, lean back and allow
                yourself to drift into the universe.
              </p>
            </div>
          </div>
          <div className="spotify">
            <iframe
              title="spotify-desktop"
              src="https://open.spotify.com/embed/playlist/2I9mdee8F0DfycAxG88kXy"
              width="380"
              height="480"
              frameBorder="0"
              allow="encrypted-media"
              className="shadow"
            />
          </div>
          <div className="spotify-mobile">
            <iframe
              title="spotify"
              src="https://open.spotify.com/embed/playlist/2I9mdee8F0DfycAxG88kXy"
              width="280"
              height="480"
              frameBorder="0"
              allow="encrypted-media"
              className="shadow"
            />
          </div>
        </div>
        <div className="color-palette">
          <div className="center">
            <h3>Color Palette</h3>
            <hr />
            <div>
              <div>
                <p>
                  Let’s get colorful! We decided to provide you with a color palette you can use as
                  a reference while designing the sketches and PSD renderings. Simply use the
                  pipette in Photoshop to select the colors from the color palette.
                </p>
              </div>
              <div className="colors">
                <div className="color-1" />
                <div className="color-2" />
                <div className="color-3" />
                <div className="color-4" />
                <div className="color-5" />
                <div className="color-6" />
                <div className="rgb">RGB</div>
              </div>
            </div>
          </div>
        </div>
        <div className="take-part">
          <div className="center">
            <h3>Taking Part</h3>
            <hr />
            <div className="two-column">
              <p>
                The Design Challenge is open to enthusiasts, freelancers, students and professionals
                within the design industry. The competition is divided into three stages: the
                ideation process, virtual sketching exploration, and 3D development. We kindly ask
                you to read through our design brief and detailed background information
                (environment, persona, sound & vibe) in order to get started.
              </p>
              <p>
                In the first stage (ideation process) we ask the designers to submit a minimum but
                not limited to 3 sketches or/and photoshop renderings per entry. The three
                sketches/renderings should include: 3/4 front view, 3/4 rear view, and a side view
                each on a separate page (more sketches can be added if the requirement is
                fulfilled).
              </p>
              <p>
                Participants can submit more than one design proposal, but they must be uploaded as
                individual entries. Once the entries have been uploaded before the final closing
                date, the work will be displayed on our website for voting. Voting will be open to
                anyone who has a CryptoMotors account to ensure no multiple votings. The five
                participants with the highest number of votes will be the winners of the challenge.
              </p>
              <p>
                Once the five winners have been announced, they will move into the second stage
                (virtual sketching exploration). For the first two designers, Gravity Sketch will
                provide (if required by the designer) a full VR headset and controllers as well as
                the Gravity Sketch software to start exploring their design in VR.
              </p>
              <p>
                For the places 3 to 5, Gravity Sketch will also provide official software licenses
                to be used by the participants to explore their designs in VR. However, the VR
                headset won’t be included.
              </p>
              <p>
                Once the second stage is completed, CryptoMotors’ professional modeling team will
                take the design inputs from stage one and two, and will transform them into 3D Alias
                Surface Models.
              </p>
              <p>
                Together with the support of our design team, the top 3 winning designers will have
                the amazing chance of developing their cars to become the next models in our lineup
                of ERC-721 crypto collectibles. The winning designs will also become the virtual
                show cars for Gravity Sketch. Read further to find out more about the prizes.
              </p>
            </div>
          </div>
          <Element name="timeline">
            <div className="timeline" />
          </Element>
          {/*!registered && (
            <div className="register">
              <Link
                to={authenticated ? DESIGN_CHALLENGE_UPLOAD : signUp}
                className="button primary"
              >
                Upload your design
              </Link>
            </div>
          )*/}
        </div>
        <div className="prizes-and-rules">
          <div className="center">
            <h3>Prizes & rules</h3>
            <hr />
            <div>
              <div className="prizes">
                <p>
                  Will you be one of the lucky 5 winners? There are exciting prizes you can take
                  away.
                </p>
                <p>
                  1. Thanks to our collaboration with Gravity Sketch, you will get the amazing
                  opportunity to use their software for free for a period of 12 months to design and
                  explore your ideas in VR. The top two designers will additionally be provided with
                  VR headsets to use during the Design Challenge.
                </p>
                <p>
                  2. CryptoMotors’ professional 3D modeling team will transform the first three
                  winning designs into a 3D Alias Autodesk model, following the highest automotive
                  concept car standards. In case you agree to share your Instagram account with us,
                  we will promote your profile along with the final outcome of the competition
                  through our social media platforms.
                </p>
                <p>
                  3. The top three (3) winners will receive a 10% commission of each car sold on our
                  platform associated with their initial design idea. The CryptoMotors market smart
                  contract will automatically send the prize in ETH (ether) to each winner’s crypto
                  wallet (this is the reason why we urge participants to set up an Ether wallet to
                  receive the prize). The smart contract will stop transacting the 10% commission
                  once the last unit of the crypto-collectible lot is sold.
                </p>
                <p>
                  4. As a part of the prize, the top three (3) winners will receive one (1)
                  CryptoMotors ERC-721 digital collectible associated with their design idea.
                </p>
                <p>
                  5. Participants who end up on places 4 and 5 of the Design Challenge will be
                  entitled to one (1) CryptoMotors ERC-721 collectible associated with one of the
                  winning designs.
                </p>
                <p>
                  6. If you&apos;d like to be featured later on a CryptoMotors after-movie, we
                  urge you to take some screenshots and photos of your design process.
                </p>
                <p className="last">
                  Good luck!
                </p>
              </div>
              <div className="rules">
                <p><b>RULES</b></p>
                <p>
                  To be eligible for the competition and to claim your prize, you’ll have to follow
                  these rules:
                </p>
                <p>
                  1. The Design Challenge is open to students and professionals from any design
                  industry.
                </p>
                <p>
                  2. Register to the challenge with your email or Facebook account.
                </p>
                <p>
                  3. Submit your work on time. You’ll find the exact dates in the timeline posted on
                  the
                  {' '}
                  <ScrollLink
                    className="normal-link"
                    activeClass="active"
                    to="timeline"
                    spy
                    smooth
                    offset={0}
                    duration={500}
                  >
                    Design Challenge landing page.
                  </ScrollLink>
                </p>
                <p>
                  4. Submit your work in the right format. Aspect ratio: 16:9, image quality:
                  1920x1080 px, format: jpg or png.
                </p>
                <p>
                  5. For the first stage (ideation process) you must submit a minimum but not
                  limited to 3 views: 3/4 front view, 3/4 rear view, side view.
                </p>
                <p>
                  6. You’ll have to complete the requirement of the first stage to be eligible for
                  the final voting.
                </p>
                <p>
                  7. In order to vote, users must be logged into the
                  {' '}
                  <Link to={HOME} className="normal-link">CryptoMotors website</Link>
                  . Each user
                  will have a maximum of 3 votes per stage.
                </p>
                <p>
                  8. In case you are one of the lucky winners, the VR gear must be handled with care
                  and sent back to Gravity Sketch on the agreed time.
                </p>
                <p>
                  9. The design work must be relevant to the competition&apos;s topic and 100%
                  original.
                </p>
                <p>
                  10. Have fun.
                </p>
                <p className="last">
                  <b>
                    For further information on our terms and conditions, please
                    {' '}
                    <Link to={DESIGN_CHALLENGE_TERMS}>click here</Link>
                  </b>
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="partners-and-sponsors center">
          <h3>Partners & Sponsor</h3>
          <hr />
          <div className="two-column">
            <p>
              For the second CryptoMotors Design Challenge, we have teamed up with
              {' '}
              <a href="https://www.gravitysketch.com/" target="_blank" rel="noopener noreferrer">Gravity Sketch</a>
              .
              Gravity Sketch provides a tool that allows you to sketch in VR using VR glasses and
              controllers. Through this software, designers are able to explore their ideas and
              designs in a three-dimensional space throughout the creative process.
            </p>
            <div className="gravity-sketch-mobile">
              <img src="/images/design-challenge-2/gravity_sketch_473.png" alt="gravity-sketch" />
            </div>
            <p>
              As an official sponsor, we would like to introduce
              {' '}
              <a href="https://www.auctionity.com" target="_blank" rel="noopener noreferrer">Auctionity</a>
              , the world&apos;s
              largest blockchain auction house for crypto collectibles. Auctionity allows
              participants to create auctions, bid on and buy goods in real-time on a global,
              decentralized network, while maintaining payment and delivery guarantee. The company
              is revolutionizing the auction world thanks to its unique application of blockchain
              technology.
            </p>
          </div>
          <div className="logos">
            <div className="gravity-sketch">
              <img src="/images/design-challenge-2/gravity_sketch_473.png" alt="gravity-sketch" />
            </div>
            <div />
            <div className="auctionity">
              <img src="/images/design-challenge-2/auctionity_989.png" alt="auctionity" />
            </div>
          </div>
        </div>
        <div className="powered-by center">
          <h3>Powered by</h3>
          <hr />
          <div className="logos">
            <div className="autodesk">
              <img src="/images/design-challenge-2/autodesk_833.png" alt="autodesk" />
            </div>
            <div className="ethereum">
              <img src="/images/design-challenge-2/ethereum_790.png" alt="ethereum" />
            </div>
          </div>
        </div>
        {/*!registered && (
          <div className="join-now">
            <h2>Join the challenge</h2>
            <Link
              to={authenticated ? DESIGN_CHALLENGE_UPLOAD : signUp}
              className="button primary big"
            >
              Upload your design
            </Link>
          </div>
        )*/}
      </div>
    </ParallaxProvider>
  );
};

export default DesignChallenge;
