import React, { useContext } from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import StatBar from "./StatBar";
import { GIFT_CAR, SELL_CAR, VIEW_CAR } from "../../config/Routes";
import AuthContext from "../../context/AuthContext";
import { VEHICLES_IMG_PATH } from "../../config/Config";

const getTopThreeStats = (s) => {
  const stats = s;
  const maxStats = [];
  for (let i = 0; i < 3; i += 1) {
    const max = Object.keys(stats).reduce((a, b) =>
      stats[a] > stats[b] ? a : b
    );
    maxStats.push(max);
    delete stats[max];
  }

  return maxStats;
};

const Card = ({ car, onClickBorrow, onClickTakeBack }) => {
  const authContext = useContext(AuthContext);

  const {
    acceleration,
    braking,
    ethId,
    files,
    grip,
    handling,
    maxSpeed,
    model: {
      name,
      gen,
      minMaxSpeed,
      maxMaxSpeed,
      minAcceleration,
      maxAcceleration,
      minGrip,
      maxGrip,
      minHandling,
      maxHandling,
      minBraking,
      maxBraking,
    },
    uuid,
    borrower,
    wallet: { user },
  } = car;

  const topThreeStats = getTopThreeStats({
    acceleration,
    braking,
    handling,
    grip,
    maxSpeed,
  });

  return (
    <div className="card">
      <div className="overlay">
        {borrower ? (
          <div className="borrow-mark-overlay-container">
            {borrower.username === authContext.username ? (
              <div className="borrow-mark-overlay">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="26.247"
                  height="20.322"
                  viewBox="0 0 26.247 20.322"
                >
                  <g
                    id="Group_190"
                    data-name="Group 190"
                    transform="translate(11502 16716.244)"
                  >
                    <path
                      id="bootstrap-brands"
                      d="M6.66,38.378a.907.907,0,0,1-1,.944H3.816V37.461H5.659C6.629,37.461,6.66,38.187,6.66,38.378ZM5.517,36.712a.819.819,0,0,0,.876-.827c0-.354-.08-.817-.876-.817h-1.7v1.645Zm4.69-3.01v6.8a1.706,1.706,0,0,1-1.7,1.7H1.7a1.706,1.706,0,0,1-1.7-1.7V33.7A1.706,1.706,0,0,1,1.7,32h6.8A1.706,1.706,0,0,1,10.207,33.7ZM7.7,38.513a1.427,1.427,0,0,0-1.118-1.535v-.017a1.305,1.305,0,0,0,.851-1.268c0-.159.046-1.476-1.768-1.476H2.774v5.954c2.922,0,3.187.038,3.727-.13A1.532,1.532,0,0,0,7.7,38.513Z"
                      transform="translate(-11493.98 -16748.244)"
                      fill="#fff"
                    />
                    <path
                      id="hand-holding-solid"
                      d="M25.758,320.369a1.508,1.508,0,0,0-1.941,0l-4.21,3.367a2.9,2.9,0,0,1-1.823.638h-5.39a.729.729,0,1,1,0-1.458h3.568a1.517,1.517,0,0,0,1.517-1.212,1.46,1.46,0,0,0-1.44-1.7H8.748a5.363,5.363,0,0,0-3.376,1.2l-2.119,1.718H.729a.731.731,0,0,0-.729.729v4.374a.731.731,0,0,0,.729.729H16.987a2.92,2.92,0,0,0,1.823-.638L25.7,322.6A1.457,1.457,0,0,0,25.758,320.369Z"
                      transform="translate(-11502 -17024.67)"
                      fill="#fff"
                    />
                  </g>
                </svg>
                <span>{user.username}</span>
              </div>
            ) : (
              <div className="borrow-mark-overlay">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="19.035"
                  height="19.035"
                  viewBox="0 0 19.035 19.035"
                >
                  <g
                    id="Group_191"
                    data-name="Group 191"
                    transform="translate(11503 16716)"
                  >
                    <path
                      id="Exclusion_3"
                      data-name="Exclusion 3"
                      d="M-2101.137,250.035h-12.69a3.19,3.19,0,0,1-3.172-3.173v-12.69a3.189,3.189,0,0,1,3.172-3.172h12.69a3.189,3.189,0,0,1,3.173,3.172v12.69A3.19,3.19,0,0,1-2101.137,250.035Zm-9.179-15.411V246H-2103v-1.883h-4.977v-9.492Z"
                      transform="translate(-9386 -16947)"
                      fill="#fff"
                    />
                  </g>
                </svg>
                <span>{borrower.username}</span>
              </div>
            )}
          </div>
        ) : null}
        <div>
          <div>
            <Link
              className="button primary"
              to={VIEW_CAR.replace(":uuid", uuid)}
            >
              View
            </Link>
          </div>
          {authContext.username === user &&
            authContext.username === user.username && (
              <div>
                <a
                  className="button primary"
                  target="_blank"
                  rel="noopener noreferrer"
                  href={SELL_CAR.replace(":eth-id", ethId)}
                >
                  Sell
                </a>
              </div>
            )}
          {authContext.username === user &&
            authContext.username === user.username && (
              <div>
                <a
                  className="button primary"
                  target="_blank"
                  rel="noopener noreferrer"
                  href={GIFT_CAR.replace(":eth-id", ethId)}
                >
                  Send as gift
                </a>
              </div>
            )}
          {authContext.username === user.username && (
            <div>
              {borrower ? (
                <button
                  className="button primary borrow"
                  onClick={() => onClickTakeBack(uuid)}
                >
                  Take Back
                </button>
              ) : (
                <button
                  className="button primary borrow"
                  onClick={() =>
                    onClickBorrow(uuid, ethId, name, files[0].name)
                  }
                >
                  Lend
                </button>
              )}
            </div>
          )}
        </div>
      </div>
      {borrower ? (
        <div>
          {borrower.username === authContext.username ? (
            <div className="borrow-mark">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="26.247"
                height="20.322"
                viewBox="0 0 26.247 20.322"
              >
                <g
                  id="Group_186"
                  data-name="Group 186"
                  transform="translate(11502 16716.244)"
                >
                  <path
                    id="bootstrap-brands"
                    d="M6.66,38.378a.907.907,0,0,1-1,.944H3.816V37.461H5.659C6.629,37.461,6.66,38.187,6.66,38.378ZM5.517,36.712a.819.819,0,0,0,.876-.827c0-.354-.08-.817-.876-.817h-1.7v1.645Zm4.69-3.01v6.8a1.706,1.706,0,0,1-1.7,1.7H1.7a1.706,1.706,0,0,1-1.7-1.7V33.7A1.706,1.706,0,0,1,1.7,32h6.8A1.706,1.706,0,0,1,10.207,33.7ZM7.7,38.513a1.427,1.427,0,0,0-1.118-1.535v-.017a1.305,1.305,0,0,0,.851-1.268c0-.159.046-1.476-1.768-1.476H2.774v5.954c2.922,0,3.187.038,3.727-.13A1.532,1.532,0,0,0,7.7,38.513Z"
                    transform="translate(-11493.98 -16748.244)"
                    fill="#b18f4f"
                  />
                  <path
                    id="hand-holding-solid"
                    d="M25.758,320.369a1.508,1.508,0,0,0-1.941,0l-4.21,3.367a2.9,2.9,0,0,1-1.823.638h-5.39a.729.729,0,1,1,0-1.458h3.568a1.517,1.517,0,0,0,1.517-1.212,1.46,1.46,0,0,0-1.44-1.7H8.748a5.363,5.363,0,0,0-3.376,1.2l-2.119,1.718H.729a.731.731,0,0,0-.729.729v4.374a.731.731,0,0,0,.729.729H16.987a2.92,2.92,0,0,0,1.823-.638L25.7,322.6A1.457,1.457,0,0,0,25.758,320.369Z"
                    transform="translate(-11502 -17024.67)"
                    fill="#b18f4f"
                  />
                </g>
              </svg>
              <span>{user.username}</span>
            </div>
          ) : (
            <div className="borrow-mark">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="19.035"
                height="19.035"
                viewBox="0 0 19.035 19.035"
              >
                <g
                  id="Group_191"
                  data-name="Group 191"
                  transform="translate(11503 16716)"
                >
                  <path
                    id="Exclusion_3"
                    data-name="Exclusion 3"
                    d="M-2101.137,250.035h-12.69a3.19,3.19,0,0,1-3.172-3.173v-12.69a3.189,3.189,0,0,1,3.172-3.172h12.69a3.189,3.189,0,0,1,3.173,3.172v12.69A3.19,3.19,0,0,1-2101.137,250.035Zm-9.179-15.411V246H-2103v-1.883h-4.977v-9.492Z"
                    transform="translate(-9386 -16947)"
                    fill="#b18f4f"
                  />
                </g>
              </svg>
              <span>{borrower.username}</span>
            </div>
          )}
        </div>
      ) : null}

      <img alt={name} src={`${VEHICLES_IMG_PATH}${files[0].name}`} />
      <div className="model-name">{name}</div>
      <div className="model-number">
        #{ethId.padStart(6, "0")} / gen {gen}
      </div>
      {topThreeStats.includes("maxSpeed") && (
        <StatBar
          name="speed"
          value={maxSpeed}
          min={minMaxSpeed}
          max={maxMaxSpeed}
        />
      )}
      {topThreeStats.includes("acceleration") && (
        <StatBar
          name="acceleration"
          value={acceleration}
          min={minAcceleration}
          max={maxAcceleration}
        />
      )}
      {topThreeStats.includes("grip") && (
        <StatBar name="grip" value={grip} min={minGrip} max={maxGrip} />
      )}
      {topThreeStats.includes("handling") && (
        <StatBar
          name="handling"
          value={handling}
          min={minHandling}
          max={maxHandling}
        />
      )}
      {topThreeStats.includes("braking") && (
        <StatBar
          name="braking"
          value={braking}
          min={minBraking}
          max={maxBraking}
        />
      )}
      <div className="mobile-buttons">
        <div>
          <Link to={VIEW_CAR.replace(":uuid", uuid)} className="button primary">
            View
          </Link>
        </div>
      </div>
    </div>
  );
};

Card.propTypes = {
  car: PropTypes.object.isRequired,
  user: PropTypes.string.isRequired,
};

export default Card;
