import Scene from "../../Engine/Scene";
import CIRCUITS from "../Config/Circuits";
import Circuit from "../GameObjects/Circuit";
import PlayerStats from "../GameObjects/PlayerStats";
import RaceManager from "../GameObjects/RaceManager";
import SpotPointer from "../GameObjects/SpotPointer";
import Vehicle from "../GameObjects/Vehicle";

export const FINISHED_EVENT = "cm-race-finished";
export const POSITION_EVENT = "cm-race-position";

export default class Race extends Scene {
  raceData = null;
  finished = false;

  playerUsername = null;
  vehiclesByUser = null;

  raceManager = null;

  constructor(playerUsername, raceData, vehiclesByUser) {
    super();

    this.playerUsername = playerUsername;
    this.raceData = raceData;
    this.vehiclesByUser = vehiclesByUser;
  }

  start() {
    this.addGameObject(() => new RaceManager(this.raceData), "RaceManager");
    this.raceManager = this.getGameObject("RaceManager");

    this.setupCircuit();
    this.setupVehicles();
    this.addGameObject(() => new PlayerStats(), "PlayerStats");

    // For dev and debug purposes
    // this.addGameObject(() => new SpotPointer());
  }

  setupCircuit() {
    this.addGameObject(
      () => new Circuit(CIRCUITS[this.raceData.circuitUuid]),
      "Circuit"
    );
  }

  setupVehicles() {
    this.raceData.racers.forEach((racer) => {
      this.addGameObject(
        () =>
          new Vehicle({
            ...racer,
            player: racer.username === this.playerUsername,
            image:
              racer.bot === false
                ? this.vehiclesByUser.find((v) => v.username === racer.username)
                    .top_view
                : null,
          })
      );
    });
  }

  update() {
    this.finished = this.raceManager.finished;
    if (this.finished) {
      window.dispatchEvent(new CustomEvent(FINISHED_EVENT));
    }
  }
}
