import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import './SuccessMessage.css';

const SuccessMessage = ({
  title, message, link, linkText,
}) => (
  <div className="success-message">
    <i className="fas fa-check-circle" />
    <h1>{title}</h1>
    {message}
    {link !== '' && <Link className="button primary" to={link}>{linkText}</Link>}
  </div>
);

SuccessMessage.propTypes = {
  title: PropTypes.string.isRequired,
  message: PropTypes.arrayOf(PropTypes.object).isRequired,
  link: PropTypes.string,
  linkText: PropTypes.string,
};

SuccessMessage.defaultProps = {
  link: '',
  linkText: '',
};

export default SuccessMessage;
