import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { RadioButton } from '@trendmicro/react-radio';
import { Redirect } from 'react-router';
import '@trendmicro/react-radio/dist/react-radio.css';
import Checkbox from 'react-simple-checkbox';
import './Settings.css';
import Modal from 'react-responsive-modal';
import { HOME, LINK_WALLET } from '../../config/Routes';
import AuthContext from '../../context/AuthContext';
import { getAuthenticatedUser, updateUser } from '../../requests/User';

const SAVING = (
  <div>
    Saving...
    {' '}
    <i className="fas fa-spinner fa-spin" />
  </div>
);

const SUCCESS = 'Settings changed successfully';
const ERROR = 'Something went wrong';

class Settings extends Component {
  static contextType = AuthContext;

  state = {
    username: '',
    email: '',
    marketingUpdates: false,
    isEuResident: false,
    wallets: [],
    isLoading: true,
    message: '',
    modalMessage: '',
    isUsernameModalOpen: false,
    isEmailModalOpen: false,
  };

  saveHandler = () => {
    this.setState({ message: SAVING });
    const {
      username, email, isEuResident, marketingUpdates,
    } = this.state;

    updateUser(this.context.token, marketingUpdates, isEuResident, username, email)
      .then(() => this.setState({ message: SUCCESS }))
      .catch(() => this.setState({ message: ERROR }));
  };

  euResidentHandler = (e) => {
    const isEuResident = e.target.value;
    this.setState(() => ({
      isEuResident: isEuResident === 'true',
    }), this.saveHandler);
  };

  marketingUpdatesHandler = () => this.setState(prevState => ({
    marketingUpdates: !prevState.marketingUpdates,
  }), this.saveHandler);


  usernameModalCloseHandler = () => this.setState({ isUsernameModalOpen: false, modalMessage: '' });

  usernameModalOpenHandler = () => this.setState({ isUsernameModalOpen: true });

  usernameSaveHandler = (e) => {
    e.preventDefault();
    const username = e.target.username.value;

    updateUser(this.context.token, null, null, username, null)
      .then(() => {
        this.setState({
          message: SUCCESS,
          modalMessage: '',
          isUsernameModalOpen: false,
          username,
        });    
        this.context.updateUsername(username);
      })
      .catch(({ response: { data: { message } } }) => {
        this.setState({ modalMessage: message || ERROR });
      });
  };

  emailModalCloseHandler = () => this.setState({ isEmailModalOpen: false, modalMessage: '' });

  emailModalOpenHandler = () => this.setState({ isEmailModalOpen: true });

  emailSaveHandler = (e) => {
    e.preventDefault();
    const email = e.target.email.value;
    updateUser(this.context.token, null, null, null, email)
      .then(() => this.setState({
        message: SUCCESS,
        modalMessage: '',
        isEmailModalOpen: false,
        email,
      }))
      .catch(({ response: { data: { message } } }) => {
        this.setState({ modalMessage: message || ERROR });
      });
  };

  logoutHandler = () => {
    this.context.logout();
  };

  componentDidMount = () => {
    this.context.checkToken();
    getAuthenticatedUser(this.context.token)
      .then(({
        data: {
          username, wallets, email, isEuResident, marketingUpdates,
        },
      }) => this.setState({
        username,
        wallets,
        email,
        isEuResident,
        marketingUpdates,
        isLoading: false,
      }));
  };

  render() {
    const { authenticated } = this.context;
    if (!authenticated) {
      return <Redirect to={HOME} />;
    }

    const {
      isLoading,
      username,
      email,
      isEuResident,
      marketingUpdates,
      wallets,
      message,
      modalMessage,
      isUsernameModalOpen,
      isEmailModalOpen,
    } = this.state;

    if (isLoading) {
      return <i className="fas fa-spinner fa-spin standalone-loading" />;
    }

    return (
      this.context.authenticated ? (
        <div className="settings">
          <Modal
            open={isUsernameModalOpen}
            onClose={this.usernameModalCloseHandler}
            center
            showCloseIcon={false}
          >
            <div className="settings-modal">
              <form onSubmit={this.usernameSaveHandler}>
                <h1>Choose your new username</h1>
                <input
                  type="text"
                  placeholder="Username (only letters and numbers)"
                  id="username"
                  required="required"
                  pattern="[a-zA-Z0-9]+"
                  minLength="2"
                  title="Username should only contain letters and/or numbers, without spaces."
                />
                <div className="buttons">
                  <button type="button" className="secondary" onClick={this.usernameModalCloseHandler}>Cancel</button>
                  <button type="submit" className="primary">Save</button>
                </div>
                {modalMessage && (
                  <div className="saving">
                    {modalMessage}
                  </div>
                )}
              </form>
            </div>
          </Modal>
          <Modal
            open={isEmailModalOpen}
            onClose={this.emailModalCloseHandler}
            center
            showCloseIcon={false}
          >
            <div className="settings-modal">
              <form onSubmit={this.emailSaveHandler}>
                <h1>Choose your new email address</h1>
                <input type="email" placeholder="Email Address" id="email" required="required" />
                <div className="buttons">
                  <button type="button" className="secondary" onClick={this.emailModalCloseHandler}>Cancel</button>
                  <button type="submit" className="primary">Save</button>
                </div>
                {modalMessage && (
                  <div className="saving">
                    {modalMessage}
                  </div>
                )}
              </form>
            </div>
          </Modal>
          <div className="center">
            <h1>User settings</h1>
            <hr className="gray double" />
            <div className="username-email">
              <div className="three-section">
                <div>Username:</div>
                <div className="bold">{username}</div>
                <div className="edit">
                  <button type="button" className="link" onClick={this.usernameModalOpenHandler}>Edit</button>
                </div>
              </div>
              <div className="three-section">
                <div>
                  Email
                  <span className="hide-mobile"> address</span>
                  :
                </div>
                <div className="bold">{email}</div>
                <div className="edit">
                  <button type="button" className="link" onClick={this.emailModalOpenHandler}>Edit</button>
                </div>
              </div>
            </div>
            <hr className="gray" />
            <div className="eu-resident-option">
              <p className="eu-resident">
              Are you an EU resident?
              </p>
              <RadioButton
                label="No"
                name="eu"
                value="false"
                checked={!isEuResident}
                onClick={this.euResidentHandler}
              />
              <RadioButton
                label="Yes"
                name="eu"
                value="true"
                checked={isEuResident}
                onClick={this.euResidentHandler}
              />
            </div>
            <hr className="gray" />
            <div className="marketing-updates">
              <Checkbox
                name="marketing"
                size={2}
                borderThickness={1}
                tickSize={3}
                color="#B18F4F"
                checked={marketingUpdates}
                onChange={this.marketingUpdatesHandler}
              />
              I want to get marketing updates.
            </div>
            <hr className="gray" />
            <div className="wallets">
              <span className="bold">Linked wallets</span>
              {wallets.map(({ address, totalVehicles, type }) => (
                <div className="three-section" key={address}>
                  <div className="address">
                    {address.substring(0, 6)}
                    ...
                    {address.substring(address.length - 4)}
                  </div>
                  <div className="bold">
                    { totalVehicles }
                    {' '}
                    cars
                  </div>
                </div>
              ))}
              <Link to={LINK_WALLET} className="secondary">Link new wallet</Link>
            </div>
            {message && (
            <div className="saving">
              {message}
            </div>
            )}
            <hr className="gray" />
            <div className="logout-link">
              <button type="button" className="link logout" onClick={this.logoutHandler}>Log out</button>
            </div>
          </div>
        </div>
      )
        : <Redirect to={HOME} />
    );
  }
}

export default Settings;
