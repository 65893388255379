import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import Analytics from 'react-router-ga';
import {
  Switch,
  Redirect,
  Route,
  withRouter,
} from 'react-router-dom';

import Auctions from '../Auctions/Auctions';
import Garage from '../Garage/Garage';
import Home from '../Home/Home';
import Market from '../Market/Market';
import MarketCar from '../MarketCar/MarketCar';
import NotFound from '../NotFound/NotFound';
import Settings from '../Settings/Settings';
import SignUp from '../SignUp/SignUp';
import About from '../About/About';
import '../Common/Common.css';
import './Layout.css';
import {
  ABOUT,
  AUCTIONS,
  CONFIRM_EMAIL,
  DESIGN_CHALLENGE,
  DESIGN_CHALLENGE_SUCCESS,
  DESIGN_CHALLENGE_TERMS,
  FAQ,
  GAME,
  GAME_SCORE,
  GAME_SUMMARY,
  GARAGE,
  HOME,
  IMPRESSUM,
  FACEBOOK_AUTH,
  LINK_WALLET,
  LOG_IN,
  MARKET,
  MARKET_CAR,
  NOT_FOUND,
  PRIVACY_POLICY,
  RECOVER_PASSWORD,
  RESET_PASSWORD,
  SETTINGS,
  SIGN_UP,
  TERMS_OF_USE,
  VIEW_CAR,
  VISION,
  DESIGN_CHALLENGE_DASHBOARD,
  EVENT_DROP
} from '../../config/Routes';
import LogIn from '../LogIn/LogIn';
import Menu from '../Menu/Menu';
import FacebookAuth from '../FacebookAuth/FacebookAuth';
import LinkWallet from '../LinkWallet/LinkWallet';
import MenuMobile from '../MenuMobile/MenuMobile';
import Footer from '../Footer/Footer';
import FooterMobile from '../FooterMobile/FooterMobile';
import Impressum from '../Impressum/Impressum';
import TermsOfUse from '../TermsOfUse/TermsOfUse';
import PrivacyPolicy from '../PrivacyPolicy/PrivacyPolicy';
import Faq from '../Faq/Faq';
import Vision from '../Vision/Vision';
import ViewCar from '../ViewCar/ViewCar';
import ConfirmEmail from '../ConfirmEmail/ConfirmEmail';
import RecoverPassword from '../RecoverPassword/RecoverPassword';
import ResetPassword from '../RecoverPassword/ResetPassword';
import DesignChallenge from '../DesignChallenge/DesignChallenge';
import RegisterSuccess from '../DesignChallenge/RegisterSuccess';
import DesignTerms from '../DesignChallenge/DesignTerms';
import DesignChallengeDashboard from '../DesignChallengeDashboard/DesignChallengeDashboard';
import Game from '../Game';
import Score from '../Game/components/Score';
import Summary from '../Game/components/Summary/Summary';
import DropEvent from '../DropEvent/DropEvent';

const Layout = ({ location: { pathname } }) => {
  const debug = window.location.hostname !== 'www.cryptomotors.io';

  return (
    <Fragment>
      <Analytics id={debug ? 'no-code' : 'UA-124177179-1'} debug={debug}>
        <header>
          <Menu pathname={pathname} />
          <MenuMobile pathname={pathname} />
        </header>
        <main>
          <Switch>
            <Route exact path={ABOUT} component={About} />
            <Route exact path={AUCTIONS} component={Auctions} />
            <Route exact path={CONFIRM_EMAIL} component={ConfirmEmail} />
            <Route exact path={DESIGN_CHALLENGE} component={DesignChallenge} />
            <Route exact path={DESIGN_CHALLENGE_SUCCESS} component={RegisterSuccess} />
            <Route exact path={DESIGN_CHALLENGE_TERMS} component={DesignTerms} />
            {/* <Route exact path={DESIGN_CHALLENGE_UPLOAD} component={UploadDesign} /> */}
            <Route exact path={GAME} component={Game} />
            <Route exact path={GAME_SCORE} component={Score} />
            <Route exact path={GAME_SUMMARY} component={Summary} />
            <Route exact path={GARAGE} component={Garage} />
            <Route exact path={FACEBOOK_AUTH} component={FacebookAuth} />
            <Route exact path={FAQ} component={Faq} />
            <Route exact path={HOME} component={Home} />
            <Route exact path={IMPRESSUM} component={Impressum} />
            <Route exact path={LINK_WALLET} component={LinkWallet} />
            <Route exact path={LOG_IN} component={LogIn} />
            <Route exact path={MARKET} component={Market} />
            <Route exact path={MARKET_CAR} component={MarketCar} />
            <Route exact path={PRIVACY_POLICY} component={PrivacyPolicy} />
            <Route exact path={RECOVER_PASSWORD} component={RecoverPassword} />
            <Route exact path={RESET_PASSWORD} component={ResetPassword} />
            <Route exact path={SETTINGS} component={Settings} />
            <Route exact path={SIGN_UP} component={SignUp} />
            <Route exact path={TERMS_OF_USE} component={TermsOfUse} />
            <Route exact path={VIEW_CAR} component={ViewCar} />
            <Route exact path={VISION} component={Vision} />
            <Route exact path={EVENT_DROP} component={DropEvent} />
            <Route path={DESIGN_CHALLENGE_DASHBOARD} component={DesignChallengeDashboard} />
            <Route path="/404" component={NotFound} />
            <Redirect from="*" to={NOT_FOUND} />
          </Switch>
        </main>
        {pathname !== '/market/auctions' && (
        <footer>
          <Footer pathname={pathname} />
          <FooterMobile pathname={pathname} />
        </footer>
        )}
      </Analytics>
    </Fragment>
  );
};

Layout.propTypes = {
  location: PropTypes.shape({
    location: PropTypes.shape({ pathname: PropTypes.string.isRequired }),
  }).isRequired,
};

export default withRouter(Layout);
