import React from "react";
import "./DropEvent.css";
import { MARKET_CAR, MODEL_RETRO_ROCKET } from "../../config/Routes";

const DropEvent = () => {
  const IMG_URL = "./images/drop-events/meet_the_drop_banner.png";
  const IMG_UNITS = "./images/drop-events/models_units.png";
  const IMG_CLOSEUP = "./images/drop-events/closeup.png";
  const IMG_KING_COOPER = "./images/drop-events/king_cooper.png";
  const IMG_KING_COOPER2 = "./images/drop-events/king_cooper2.png";
  const OPEN_SEA_PATH =
    "https://opensea.io/assets/cryptomotors?search[stringTraits][0][name]=Gen&search[stringTraits][0][values][0]=Design%20Legends&search[sortAscending]=true&search[sortBy]=PRICE";

  return (
    <div className="drop-event-container">
      <div className="event-banner">
        <img src={IMG_URL} alt="Drop Banner" />
        <h1 class="desktop">
          Design Legends: Retro Rocket - November 20 & 25th
        </h1>
      </div>
      <div className="presentation-video-container mt-5">
        <h2 className="event-sub-title">
          We are launching our first generation of Design Legends cars in
          collaboration with world-renowned designer Scott Robertson. The first
          batch drops on November 20th at 11.30 AM EST / 5.30 PM CET / 11.30 PM
          HKT
        </h2>
        <div>
          <div>
            <iframe
              className="video"
              width="966"
              height="443"
              src="https://www.youtube.com/embed/WDffGUhkRCA"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            />
          </div>
        </div>
      </div>
      <div className="event-info-container">
        <h2 className="drop-edition mb-5">
          Introducing Design Legends Generation
        </h2>
        <div className="introducing">
          <p>
            Design Legends is a new generation of CryptoMotors cars we made
            collaboratively with world-famous designers. These cars are imagined
            and handcrafted by professionals that we admire and respect and have
            been a constant source of inspiration for our team.
          </p>
          <p>
            During the creation of these vehicles, we sit through many sprints
            with legendary designers to define and create a new and disruptive
            car concept together. Our team then transforms those concepts into a
            top-quality, high-interoperability NFT car that collectors can enjoy
            across the Metaverse.
          </p>
          <p>You can read more about the Design Legends Generation, here.</p>
        </div>
      </div>
      <div className="event-info-container" id="about-the-drop">
        <h2 className="drop-edition mb-5">About the drop</h2>
      </div>
      <div className="drop-event-content">
        <div className="outer-box">
          <div className="inner-box">
            <div className="content-info">
              <div className="content-text launch">
                <p>
                  Starting on November 20th, we will be launching our first
                  collection of Design Legends cars made in collaboration with
                  legendary American car designer Scott Robertson.
                </p>
                <p>
                  This drop brings a new model to our digital lineup: The Retro
                  Rocket Razer. The Retro Rocket is a concept car that combines
                  American design heritage with a futuristic style into a
                  unique, powerful, and elegant NFT vehicle.
                </p>
              </div>
              <div>
                <div className="models-units">
                  <img src={IMG_UNITS} />
                </div>
                <div className="buy-now-link">
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href={OPEN_SEA_PATH}
                    className="button primary big opaque"
                  >
                    BUY NOW
                  </a>
                </div>
              </div>
            </div>
            <div className="content-info">
              <div className="models-units">
                <img src={IMG_CLOSEUP} />
              </div>
              <div class="content-text launch with-margin">
                <p>
                  <b>The drop will be released in two batches.</b> The first one
                  will feature the Retro Rocket King Copper, a one-of-one
                  collectible NFT car with a unique design and color. The second
                  batch will include five models with different rarity levels,
                  colors, stats, and traits. Collectors will be able to acquire
                  these NFT cars through OpenSea.
                </p>
                <p>
                  <b>
                    All the cars in the drop will be sold in REVV. The price in
                    REVV will be established based on the ETH price at the
                    launch date. More details, below.
                  </b>
                </p>
              </div>
            </div>
            <div className="event-info-container">
              <h2
                id="design-legends-log"
                className="drop-edition mb-5 with-margin"
              >
                Design Legends Log - Entry #001
              </h2>
              <div className="introducing italics">
                <p>
                  In Scott's world, racing for your life is the norm. And he
                  lives by it.
                </p>
                <p>
                  Word has it that, many years ago, Scott designed a legendary
                  car that could beat anyone who dared to pose a challenge.
                </p>
                <p>
                  The vehicle was so fast and powerful that no wise person in
                  his world wanted it to race him anymore. So Scott couldn't
                  find a rival of worth for a long time.
                </p>
                <p>
                  But there are others like Scott in infinite Multiverses.
                  Prodigies born on distant planets who have created the
                  greatest cars ever witnessed. Folks called them Design
                  Legends, and the name stuck.
                </p>
                <p>
                  Finally, the time has come for the Design Legends to compete
                  in the ultimate racing arena: The Metaverse. The first of
                  those design legends cars has arrived, and only a few pilots
                  will have the privilege to drive them.
                </p>
              </div>
            </div>
            <div>
              <h1 className="drop-edition mb-5">
                <b>About Scott Robertson</b>
              </h1>
            </div>
            <div className="content-info">
              <div className="content-image" id="scott-robertson">
                <p>
                  Scott Robertson is a world-renowned designer, author, and
                  educator. He has an extensive career in transportation and
                  industrial design and has worked with many noteworthy brands
                  like Nissan, Volvo, Yamaha, BMW, Mattel, Hasbro, Patagonia,
                  Nike, Microsoft, Universal Studios, and Apple, among others.
                </p>
                <p>
                  We partnered with Scott to design and create the first series
                  of Design Legends cars. You can read more about collaboration
                  with Robertson here.
                </p>
              </div>
              <div className="content-text">
                <img
                  src="/images/drop-events/drop_event_img1.png"
                  alt="Special Neon Roadster"
                />
              </div>
            </div>
            <div>
              <h1 className="drop-edition mb-5">
                <b>Retro Rocket - Design Legends GEN Drop</b>
              </h1>
            </div>
            <img
              id="rocket-units"
              src="/images/drop-events/retro_rocket_units.jpeg"
              alt="Special Neon Roadster"
            />
            <img
              id="rocket-units-long"
              src="/images/drop-events/retro_rocket_units_long.png"
              alt="Special Neon Roadster"
            />
            <div className="will-be-winner">
              <span>Prices</span>
              <span>
                (The price in REVV will be established based on ETH price at the
                moment of the sale)
              </span>
              <p>
                <b>King Copper - 1 unit -</b> Auction: starting on <b>3 ETH</b>
              </p>
              <p>
                <b>G-Rad / Top-Hat / Criss-Cross - 6 units (2 per model) -</b>{" "}
                Auction: starting on <b>2 ETH</b>
              </p>
              <p>
                <b>Stealth Green - 14 units -</b>Fixed price: <b>1.5 ETH</b>
              </p>
              <p>
                <b>Tech Red - 30 units -</b> Fixed price: <b>1.2 ETH</b>
              </p>
            </div>
            <div className="event-info-container">
              <h2 className="drop-edition mb-5">The legendary King Copper</h2>
              <div className="introducing italics">
                <span>
                  <b>One King to rule the Metaverse.</b>
                </span>
                <p>
                  The Retro Rocket Razer King Copper is a one-of-one NFT car
                  created by the legendary designer Scott Robertson. With a
                  unique color, design, and stats, this champion racer will
                  stand out wherever it goes in the Metaverse.
                </p>
                <p>
                  The King Copper will be available for purchase through an
                  auction on OpenSea starting on November 20th. Besides getting
                  this incredible, one-of-a-kind NFT car, the collector who wins
                  the auction will be awarded with physical artwork signed by
                  Scott Robertson himself.
                </p>
                <p>
                  The certified art piece will be packed and shipped to anywhere
                  in the world.
                </p>
                <img src={IMG_KING_COOPER} />
                <p>Will you be the owner of the legendary King Copper?</p>
              </div>
            </div>
            <div className="buy-now-bottom">
              <a
                rel="noopener noreferrer"
                href="https://opensea.io/collection/cryptomotors?search[query]=Retro%20Rocket%20&search[sortAscending]=true&search[sortBy]=PRICE"
                className="button primary big visit-car-page opaque"
                target="_blank"
              >
                See it on Open Sea
              </a>
            </div>
            <div className="event-info-container">
              <h2 className="drop-edition mb-5">Interoperability</h2>
              <div className="introducing">
                <p>
                  The Retro Rocket has the same interoperability as any other
                  CryptoMotors NFT car. That means, users will be able to enjoy
                  this car in every game and virtual world that supports our
                  digital vehicles: Decentraland, Cryptovoxels, REVV Racing, and
                  The Sandbox Game.
                </p>
                <p>
                  At the same time, players will also be able to race with the
                  Retro Rocket in our play-to-earn STAT game and our upcoming 3D
                  game. We plan to continue expanding the integrations of our
                  cars and forging new partnerships with more games and
                  platforms!
                </p>
                <img src={IMG_KING_COOPER2} />
              </div>
            </div>
            <div className="event-info-container">
              <h2 className="drop-edition mb-5">The Retro Rocket Razer</h2>
              <div className="introducing">
                <p>
                  Handcrafted by a skilled team of gearheads with a passion for
                  the past and a technical eye towards the future, The Retro
                  Rocket Razer is a perfect blend of classic design and high-end
                  technology.
                </p>
                <p>
                  Utilizing an electric propulsion system to maximize torque and
                  a supercapacitor hybrid system to complement the graphene
                  technology woven throughout the monocoque construction, the
                  Retro Rocket packs an extreme punch off the line.
                </p>
                <p>
                  The Retro Rocket is made for the most demanding pilots.
                  Featuring in-wheel suspension, motors, and brakes in all four
                  wheels, this killer machine poses no driver-assist to aid you
                  in your quest for the winner's circle. Combining old-school
                  proportions with modern surfacing and aero-inspired elements,
                  the Retro Rocket stands out as a unique presence in the
                  Metaverse.
                </p>
                <p>
                  Read all the information about this vehicle on the Retro
                  Rocket car page.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="buy-now-bottom">
        <a
          rel="noopener noreferrer"
          href={MARKET_CAR.replace(":model", MODEL_RETRO_ROCKET)}
          className="button primary big visit-car-page opaque"
        >
          Visit Retro Rocket Page
        </a>
      </div>
      <div className="logos-container mb-5">
        <div className="mb-5" />
        <div>
          <div>
            <img src="/images/drop-events/redd_logo.svg" alt="Redd" />
          </div>
          <div>
            <img src="/images/drop-events/animoca_logo.svg" alt="Animoca" />
          </div>
          <div>
            <img src="/images/drop-events/whale_logo.svg" alt="WhaleShark" />
          </div>
          <div>
            <img src="/images/drop-events/opensea_logo.svg" alt="OpenSea" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default DropEvent;
