import React from 'react';

import './Loading.css';

const Loading = () => (
  <div id="circleG">
    <div id="circleG_1" className="circleG" />
    <div id="circleG_2" className="circleG" />
    <div id="circleG_3" className="circleG" />
  </div>
);

export default Loading;
