import React, { Component } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import moment from 'moment';
import AuthContext from '../../../../context/AuthContext';
import Game from '../Engine/Game';
import UserDetails from '../Utils/UserDetails';
import MusicHelper from '../Utils/MusicHelper';
import ShareLink from 'react-twitter-share-link';
import { Link, Redirect } from 'react-router-dom';
import Race, { FINISHED_EVENT, POSITION_EVENT } from '../Race/Scenes/Race';
import { GAME_API_URL, API_URL } from '../../../../config/Config';
import { GAME } from '../../../../config/Routes';
import '../../css/style.css';

class Summary extends Component {
  constructor() {
    super();
    this.containerRef = React.createRef();
    this.helperButtonRef = React.createRef();
    this.positionRef = React.createRef();
  }

  static contextType = AuthContext;
  
  static propTypes = {
    match: PropTypes.shape({
      params: PropTypes.shape({ uuid: PropTypes.string.isRequired }).isRequired,
    }).isRequired,
  };

  state = {
    raceExpired: false,
    isLoading: true,
    race: null,
    loggedUserScore: {},      
    vehicleByUser: [],
    gameCreated: false,
    simluationFinished: null,
    countdown: {
      hours: "00",
      minutes: "00",
      seconds: "00",
      milliseconds: null
    },
  };

  async componentDidMount() {      
     

    const token = this.context.token;
    const uuid = this.props.match.params.uuid;
    
    const getRace = async (GAME_API_URL, token, uuid) => {
      try {
        const race = (
          await axios({
          method: 'GET',
          headers: { Authorization: token},
          url: `${GAME_API_URL}/races/${uuid}`,
        })).data;
        return race;
      } catch (error) {
        this.setState({
          raceExpired: true,
        })
      }
    }
    const race = await getRace(GAME_API_URL, token, uuid);

    const raceStatus = JSON.parse(localStorage.getItem('raceStatus'));

    if(raceStatus && this.state.raceExpired === false){      
      localStorage.setItem('raceStatus', JSON.stringify({
        status: "running", 
        race: raceStatus.race,
        selectedCircuit: raceStatus.selectedCircuit,
        selectedVehicle: raceStatus.selectedVehicle
      }));
    }   

    const userData = (await axios({
      method: 'GET',
      url: `${GAME_API_URL}/score`,
      headers: { Authorization: this.context.token },
    })).data;

    let userActualScore = { username: this.context.username, score: 0, rankingPoints: 0};

    if(userData.scores.length > 0 && this.state.raceExpired === false) {
      const circuitScore = userData.scores.find(c => c.circuitUuid === race.circuitUuid);
      userActualScore = {
        score: circuitScore.score,
        rankingPoints: circuitScore.rankingPoints,
        ranked: circuitScore.ranked,
        circuitName: circuitScore.circuitName,
        racingsLeftToRank: circuitScore.racingsLeftToRank,
        username: this.context.username
      };  
    }

    let simulationIsFinished;
    if(simulationIsFinished === null){
      simulationIsFinished = true;
    }else{
      simulationIsFinished = localStorage.getItem('simulationFinished') === "true";
    }
      
    this.setState({ 
      isLoading: false, 
      race,    
      simluationFinished: simulationIsFinished,
      loggedUserScore: userActualScore,      
    });
    
    if (this.state.raceExpired === false) {
      race.racers.filter(r => !r.bot).map((player) => {               
        axios({
          method: 'GET',
          url: `${API_URL}/vehicles/${player.vehicleUuid}`,
        }).then(response => {           
          const element = {username: player.username, top_view: response.data.top_view.replace("http://stage.cryptomotors.io", "")};    
          this.setState(prevState => ({
            vehicleByUser: [...prevState.vehicleByUser, element]
          }))                                     
        }).catch(err => console.log(err));    
        return;
      });     
    }
    
    if (this.state.raceExpired === false) {
      this.interval = setInterval(() => {
        const now = moment.duration(moment(`${race.startAt}-0000`).diff(moment()));
        const hours =  now.hours().toString().padStart(2, '0');
        const minutes = now.minutes().toString().padStart(2, '0');
        const seconds = now.seconds().toString().padStart(2, '0');
        const milliseconds =  now.milliseconds();              
        
        this.setState({ 
          countdown: {
            hours,
            minutes,
            seconds,
            milliseconds
          },
        });
  
      }, 1000);    
    }
          
  }

  componentWillUnmount() {
    if (this.interval) {
      clearInterval(this.interval);      
    }

    if(this.callRaceInterval){
      clearInterval(this.callRaceInterval);    
    }
    
  }

  async callRaceData () {
    const refreshRace = (await axios({
      method: 'GET',
      headers: { Authorization: this.context.token },
      url: `${GAME_API_URL}/races/${this.props.match.params.uuid}`,
    })).data;

    this.setState({
      race: refreshRace,
    })
    
  }

  ordinalSufix = (i) => {
    const j = i % 10;

    const k = i % 100;
    if (j === 1 && k !== 11) {
      return `${i}st`;
    }
    if (j === 2 && k !== 12) {
      return `${i}nd`;
    }
    if (j === 3 && k !== 13) {
      return `${i}rd`;
    }
    return `${i}th`;
  }

  userPosition = (vehicleLaps) => {
    const positions = {
      1: 'first',
      2: 'second',
      3: 'third',
      4: 'fourth',
      5: 'fifth',
      6: 'sixth',
      7: 'seventh',
      8: 'eighth',
      9: 'ninth',
      10: 'tenth',
    };

    const found = vehicleLaps.find(lap => lap.username === this.context.username);
    if (!found) {
      return null;
    }

    if (found.position === vehicleLaps.length) {
      return 'last';
    }

    return positions[found.position];
  }

  handlePlayHelper = () => {
    if(this.helperButtonRef.current){
        this.helperButtonRef.current.handlePlayStopMusic();
    }
  }

  render() {
    
    const { raceExpired, isLoading, race, loggedUserScore, countdown, simluationFinished, vehicleByUser, gameCreated } = this.state;    
    if (raceExpired) {
      localStorage.setItem('simulationFinished', true);
      localStorage.setItem('raceExpiredUUID', this.props.match.params.uuid);
      return (
        <Redirect to={GAME}/> 
      );
    }

    if (isLoading) {
      return (
        <div className="game game-page">
          <div className="banner__area race-summary-page-area monthly-page">
            <div className="page-area">
              <div className="single__banner banner_bg single__banner2  bann-800">
                <div className="container wi-1000 pt-100">
                  <i className="fas fa-spinner fa-spin standalone-loading-white" />
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    }
    
    if(countdown.milliseconds < 0 && raceExpired !== true){
      clearInterval(this.interval);
      clearInterval(this.callRaceInterval); //Prevent blink on trigger
      this.callRaceInterval = setInterval(() => {
        this.callRaceData();            
      }, 1000);
    }
    
    if(race.laps.length !== 0 && this.containerRef.current !== null && raceExpired !== true){       
        clearInterval(this.callRaceInterval);
    }

    if (race.laps.length === 0 && raceExpired !== true) {           
      return (
        <div className="game">
          <div className="game-page">
            <div className="single__banner banner_bg single__banner2 bann-800 race-starts-page-area">
              <div className="container wi-1000 pt-100 pb-180">
                <UserDetails
                  hideScore={true}                  
                  username={loggedUserScore.username}
                  score={loggedUserScore.rankingPoints}
                  canPlayStop={true}
                  handleMusicPlay={this.handlePlayHelper} 
                  isInRank={loggedUserScore.ranked}
                  circuitName={loggedUserScore.circuitName}
                  racingsLeftToRank={loggedUserScore.racingsLeftToRank}                     
                />
                <MusicHelper
                  ref={this.helperButtonRef}
                />
                <div className="row">
                  <div className="col-12">
                    <div className="all-heading">
                      {countdown.milliseconds < 0 ? 
                        <div>
                          <h2>Loading simulation...</h2>     
                        </div>
                      :
                        <div>
                          <h2>
                            Race starts in:
                          {' '}
                            <br />
                            <span>{`${countdown.hours}:${countdown.minutes}:${countdown.seconds}`}</span>
                          </h2>
                          <p className="waiting-players">Waiting for other players to join the lobby</p>
                        </div>
                      }
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div >
      );
    }
    
    if(race.laps.length !== 0 && !simluationFinished && raceExpired !== true){                
      if(this.containerRef.current){                            
        // Create the Game        
        if(!gameCreated && vehicleByUser.length === race.racers.filter(r => !r.bot).length){   
          this.setState({gameCreated: true});                         
          const game = new Game(this.containerRef.current, 900, 502);          
          game.addScene('Race', () => new Race(this.context.username, race, vehicleByUser));
          game.run();          
        
          window.addEventListener(FINISHED_EVENT, () => {                 
            setTimeout(() => game.stop(), 3000);
            localStorage.setItem('simulationFinished', true);
            this.setState({            
              simluationFinished: true
            });
          });

          //Position Event in every frame          
          window.addEventListener(POSITION_EVENT, (e) => {                   
            let results = "";           
            e.detail.forEach(p => {              
              results += `<div class="pos-item ${p.isPlayer ? 'is-player' : ''}"><div class="pos-number">${p.position}</div><div class="pos-name">${p.username}</div></div><br>`              
            });  
            if(this.positionRef.current){  
              this.positionRef.current.innerHTML = results;                                               
            }  
          });          
        }
      }
   
      return (
        <div className="game">
          <div className="game-page">
            <div className="single__banner banner_bg single__banner2 bann-800 race-starts-page-area">
              <div className="container wi-1000 pt-100 pb-180">
                <UserDetails
                  hideScore={true}
                  username={loggedUserScore.username}
                  score={loggedUserScore.rankingPoints}
                  canPlayStop={true}
                  handleMusicPlay={this.handlePlayHelper} 
                  isInRank={loggedUserScore.ranked}   
                  circuitName={loggedUserScore.circuitName}  
                  racingsLeftToRank={loggedUserScore.racingsLeftToRank}                            
                />
                <MusicHelper
                  ref={this.helperButtonRef}
                />
                <div className="row">
                  <div className="col-12">
                    <div className="all-heading">  
                      <div>
                        <h2>Race is running</h2> 
                        <div className="simlation-position-container mt-5">
                          <div className="media-container">
                            <div id="containerRef" className="simulation-container" ref={this.containerRef}></div>
                          </div>
                          <div className="position-container">                          
                            <div className="pos-sub-container" ref={this.positionRef}/>                          
                          </div>
                        </div>
                      </div>                    
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div >
      );
    }
    
    if(simluationFinished && raceExpired !== true){
    
      const userPosition = this.userPosition(race.laps[race.laps.length - 1].vehicleLaps);        
      localStorage.removeItem('raceStatus');

      return (
        <div className="game game-page">
          <div className="banner__area race-summary-page-area">
            <div className="page-area">
              <div className="single__banner banner_bg single__banner2 single__banner3 bann-800 summary-h" style={{backgroundImage: `url(/game-images/tracks-preview/big-${race.circuitName}.jpg)`}}>
                <div className="container wi-1000 pt-100">
                  <UserDetails
                    username={loggedUserScore.username}
                    score={loggedUserScore.rankingPoints}
                    canPlayStop={true}
                    handleMusicPlay={this.handlePlayHelper} 
                    isInRank={loggedUserScore.ranked}  
                    circuitName={loggedUserScore.circuitName}
                    racingsLeftToRank={loggedUserScore.racingsLeftToRank}                               
                  />
                  <MusicHelper
                    ref={this.helperButtonRef}
                  />
                  <div className="row">
                    <div className="col-12">
                      <div className="all-heading">
                        <h2>Race summary</h2>
                          <p className="summary-race-name">{race.circuitName}</p>
                      </div>
                    </div>
                    {race.laps.map(lap => (
                      <div className="col-lg-6" key={lap.lapNumber} align="center">
                        <div className="starts_wrap wi-300">
                          <div className="bordered_title">
                            <h4>
                              {`LAP ${lap.lapNumber}`}
                            </h4>
                          </div>
                          <div className="lines">
                            {lap.vehicleLaps.map(vehicle => (
                              <div className="single_line d-flex justify-content-between" key={vehicle.position}>
                                <span className="left line_wrap">{vehicle.username}</span>
                                <span className="right line_wrap">{this.ordinalSufix(vehicle.position)}</span>
                              </div>
                            ))}
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
                {userPosition && (
                  <div className="mo-footer">
                    <div className="box-container">
                      <div className="social-points">
                        <p className="points-obtained">You won {race.racers.find(user => user.username === this.context.username).score} points</p>
                        <p className="position-msg">{`You’ve finished ${userPosition}!`}</p>
                        <ShareLink 
                          link={'https://www.cryptomotors.io/'} 
                          text={`I just finished ${userPosition} in the @CryptoMotors_io STAT alpha game race! 🤩 \n\nCome join and race!`}>

                          {link => (
                              <a className="share-twitter" href={link} target='_blank' rel="noopener noreferrer">Share on Twitter</a>
                          )}
                        </ShareLink>
                      </div>
                      <div className="race-again-btn">
                        {<Link to="/stat-game" className="boxed_btn race_again">RACE AGAIN</Link>}
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      ); 
    }
  }
}

export default Summary;
