import React, { useContext, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Link, Redirect } from "react-router-dom";
import axios from "axios";
import { MARKET, NOT_FOUND } from "../../config/Routes";
import { API_URL } from "../../config/Config";
import AuthContext from "../../context/AuthContext";
import Card from "./Card";
import Borrow from "./Borrow";
import { useToasts } from "react-toast-notifications";
import "./Garage.css";

const Garage = (props) => {
  const authContext = useContext(AuthContext);
  const { addToast } = useToasts();
  const [cars, setCars] = useState(null);
  const [selectedCar, setSelectedCar] = useState({
    uuid: "",
    ethId: "",
    name: "",
    fileName: "",
    borrower: "",
  });
  const [showmodal, setShowModal] = useState(false);
  const [merror, setMerror] = useState(false);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [reload, setReload] = useState(false);

  useEffect(() => {
    fetchCars();
  }, [reload]);

  const fetchCars = () => {
    axios({
      method: "GET",
      url: `${API_URL}/users/${props.match.params.user}/vehicles`,
    })
      .then(({ data }) => {
        setCars(data);
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
        setError(true);
      });
  };

  const handleBorrowCar = (username, vehicleuuid) => {
    if (!username) {
      setMerror(true);
      return;
    }
    axios({
      method: "POST",
      url: `${API_URL}/borrow-vehicles`,
      headers: { Authorization: authContext.token },
      data: {
        vehicle: vehicleuuid,
        borrower: username,
      },
    })
      .then(() => {
        setShowModal(false);
        addToast("Lent Successfully", {
          appearance: "success",
          autoDismiss: true,
        });
        setReload(!reload);
      })
      .catch(() => {
        addToast("Incorrect Username", {
          appearance: "error",
          autoDismiss: true,
        });
      });
  };

  const handleTakeBackCar = (vehicleuuid) => {
    axios({
      method: "POST",
      url: `${API_URL}/borrow-vehicles/cancel`,
      headers: { Authorization: authContext.token },
      data: {
        vehicle: vehicleuuid,
      },
    }).then(() => {
      addToast("Successfully Returned", {
        appearance: "success",
        autoDismiss: true,
      });
      setReload(!reload)            
    })
    .catch(() => {
      addToast("There was an error, please try again later", {
        appearance: "error",
        autoDismiss: true,
      });
    });
  };

  const handleShowModalAndSetData = (uuid, ethId, name, fileName, borrower) => {
    setShowModal(!showmodal);
    setMerror(false);
    setSelectedCar({
      uuid,
      ethId,
      name,
      fileName,
      borrower,
    });
  };

  const handleCloseModal = () => {
    setShowModal(!showmodal);
  };

  const { user } = props.match.params;

  if (loading) {
    return <i className="fas fa-spinner fa-spin standalone-loading" />;
  }

  return !error ? (
    <div className="garage">
      {showmodal ? (
        <Borrow
          error={merror}
          car={selectedCar}
          onClickClose={handleCloseModal}
          onClickBorrow={handleBorrowCar}
          onClickTakeBack={handleTakeBackCar}
        />
      ) : null}
      <div className="header">
        <i className="far fa-user" />
        {user}
      </div>
      <div className="cards">
        <div>
          {cars.length > 0 ? (
            cars.map((car) => (
              <Card
                key={car.uuid}
                car={car}
                user={user}
                onClickBorrow={handleShowModalAndSetData}
                onClickTakeBack={handleTakeBackCar}
              />
            ))
          ) : (
            <div className="no-cars">
              <h2>Looks like your garage is empty</h2>
              <p>Check out our marketplace to find the perfect fit for you!</p>

              <Link className="button primary" to={MARKET}>
                I want my first car
              </Link>
            </div>
          )}
        </div>
      </div>
    </div>
  ) : (
    <Redirect to={NOT_FOUND} />
  );
};

Garage.propTypes = {
  props: PropTypes.shape({
    match: PropTypes.shape({
      params: PropTypes.shape({ user: PropTypes.string.isRequired }).isRequired,
    }).isRequired,
  }),
};

export default Garage;