import SpriteRenderer from "../../Engine/Components/SpriteRenderer";
import GameObject from "../../Engine/GameObject";
import Vector2 from "../../Engine/Helper/Vector2";
import Sprite from "../../Engine/Sprite";

const STAR_SPRITE = "/game-stats/star-solid.png";

export default class PlayerStar extends GameObject {
  raceManager = null;
  spriteRenderer = null;

  constructor() {
    super();

    const image = new Image();
    image.src = STAR_SPRITE;

    this.addComponent(
      () =>
        new SpriteRenderer({
          sprite: new Sprite({
            image: image,
            width: 10,
            height: 10,
          }),
        }),
      "SpriteRenderer"
    );
    this.spriteRenderer = this.getComponent("SpriteRenderer");
    this.spriteRenderer.active = false;
  }

  start() {
    this.raceManager = this.scene.getGameObject("RaceManager");
  }

  update() {
    this.spriteRenderer.active = this.parent.loaded;

    const parentPos = this.parent.transform.position;
    const deg =
      this.parent.transform.rotation + this.raceManager.direction * 90;
    const rad = (Math.PI * deg) / 180;
    const distance = 15;

    const lineTo = new Vector2(
      parentPos.x - distance * Math.cos(rad),
      parentPos.y + distance * Math.sin(rad)
    );
    //console.log(this.raceManager);
    this.transform.position.set(lineTo.x, lineTo.y);
  }
}
