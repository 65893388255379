import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import './CarCard.css';

const CarCard = ({
  title,
  description,
  buttonText,
  buttonLink,
  buttonExternal,
  viewLink,
  image,
  theme,
  inverted,
}) => (
  <div className={`car-card padding-vertical-large ${theme} ${(inverted ? 'inverted' : '')}`}>
    <div className="center">
      <div className="description-block">
        <p className="title">{title}</p>
        <hr />
        <p className="description">
          {description}
        </p>
        {buttonExternal
          ? <a href={buttonLink} className="button primary margin-right-small" target="_blank" rel="noopener noreferrer">{buttonText}</a>
          : <Link to={buttonLink} className="button primary margin-right-small">{buttonText}</Link>
        }
        {viewLink && <Link to={viewLink} className="button secondary text-black">View</Link>}
      </div>
      <div className="image-container">
        <img src={image} alt="car" />
      </div>
    </div>
  </div>
);

CarCard.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  buttonText: PropTypes.string.isRequired,
  buttonLink: PropTypes.string.isRequired,
  buttonExternal: PropTypes.bool,
  viewLink: PropTypes.string,
  image: PropTypes.string.isRequired,
  inverted: PropTypes.bool,
  theme: PropTypes.string,
};

CarCard.defaultProps = {
  buttonExternal: false,
  inverted: false,
  theme: '',
  viewLink: '',
};

export default CarCard;
