export const MEMBERS = [
  {
    name: "Leandro Bellone",
    image: "/images/team/leandro.png",
    title: "CEO - Head 3D Modeler",
  },
  {
    name: "Nahuel Cirocco",
    image: "/images/team/nahuel.png",
    title: "CTO",
  },
  {
    name: "Rodrigo Maldonado",
    image: "/images/team/rodrigo.png",
    title: "CDO - Head Car Designer",
  },
  {
    name: "Santiago Carullo",
    image: "/images/team/santiago_carullo.png",
    title: "Blockchain Developer",
  },
  {
    name: "Leonel Cirocco",
    image: "/images/team/leonel.png",
    title: "Software Engineer",
  },
  {
    name: "Mauro Cristy",
    image: "/images/team/mauro.png",
    title: "Backend and Game Developer",
  },
  {
    name: "Santiago Chopitea",
    image: "/images/team/santiago_chopitea.png",
    title: "Frontend Developer",
  },
  {
    name: "Alejandro Hernandez",
    image: "/images/team/alejandro.png",
    title: "Game Developer",
  },
  {
    name: "Alan Wendorff",
    image: "/images/team/alan.png",
    title: "Frontend Developer",
  },
  {
    name: "Guillermo Vidal",
    image: "/images/team/guillermo.png",
    title: "Content Creator & Digital Marketing Analyst",
  },
];

export default MEMBERS;
