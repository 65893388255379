import React from 'react';
import PropTypes from 'prop-types';
import LinkWalletLayout from './LinkWalletLayout';

const WalletTaken = ({ address }) => (
  <LinkWalletLayout>
    <p>
      The address
      {' '}
      <span className="bold">{ address }</span>
      {' '}
      is already linked to an account. If you want to link another address choose
      it in your MetaMask.
    </p>
  </LinkWalletLayout>
);

WalletTaken.propTypes = {
  address: PropTypes.string.isRequired,
};

export default WalletTaken;
