import React from 'react';
import PropTypes from 'prop-types';

const StatBar = ({
  name, value, min, max,
}) => (
  <div className="stat">
    <div className="stat-name">{ name }</div>
    <div className="stat-bar">
      <div className="colored" style={{ width: `${max}%` }}>
        <div className="cover" style={{ width: `${min}%` }} />
      </div>
      <div className="stat-circle" style={{ left: `${value}%` }} />
    </div>
    <div className="stat-value">{ value / 10 }</div>
  </div>
);

StatBar.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.number.isRequired,
  min: PropTypes.number.isRequired,
  max: PropTypes.number.isRequired,
};

export default StatBar;
