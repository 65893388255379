import React, { Component } from "react";
import axios from "axios";
import { Link, Redirect } from "react-router-dom";
import AuthContext from "../../context/AuthContext";
import SelectTrack from "./components/SelectTrack";
import SelectCar from "./components/SelectCar";
import RaceStart from "./components/RaceStart";
import "./css/bootstrap.min.css";
import "./css/style.css";
import { GAME_API_URL, API_URL } from "../../config/Config";
import { AUCTIONS, GAME_SUMMARY, HOME } from "../../config/Routes";

class Game extends Component {
  static contextType = AuthContext;

  state = {
    statusNotify: false,
    stage: 0,
    circuits: [],
    vehicles: [],
    selectedCircuit: null,
    selectedVehicle: null,
    isLoading: true,
    hasNoVehicles: false,
    race: null,
    loggedUserScore: {},
    userCircuitScores: [],
    redirect: false,
    redirectData: {
      raceUuid: null,
      circuitUuid: null,
    },
  };

  async componentDidMount() {
    if (localStorage.getItem("raceExpiredUUID") !== null) {
      this.setState({
        statusNotify: true,
      });
    }

    this.context.checkToken();

    let userCircuitScores = [];
    let defaultCircuitScore = {
      username: this.context.username,
      score: 0,
      rankingPoints: 0,
    };

    const userData = (await axios({
      method: "GET",
      url: `${GAME_API_URL}/score`,
      headers: { Authorization: this.context.token },
    })).data;

    if (userData.scores.length > 0) {
      userCircuitScores = userData.scores;
      defaultCircuitScore = {
        username: this.context.username,
        score: userData.scores[0].score,
        rankingPoints: userData.scores[0].rankingPoints,
        ranked: userData.scores[0].ranked,
        circuitName: userData.scores[0].circuitName,
        racingLeftToRank: userData.scores[0].racingsLeftToRank,
      };
    }

    const circuitsData = (await axios({
      method: "GET",
      url: `${GAME_API_URL}/circuits`,
      headers: { Authorization: this.context.token },
    })).data;

    const vehiclesData = (await axios({
      method: "GET",
      url: `${API_URL}/users/vehicles`,
      headers: { Authorization: this.context.token },
    })).data.filter(
      (v) =>
        !v.borrower ||
        (v.wallet.user && v.wallet.user.username !== this.context.username)
    );

    if (vehiclesData.length === 0) {
      this.setState({
        hasNoVehicles: true,
        isLoading: false,
      });
    }

    this.setState({
      circuits: circuitsData,
      vehicles: vehiclesData,
      selectedCircuit: circuitsData[0],
      selectedVehicle: vehiclesData[0],
      isLoading: false,
      loggedUserScore: defaultCircuitScore,
      userCircuitScores: userCircuitScores,
    });

    const raceStatus = JSON.parse(localStorage.getItem("raceStatus"));

    if (raceStatus && this.state.statusNotify !== true) {
      this.setState({
        isLoading: true,
      });

      const race = (await axios({
        method: "GET",
        headers: { Authorization: this.context.token },
        url: `${GAME_API_URL}/races/${raceStatus.race.uuid}`,
      })).data;

      if (race.status === 5) {
        localStorage.removeItem("raceStatus");
        localStorage.setItem("simulationFinished", true);
      } else {
        if (raceStatus.status === "lobby") {
          this.setState({
            selectedCircuit: raceStatus.selectedCircuit,
            selectedVehicle: raceStatus.selectedVehicle,
            race: raceStatus.race,
            stage: 2,
            loggedUserScore: defaultCircuitScore,
            hasNoVehicles: false,
            isLoading: false,
          });
        } else if (raceStatus.status === "running") {
          this.setState({
            redirect: true,
            redirectData: {
              raceUuid: raceStatus.race.uuid,
              circuitUuid: raceStatus.selectedCircuit.uuid,
            },
          });
        }
      }

      this.setState({
        isLoading: false,
      });
    }
  }

  handleClickCircuit = (circuitName) => {
    const { circuits } = this.state;
    const selectedCircuit = circuits.find(
      (circuit) => circuit.name === circuitName
    );
    const loggedUserScore = this.state.userCircuitScores.find(
      (s) => s.circuitUuid === selectedCircuit.uuid
    );

    const newScore = {
      score: loggedUserScore.score,
      rankingPoints: loggedUserScore.rankingPoints,
      ranked: loggedUserScore.ranked,
      circuitName: loggedUserScore.circuitName,
      racingLeftToRank: loggedUserScore.racingsLeftToRank,
      username: this.context.username,
    };

    this.setState({ selectedCircuit, loggedUserScore: newScore });
  };

  handleClickVehicle = (ethId) => {
    const { vehicles } = this.state;
    const selectedVehicle = vehicles.find((vehicle) => vehicle.ethId === ethId);
    this.setState({ selectedVehicle });
  };

  handleNextStage = () => {
    this.setState((prevState) => ({ stage: prevState.stage + 1 }));
  };

  handleBackToStage = () => {
    this.setState((prevState) => ({ stage: prevState.stage - 1 }));
  };

  handleRaceNow = async () => {
    this.setState({
      isLoading: true,
    });

    const response = (await axios({
      method: "POST",
      url: `${GAME_API_URL}/race-subscriptions`,
      headers: { Authorization: this.context.token },
      data: {
        circuitUuid: this.state.selectedCircuit.uuid,
        vehicleUuid: this.state.selectedVehicle.uuid,
      },
    })).data;

    this.setState((prevState) => ({
      stage: prevState.stage + 1,
      isLoading: false,
      race: response.race,
    }));
  };

  render() {
    const {
      circuits,
      isLoading,
      selectedCircuit,
      stage,
      vehicles,
      selectedVehicle,
      hasNoVehicles,
      race,
      loggedUserScore,
      redirect,
      redirectData,
    } = this.state;

    const hideNotify = () => {
      this.setState({
        statusNotify: false,
      });
      //console.log("removing");
      localStorage.removeItem("lastRace");
      localStorage.removeItem("raceStatus");
      localStorage.removeItem("raceExpiredUUID");
    };
    if (this.state.statusNotify === true) {
      setTimeout(hideNotify, 9000);
      //console.log("timeout seted");
    }

    const { authenticated } = this.context;
    if (!authenticated) {
      return <Redirect to={HOME} />;
    }

    if (redirect) {
      return (
        <Redirect
          to={`${GAME_SUMMARY.replace(":uuid", redirectData.raceUuid).replace(
            ":track",
            redirectData.circuitUuid
          )}`}
        />
      );
    }

    if (isLoading) {
      return (
        <div className="game game-page">
          <div className="banner__area race-summary-page-area monthly-page">
            <div className="page-area">
              <div className="single__banner banner_bg single__banner2  bann-800">
                <div className="container wi-1000 pt-100">
                  <i className="fas fa-spinner fa-spin standalone-loading-white" />
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    }

    if (hasNoVehicles) {
      return (
        <div className="game game-page">
          <div className="banner__area race-summary-page-area monthly-page">
            <div className="page-area">
              <div className="single__banner banner_bg single__banner2  bann-800">
                <div className="container wi-1000 pt-100">
                  <div className="center padding-vertical-small pt-middle">
                    <div className="all-heading">
                      <h2>Looks like your garage is empty.</h2>
                      <Link to={AUCTIONS} className="boxed_btn">
                        Buy a car to play
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    }

    return (
      <div className="game">
        {stage === 0 && (
          <SelectTrack
            loggedUserScore={loggedUserScore}
            circuits={circuits}
            selectedCircuit={selectedCircuit}
            onClickCircuit={this.handleClickCircuit}
            onClickNextStage={this.handleNextStage}
            statusNotify={this.state.statusNotify}
            hideNotify={hideNotify}
          />
        )}
        {stage === 1 && (
          <SelectCar
            loggedUserScore={loggedUserScore}
            vehicles={vehicles}
            selectedCircuit={selectedCircuit}
            selectedVehicle={selectedVehicle}
            onClickNextStage={this.handleRaceNow}
            onClickVehicle={this.handleClickVehicle}
            onClickBackStage={this.handleBackToStage}
          />
        )}
        {stage === 2 && (
          <RaceStart
            loggedUserScore={loggedUserScore}
            selectedCircuit={selectedCircuit}
            selectedVehicle={selectedVehicle}
            race={race}
          />
        )}
      </div>
    );
  }
}

export default Game;
