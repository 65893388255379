import axios from 'axios';
import { API_URL } from '../config/Config';

export const getAuthenticatedUser = token => axios({
  method: 'GET',
  headers: { Authorization: token },
  url: `${API_URL}/authentication/user`,
});

export const postRefreshToken = (token, refreshToken) => axios({
  method: 'POST',
  url: `${API_URL}/authentication/refresh-token`,
  data: { token, refreshToken },
});

export const updateUser = (token, marketingUpdates, isEuResident, username, email) => axios({
  method: 'PATCH',
  headers: { Authorization: token },
  url: `${API_URL}/authentication/user`,
  data: {
    marketingUpdates: marketingUpdates !== null ? marketingUpdates : undefined,
    isEuResident: isEuResident !== null ? isEuResident : undefined,
    username: username || undefined,
    email: email || undefined,
  },
});
