import React from 'react';
import PropTypes from 'prop-types';
import { Carousel } from 'react-responsive-carousel';
import '../../../node_modules/react-responsive-carousel/lib/styles/carousel.css';
import './ImageGallery.css';

const ImageGallery = ({ images }) => (
  <Carousel
    useKeyboardArrows
    emulateTouch
    showStatus={false}
    showArrows
    showThumbs={false}
  >
    {images.map(image => (
      <div key={image}>
        <img src={image} alt={image} />
      </div>
    ))}
  </Carousel>
);

ImageGallery.propTypes = {
  images: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default ImageGallery;
