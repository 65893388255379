import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  ABOUT,
  DESIGN_CHALLENGE_DASHBOARD,
  GARAGE,
  HOME,
  MARKET,
  SETTINGS,
  SIGN_UP,
  VISION,
  GAME,
  GAME_SCORE,
} from '../../config/Routes';
import AuthContext from '../../context/AuthContext';
import './MenuMobile.css';

class MenuMobile extends Component {
  static contextType = AuthContext;

  static propTypes = {
    pathname: PropTypes.string.isRequired,
  };

  state = {
    isOpen: false,
  };

  toggleMenu = () => this.setState(prevState => ({
    isOpen: !prevState.isOpen,
  }));

  closeMenu = () => this.setState({ isOpen: false });

  render() {
    const { pathname } = this.props;
    const { authenticated, username } = this.context;
    const { isOpen } = this.state;

    return (
      <nav className="center mobile-menu">
        <div className="header">
          <div className="logo">
            <Link to={HOME}><img src="/images/logo-white.svg" alt="isologo" /></Link>
          </div>
          <div className="bars">
            <img src="/images/burger.svg" onClick={this.toggleMenu} alt="menu" />
          </div>
        </div>
        <div
          className={`menu-dropdown ${isOpen ? 'open' : 'closed'}`}
        >
          <ul>
            <li>
              <Link
                to={HOME}
                className={pathname === HOME ? 'active' : ''}
                onClick={this.closeMenu}
              >
                Home
              </Link>
            </li>
            <li>
              <Link
                to={MARKET}
                className={pathname === MARKET ? 'active' : ''}
                onClick={this.closeMenu}
              >
                Market
              </Link>
            </li>
            {authenticated && (
            <li>
              <Link
                to={GAME}
                className={pathname === GAME ? 'active' : ''}
                onClick={this.closeMenu}
              >
                Stat Game
              </Link>
            </li>
            )}
            <li>
              <Link
                to={GAME_SCORE}
                className={pathname === GAME_SCORE ? 'active' : ''}
                onClick={this.closeMenu}
              >
                Leaderboard
              </Link>
            </li>
            <li>
              <Link
                to={ABOUT}
                className={pathname === ABOUT ? 'active' : ''}
                onClick={this.closeMenu}
              >
                About
              </Link>
            </li>
            <li>
              <Link
                to={DESIGN_CHALLENGE_DASHBOARD}
                className={pathname.includes('/design-challenge') ? 'active' : ''}
                onClick={this.closeMenu}
              >
                Challenge
              </Link>
            </li>
            <li>
              <Link
                to={VISION}
                className={pathname === VISION ? 'active' : ''}
                onClick={this.closeMenu}
              >
                Vision
              </Link>
            </li>
            {authenticated ? (
              <Fragment>
                <li>
                  <Link
                    to={GARAGE.replace(':user', username)}
                    className={pathname.includes('/garage/') ? 'active' : ''}
                    onClick={this.closeMenu}
                  >
                    Garage
                  </Link>
                </li>
                <li>
                  <Link
                    to={SETTINGS}
                    className={pathname === SETTINGS ? 'active' : ''}
                    onClick={this.closeMenu}
                  >
                    Settings
                  </Link>
                </li>
              </Fragment>
            )
              : (
                <li>
                  <Link
                    to={SIGN_UP}
                    className={pathname === SIGN_UP ? 'active' : ''}
                    onClick={this.closeMenu}
                  >
                    Sign up
                  </Link>
                </li>
              )}
          </ul>
        </div>
      </nav>
    );
  }
}

export default MenuMobile;
