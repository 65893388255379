import React from 'react';
import { Link } from 'react-router-dom';
import './CarSection.css';
import { MARKET_CAR, MODEL_SEDAN } from '../../config/Routes';

const CarSection = () => (
  <div className="car-section">
    <img src="/images/home/sedan-hero-928.png" alt="car" />
    <div className="description-block">
      <p className="title">Rethinking automotive design</p>
      <hr />
      <p className="description">
        We are a professional car design studio based in Germany dedicated to creating
        decentralized digital vehicles powered by the Ethereum blockchain.
        Every car we make is unique and 100% owned by you. It cannot be destroyed, replicated or
        taken away.
      </p>
      <Link to={MARKET_CAR.replace(':model', MODEL_SEDAN)} className="button primary">Buy now</Link>
    </div>
  </div>
);

export default CarSection;
