import React from 'react';
import LinkWalletLayout from './LinkWalletLayout';

const NoMainNetwork = () => (
  <LinkWalletLayout>
    <p>
      It looks like you are in a test network. Please, change your wallet to the main network
      in order to proceed.
    </p>
  </LinkWalletLayout>
);

export default NoMainNetwork;
