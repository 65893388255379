import GameObject from "../../Engine/GameObject";
import TextRenderer from "../../Engine/Components/TextRenderer";

const LAP_TEXT = "Current Lap: {lap}";
const STATS_TEXT = "Last Lap: {lap} - Position: {position}";

export default class PlayerStats extends GameObject {
  textRenderer = null;
  positions = new Map();

  constructor() {
    super();

    this.transform.position.x = -430;
    this.transform.position.y = 230;

    this.addComponent(
      () =>
        new TextRenderer({
          size: 12,
          color: "#E3DFDF",
          font: "Courier New",
          lineSeparation: 5,
          bold: true,
        }),
      "TextRenderer"
    );
    this.textRenderer = this.getComponent("TextRenderer");
  }

  start() {
    this.setActive(false);
  }

  update() {
    let text = [];
    this.positions.forEach((p) => {
      text.push(`${p.position}: ${p.username} ${p.isPlayer ? "(player)" : ""}`);
    });

    this.textRenderer.text = text;
  }
}
