import React from "react";
import MarketMenu from "../MarketMenu/MarketMenu";
import "./Market.css";
import CarCard from "../CarCard/CarCard";
import MODELS from "../../config/Models";
import {
  MARKET_CAR,
  MODEL_SUV,
  MODEL_SPORT_COUPE,
  MODEL_SEDAN,
  MODEL_ABYSSUS,
  EVENT_DROP,
  MODEL_NEON_ROADSTER,
} from "../../config/Routes";

const BUY_NOW = "buy now";
// neon roadstr ex view link: /vehicle/16785f70-d471-47c7-b516-3bfff7c31fc8

const Market = () => (
  <div className="market">
    <MarketMenu />
    <CarCard
      title={MODELS.retrorocket.name}
      description="Handcrafted in a nearby Multiverse by a skilled team of gearheads with a passion for the past and a technical eye towards the future, the Retro Rocket Razer is a perfect blend of classic design and high-end technology."
      image={MODELS.retrorocket.images.front}
      theme="gray"
      buttonLink={EVENT_DROP}
      buttonText={"join the drop"}
      inverted
    />
    <CarCard
      title={MODELS.neonroadster.name}
      description="The GEN 1 Neon Roadster is the second CryptoMotors car created out from our first Design Challenge. The compact, yet aerodynamic body gives this vehicle the perfect balance between acceleration, top speed, and handling."
      image={MODELS.neonroadster.images.front}
      theme="white"
      buttonLink={EVENT_DROP}
      buttonText={BUY_NOW}
      viewLink={MARKET_CAR.replace(":model", MODEL_NEON_ROADSTER)}
      buttonExternal
    />
    <CarCard
      title={MODELS.abyssus.name}
      description="The aerodynamic shape of the Abyssus allows it to reach the highest speeds in straight lines, and its versatile design gives the car perfect handling to smoothly take hard curves and sharp turns."
      image={MODELS.abyssus.images.front}
      theme="gray"
      buttonLink={MODELS.abyssus.openSea}
      buttonText={BUY_NOW}
      viewLink={MARKET_CAR.replace(":model", MODEL_ABYSSUS)}
      inverted
      buttonExternal
    />
    <CarCard
      title={MODELS.sedan.name}
      description="The Sedan has a solid and aerodynamic body that helps it maximize its performance on the road. It's great grip and handling make it an ideal car for races with sharp curves and hard turns."
      image={MODELS.sedan.images.front}
      theme="white"
      buttonLink={MODELS.sedan.openSea}
      buttonText={BUY_NOW}
      buttonExternal
      viewLink={MARKET_CAR.replace(":model", MODEL_SEDAN)}
    />
    <CarCard
      title={MODELS.sportcoupe.name}
      description="Its great acceleration and max speed make it an ideal vehicle for drag racing. Short ground clearance and an aerodynamic design give the Sport Coupé a significant edge on high-speed races. Its performance improves in dry tracks and arid climates."
      image={MODELS.sportcoupe.images.front}
      theme="gray"
      inverted
      buttonLink={MODELS.sportcoupe.openSea}
      buttonText={BUY_NOW}
      buttonExternal
      viewLink={MARKET_CAR.replace(":model", MODEL_SPORT_COUPE)}
    />
    <CarCard
      title={MODELS.suv.name}
      description="Although the SUV does not characterize for reaching the highest speeds, Its balanced acceleration and handling make it a great choice for off-road races on extreme weather conditions."
      image={MODELS.suv.images.front}
      theme="white"
      buttonLink={MODELS.suv.openSea}
      buttonText={BUY_NOW}
      buttonExternal
      viewLink={MARKET_CAR.replace(":model", MODEL_SUV)}
    />
  </div>
);

export default Market;
