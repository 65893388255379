import React, {useEffect, useContext, forwardRef, useImperativeHandle} from 'react';
import { PlayAudioContext } from '../../../../context/PlayAudioContext';

const MusicHelper = forwardRef((props, ref) => {
    const {playpause, playStopMusic} = useContext(PlayAudioContext);    
    
    useEffect(() => {  
      
    }, [playpause]);
    
    useImperativeHandle(ref, () => ({

        handlePlayStopMusic() { 
            playStopMusic();            
        }
    
    }));

    return (
        <button className="button-music-helper" ref={ref}></button>
    )
})

export default MusicHelper;