import React from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser, faHeart } from '@fortawesome/free-regular-svg-icons';
import { faHeart as fasHeart } from '@fortawesome/free-solid-svg-icons';
import { faInstagram } from '@fortawesome/free-brands-svg-icons';
import LazyLoad from 'react-lazyload';
import { DESIGN_IMAGE_URL } from '../../config/Config';

const Container = styled.div`
  background: white;
  display: flex;
  flex-direction: column;
  
  > * {
    flex: 1;

    > img {
      &:nth-child(2n) {
        border-top-left-radius: 12px;
        border-top-right-radius: 12px;
      }
    }
  }
`;

const Image = styled.img`
  width: 100%;
`;

const Info = styled.div`
  display: flex;
  padding: 1.25rem;
  background-color: #FFFFFF;
  flex-direction: column;
  align-items: flex-start;
  border-bottom-left-radius: 0.75rem;
  border-bottom-right-radius: 0.75rem;
`;

const DesignerName = styled.div`
  font-size: 1.12rem;
  color: black;
`;

const SocialNetworkLink = styled.a`
  margin-top: 10px;
  color: black;
  font-size: 0.62rem;
  cursor: pointer;
  text-transform: capitalize;
  
  > .fa-user, .fa-instagram {
    font-size: .8rem;
    color: #A0A0A0;
  }
`;

const Votes = styled.div`
  margin-top: 0.62rem;
  border-top: 1px solid #E3E3E3;
  width: 100%;
  color: black;
  text-align: left;
  padding-top: 0.62rem;
  
  > .fa-heart {
    cursor: pointer;
  }
`;

const Icon = styled(FontAwesomeIcon)`
  margin-right: 5px;
`;

const IMAGES_BASE_PATH = '/challenge/';

const getInstagramUserName = (url) => {
  const match = url.match('/(?:(?:http|https):\\/\\/)?(?:www.)?(?:instagram.com|instagr.am)\\/([A-Za-z0-9-_\\.]+)/');

  if (match && match[1]) {
    return `@${match[1]}`;
  }

  return '';
};

const DesignCard = ({
  design, onClickImageHandler, handleVote, index,
}) => (
  <Container>
    <div className="design-challenge-card-images">
      {index === 0
        && <img alt="1st position" src="/images/design-challenge-2/1st.png" className="ribbon" />
      }
      {index === 1
      && <img alt="2nd position" src="/images/design-challenge-2/2nd.png" className="ribbon" />
      }
      {index === 2
      && <img alt="3rd position" src="/images/design-challenge-2/3rd.png" className="ribbon" />
      }
      {index === 3
      && <img alt="special mention" src="/images/design-challenge-2/special.png" className="ribbon" />
      }
      {index === 4
      && <img alt="special mention" src="/images/design-challenge-2/special.png" className="ribbon" />
      }
      {design.images.map(image => (
        <LazyLoad height={300} key={image}>
          <Image
            onClick={() => onClickImageHandler(design.designId)}
            src={`${DESIGN_IMAGE_URL}${IMAGES_BASE_PATH}${design.userId}/${design.designId}/${image}`}
          />
        </LazyLoad>
      ))}
    </div>
    <Info>
      <DesignerName>{design.designer}</DesignerName>
      {design.instagram.url !== '' && getInstagramUserName(design.instagram.url) !== '' && (
        <SocialNetworkLink
          href={design.instagram.url}
          target="_blank"
          rel="noopener noreferrer"
        >
          <Icon icon={faInstagram} />
          Instagram
        </SocialNetworkLink>
      )}
      {design.portfolio !== '' && (
        <SocialNetworkLink
          href={design.portfolio}
          target="_blank"
          rel="noopener noreferrer"
        >
          <Icon icon={faUser} />
          Portfolio
        </SocialNetworkLink>
      )}
      <Votes>
        <Icon
          icon={[0, 1, 2, 3, 4].includes(index) ? fasHeart : faHeart}
          onClick={() => handleVote(design.designId)}
          style={{ color: [0, 1, 2, 3, 4].includes(index) ? '#b18f4f' : '#D0D0D0' }}
        />
        {design.votes}
        {' '}
        votes
      </Votes>
    </Info>

  </Container>
);

export default DesignCard;
