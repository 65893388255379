import React, { Component } from 'react';
import { Redirect } from 'react-router';
import AuthContext from '../../context/AuthContext';
import { loginFacebook } from '../../requests/Authentication';
import {
  DESIGN_CHALLENGE_DASHBOARD,
  HOME,
  ORIGIN_DESIGN_CHALLENGE,
} from '../../config/Routes';

const LOGIN_ERROR_MESSAGE = 'Something went wrong. Please try again later';

class FacebookAuth extends Component {
  static contextType = AuthContext;

  state = {
    isLoading: true,
    success: false,
    errorMessage: '',
  };

  componentDidMount() {
    const urlParams = new URLSearchParams(window.location.search);
    const code = urlParams.get('code');

    loginFacebook(code)
      .then(({
        data:
           {
             expiresAt,
             token,
             refreshToken,
             user: { username },
           },
      }) => {
        this.setState({
          isLoading: false,
        });

        this.context.login(token, refreshToken, expiresAt, username);
      })
      .catch(() => {
        this.setState({
          errorMessage: LOGIN_ERROR_MESSAGE,
          isLoading: false,
        });
      });
  }

  render() {
    const { authenticated } = this.context;
    const { isLoading, success, errorMessage } = this.state;

    const origin = localStorage.getItem('origin');
    if (authenticated && origin === ORIGIN_DESIGN_CHALLENGE) {
      return <Redirect to={DESIGN_CHALLENGE_DASHBOARD} />;
    }

    if (authenticated) {
      return <Redirect to={HOME} />;
    }

    if (isLoading) {
      return <i className="fas fa-spinner fa-spin standalone-loading" />;
    }

    if (success) {
      return <Redirect to={HOME} />;
    }

    return <div>{errorMessage}</div>;
  }
}

export default FacebookAuth;
