import React from 'react';
import LinkWalletLayout from './LinkWalletLayout';

const NoAddress = () => (
  <LinkWalletLayout>
    <p>
      Please login into your wallet in order to proceed.
    </p>
  </LinkWalletLayout>
);

export default NoAddress;
