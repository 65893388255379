import React from 'react';
import './TermsOfUse.css';
import { Link } from 'react-router-dom';
import { PRIVACY_POLICY } from '../../config/Routes';

const TermsOfUse = () => (
  <div className="center terms-of-use">
    <h1>General Terms of Use</h1>
    <p>
      Please read through the following Terms of Use carefully, which apply to every visitor and
      every user of our app/and website. Through the use of the website, you give your implied
      consent to these Terms of Use.
    </p>
    <h3>I. Scope of applicability; subject matter</h3>
    <ol>
      <li>
        The following General Terms of Use apply to all business relations with our users.
      </li>
      <li>
        Our Terms of Use apply exclusively. Any deviating, inconsistent or supplementary
        general terms of use of the user shall become an integral part of the contract
        only if and to the extent that we have expressly approved the validity thereof in writing.
      </li>
      <li>
        CryptoMotors is an application, a digital automobile manufacturer that is set up on the
        Ethereum blockchain and uses specially developed “smart contracts”. The application enables
        users to acquire, possess, modify, transfer, collect, trade and use in videogames unique
        digital vehicles (hereinafter “Crypto-Collectibles” or “Vehicles”, technically a token in
        the blockchain environment). Cryptomotors UG makes an app and a website available for this
        purpose. The user community should also be granted access to the platform so that it can
        take part in the creation of Crypto-Collectibles.
        <br />
        <br />
        We use the network to start the website and to make gen0 4375 Crypto-Collectibles available
        to the app once. We will publish further Crypto-Collectibles and offer them for sale at
        irregular intervals.
      </li>
      <li>
        Every Vehicle provided on the platform is a unique and special vehicle that can be purchased
        thanks to the non-fungible ERC-721 token. The Vehicle cannot be destroyed or taken away from
        any other user.
        <br />
        <br />
        CryptoMotors are digital 3D Vehicles that are developed by design professionals with the
        aid of the community and set up on the basis of the Ethereum blockchain. These Vehicles
        can be purchased and sold with Ether and be personalised to create new customised versions.
        The Vehicles of a project are offered only as a limited unit with a unique identification
        number and statistics. Based on the principle of digital rarity, some of the items are
        rarer than others, like in the real world.
      </li>
      <li>
        Entertainment: CryptoMotors is developing a role-playing game which users can appear in with
        their Crypto-Collectibles. Our team also promotes integration and collaboration with various
        videogames and platforms and by doing so, broadens horizons for users so they can decide how
        they wish to enjoy their assets.
      </li>
      <li>
        CryptoMotors undertakes to pay all the purchasers of the Crypto-Collectibles of a vehicle
        series, in the event of the real production and sale of the respective vehicle, a pro-rata
        profit participation totalling 10% for all the acquirers of the overall profit.
      </li>
    </ol>
    <h3>II. Your account</h3>
    <ol>
      <li>
        A user account must be created to access several (chargeable) services of the website and
        the app.
      </li>
      <li>
        Only fully legally competent persons are permitted to use our website and/or app.
      </li>
      <li>
        The user undertakes not to provide any false particulars on his identity. Furthermore, the
        user also undertakes to check his particulars on a regular basis in order to ensure they
        are correct.
      </li>
      <li>
        If you use our services, you are responsible for ensuring the confidentiality of your
        account, your password and for the restriction of access to your computer and your mobile
        equipment as well as for the security of your electronic wallet (“Wallet”). You undertake
        to take all the measures required to ensure that your password remains secret and is safely
        stored.
      </li>
      <li>
        You undertake to inform us without delay if you find out that a third party has gained
        knowledge of your password or the password is being used without authorisation or this
        is probable.
      </li>
      <li>
        You may change your user name and your password subsequently at any time.
      </li>
      <li>
        You may not use our services in a manner suitable for interrupting or damaging the services
        or for impairing them in any other way. Furthermore, you may not use the services for
        activities that are fraudulent or connected with a criminal offence or illegal activities.
      </li>
      <li>
        We reserve the right to withhold the services from you on the website or to close members’
        accounts at our discretion. This applies particularly in the event that you violate
        applicable law, contractual agreements or our Terms of Use. This shall have no effect on
        your rights to the Crypto-Collectibles.
      </li>
      <li>
        Users of the website and/or the app may delete their account at any time. After deletion of
        the account, you will no longer be able to access the service. This shall have no influence
        on your rights to the Crypto-Collectibles.
      </li>
    </ol>
    <h3>III. Fees and payment</h3>
    <ol>
      <li>
        To conduct transactions on the app and/or on the website, you must first install a browser
        extension named MetaMask (or another cryptocurrency-compatible browser extension). This
        requires that you have installed a web browser compatible for this, such as the Google
        Chrome web browser. MetaMask is an electronic wallet (“Wallet”) that allows you to purchase
        cryptocurrency directly via the Coinbase platform, save it and conduct transactions in
        cryptocurrency. You can conduct transactions on our website and/or app only via MetaMask (or
        other cryptocurrency-compatible browser extensions). To do so, you must connect your Wallet
        to our website and app.
      </li>
      <li>
        In order to purchase a Crypto-Collectible or additional functions, extensions or
        modifications, the prices shown for the respective products must be paid to CryptoMotors
        UG.
      </li>
      <li>
        If you conduct transactions on the website and/or app, these transactions will be conducted
        solely via the cryptocurrency network.
      </li>
      <li>
        Cryptocurrencies demand the payment of a transaction fee for transactions that take place
        in the network. You must therefore pay such a transaction fee for each transaction.
      </li>
      <li>
        On the app or website, we offer you a platform where you can trade your Crypto-Collectibles
        (“Resale”). In the event of a Resale via our platform, we charge commission of 5%. You
        hereby agree to this commission being forwarded to us directly through the cryptocurrency
        network as part of your payment. We will not collect any commission for transactions that
        were not conducted via our website and/or app.
      </li>
      <li>
        All prices already include statutory value-added tax, unless expressly otherwise stated.
      </li>
    </ol>
    <h3>IV. Rights; copyright</h3>
    <ol>
      <li>
        The contents, works and the information published and provided on our website and app are
        subject to German copyright and ancillary copyright.
      </li>
      <li>
        Cryptomotors UG is the sole holder of all the rights and property rights to the services and
        contents provided on our website and app by Cryptomotors UG.
      </li>
      <li>
        You can acquire rights to Crypto-Collectibles on our website and app. Crypto-Collectibles
        are intangible digital assets that exist only on the basis of the proof kept in the
        cryptocurrency network and are not saved on a physical disk. Our website and/or app do not
        save, send or receive any Crypto-Collectibles themselves. This is due to the fact that
        Crypto-Collectibles exist only on the basis of the proof in the blockchain in the
        cryptocurrency network, specifically in the Ethereum blockchain.
      </li>
      <li>
        If you purchase a Crypto-Collectible, we shall grant you limited, not exclusive,
        transferable rights to use the Crypto-Collectible for the following purposes:
        <ol className="ol-alpha">
          <li>
            for personal, non-commercial use;
          </li>
          <li>
            for the transaction of the rights on marketplaces, provided the marketplace
            cryptographically checks the rights of each Crypto-Collectible purchaser to ensure that
            only the actual rights holder can display and transfer the Crypto-Collectibles. In
            particular, the Crypto-Collectibles may be transferred on the marketplace provided by
            us.
          </li>
          <li>
            For inclusion, involvement or participation in racing games on our website and/or app
            or on a third-provider website or application provided that the website/application
            cryptographically checks the rights of each Crypto-Collectible purchaser to ensure
            that only the actual rights holder can display and use the Crypto-Collectibles.
          </li>
          <li>
            For the modification of the Crypto-Collectibles, provided the modifications can be
            cryptographically checked. In particular, Crypto-Collectibles can be modified through
            the services provided by us.
          </li>
        </ol>
      </li>
      <li>
        It will only be possible to visualise the Crypto-Collectibles on CryptoMotors platform or
        that of our partner.
      </li>
      <li>
        The rights granted apply only to the extent to which you continue to be the holder of the
        rights to the respective Crypto-Collectibles. Once you transfer these, your rights lapse.
      </li>
      <li>
        Any kind of reproduction, processing, dissemination, saving and any kind of use beyond the
        limits of the rights requires our prior written approval. In particular, the
        Crypto-Collectibles may not be:
        <ol className="ol-alpha">
          <li>
            used to advertise the products or services of third parties;
          </li>
          <li>
            used in connection with images, videos or other media that show hatred, intolerance
            and/or violence or infringe third-party rights;
          </li>
          <li>
            used for commercial purposes.
          </li>
        </ol>
      </li>
    </ol>
    <h3>V. Design competitions</h3>
    <ol>
      <li>
        The users of our website and/or app can take part in “design competitions” organised by us
        at irregular intervals. In addition to these Terms of Use, the respective conditions of
        participation apply to the competitions.
      </li>
    </ol>
    <h3>VI. Liability</h3>
    <ol>
      <li>
        Cryptomotors shall endeavour to offer the service for access without interruptions as far
        as possible. Even when applying the greatest care, it is not possible to prevent downtimes
        when the web server cannot be accessed via the Internet on account of technical or other
        problems which are beyond the control of Cryptomotors. The user acknowledges that the full
        availability (100%) of the website cannot be technically realised.
      </li>
      <li>
        Unless otherwise stated in our Terms of Use including the following provisions, we shall
        be liable in the event of a breach of contractual and extra-contractual obligations in
        accordance with legal provisions
      </li>
      <li>
        We shall be liable for damages – regardless of the legal ground – within the limits of
        fault-based liability in case of intent and gross negligence. In case of simple negligence,
        we shall be liable in accordance with legal provisions subject to a milder standard of
        liability (e.g. for care applied to one’s own matters) only
        <ol className="ol-alpha">
          <li>
            for damage arising from loss of life, physical injury or an impairment of health;
          </li>
          <li>
            for damage arising from a not insignificant breach of an essential contractual
            obligation (obligation, the fulfilment of which renders the proper implementation
            of the contract possible in the first place and on compliance with which the
            contractual party regularly relies and may rely); in this case our liability is,
            however, restricted to compensation for foreseeable damage that typically arises.
          </li>
          <li>
            The limitations of liability defined under 2. shall also apply to breaches of duty by
            or in favour of persons for whose fault we must assume responsibility in accordance
            with legal provisions or have assumed a guarantee for the condition of the
            Crypto-Collectibles and for claims of the purchaser based on the Product Liability Act.
          </li>
        </ol>
      </li>
      <li>
        As a user you are responsible for the implementation of reasonable measures to safeguard
        the Wallet, safe or another storage mechanism that you use for the purchase, exchange or
        use of platform-internal elements, tokens and/or other objects on the CryptoMotors
        platform, currently consisting of an app and a website, including all the required
        private passwords or other access data that are required for access to these storage
        mechanisms. As a platform organiser, CryptoMotors is not responsible and shall remain not
        responsible for losses entailed in the loss of your access data or in third-party access to
        your access data.
      </li>
      <li>
        Through the purchase of platform-internal articles and/or the receipt of tokens and the use
        of the CryptoMotors platform, you declare and guarantee the following:
        <ol>
          <li>
            You have a sufficient understanding of and experience with cryptographic tokens
            (particularly  Ethereum), token storage mechanisms (e.g. token Wallets) and
            blockchain technology in order to understand these terms and understand the risks and
            effects of the acquisition, the procurement and the exchange of platform-internal
            elements on Ethereum.
          </li>
          <li>
            ou have read and understood these terms (including all the annexes, if available).
          </li>
          <li>
            You have received sufficient information on the CryptoMotors platform to make an
            informed decision on the purchase, holding or exchange of platform-internal articles
            and/or tokens.
          </li>
          <li>
            You will not purchase, hold or exchange platform-internal articles and/or tokens for
            purposes other than to take part in the CryptoMotors platform or that of our partners
            and the platform functions declared in these Terms of Use on the website and/or on the
            app.
          </li>
          <li>
            Your purchase, your procurement and your exchange of articles and tokens tied to the
            platform comply with applicable laws and regulations in your place of jurisdiction,
            including but not limited to (i) the legal capacity and all other threshold
            requirements in your jurisdiction for the use and payment of Ether and the conclusion
            of contracts with the platform organiser, (ii) all the foreign currency or regulatory
            restrictions for this purchase and (iii) all the government or other approvals that
            must be obtained.
          </li>
          <li>
            You will comply with all the tax obligations applicable in your jurisdiction which
            arise from the purchase, the procurement or the exchange of cross-platform articles,
            if necessary.
          </li>
          <li>
            If you purchase, hold or exchange platform-independent articles or tokens in the name
            of a natural or legal person, you are entitled to accept these terms in the name of
            this natural or legal person and this natural or legal person shall be responsible for
            any infringement of these terms by you or this natural or legal person or another
            employee or representative of this person.
          </li>
          <li>
            You agree that when using the platform there is a risk that digital assets in the
            form of cryptocurrency can be lost, particularly through the loss of private keys,
            and that the platform organiser is not liable to you for any such loss.
          </li>
        </ol>
      </li>
    </ol>
    <h3>VII. Third-party providers; third-party applications; fluctuations in value</h3>
    <ol>
      <li>
        The website and the app contain what are known as “external links” to other websites, the
        contents of which are beyond our control. For this reason we assume no liability for these
        contents. The respective provider of the linked website is responsible for the contents and
        the accuracy of the information provided. At the time of placing the link, no legal
        infringements were detected. If such a legal infringement becomes known, the link shall be
        immediately removed.
      </li>
      <li>
        Furthermore, we have no influence on the applications, products or services of third
        parties or other websites such as MetaMask, Coinbase, Google Chrome, cryptocurrency
        networks which you access to enable use of the various functions of the website and/or app.
        The respective provider itself is responsible for the applications. For this reason we
        assume no liability for these either.
      </li>
      <li>
        The values of blockchain assets are subject to extreme fluctuations. We cannot guarantee
        that purchasers of Crypto-Collectibles will not lose money.
      </li>
    </ol>
    <h3>VIII. Indemnification</h3>
    <p>
      You undertake to indemnify Cryptomotors UG from all third-party claims and from all
      liabilities, payment obligations, costs or damage that arise on account of or in connection
      with
    </p>
    <ol>
      <li>
        your violations of the Terms of Use,
      </li>
      <li>
        your infringements of industrial property rights or other rights of data protection
        provisions of third parties or
      </li>
      <li>
        any misuse of the service by third parties if the misuse was rendered possible by your
        failing to take reasonable measures to protect your user name and password against misuse
        by third parties.
      </li>
    </ol>
    <h3>IX. Data protection</h3>
    <p>
      All personal data are collected, saved, processed and used in accordance with applicable data
      protection laws and our Privacy Statement. To read our Privacy Statement, please
      click
      {' '}
      <Link className="secondary" to={PRIVACY_POLICY}>here</Link>
      .
    </p>
    <h3>X. Amendments to the Terms of Use</h3>
    <p>
      We reserve the right to make changes to our services such as the website and the app and
      amend sets of rules, conditions, including these Terms of Use at any time. They are subject
      to the respective conditions in force at the time when you use our services.
    </p>
    <h3>XI. Miscellaneous</h3>
    <ol>
      <li>
        This Agreement and all the legal relationships of the parties are governed by the law of
        the Federal Republic of Germany to the exclusion of the UN Sales Convention (CISG).
      </li>
      <li>
        Should individual provisions of these Terms of Use be invalid, this shall not affect the
        validity of the remaining provisions. Invalid provisions shall be replaced by those that
        most closely approximate the meaning intended by the invalid provision. The same applies in
        the event of the occurrence of any gaps which need to be filled.
      </li>
      <li>
        If a user is a merchant, the place of jurisdiction for all disputes arising under
        contractual relationships between the user and the company is the registered office of the
        company.
      </li>
      <li>
        Cryptomotors UG will not take part in dispute settlement procedures before a consumer
        arbitration board to settle disputes with consumers.
      </li>
    </ol>

    <h2>Allgemeine Nutzungsbedingungen</h2>
    <p>
      Bitte lesen Sie die folgenden, für jeden Besucher und Nutzer unserer App und/oder Website
      geltenden Nutzungsbedingungen sorgfältig durch. Durch die Nutzung der Website erklären Sie
      konkludent ihr Einverständnis mit diesen Nutzungsbedingungen.
    </p>
    <h3>I. Geltungsbereich; Gegenstand</h3>
    <ol>
      <li>
        Die vorliegenden Allgemeinen Nutzungsbedingungen gelten für alle Geschäftsbeziehungen mit
        unseren Nutzern.
      </li>
      <li>
        Unsere Nutzungsbedingungen gelten ausschließlich. Abweichende, entgegenstehende oder
        ergänzende Allgemeine Geschäftsbedingungen des Nutzers werden nur dann und insoweit
        Vertragsbestandteil, als wir ihrer Geltung ausdrücklich schriftlich zugestimmt haben
      </li>
      <li>
        CryptoMotors ist eine Anwendung, ein digitaler Automobilhersteller, der auf der
        Ethereum-Blockchain aufsetzt und speziell entwickelte „Smart Contracts“ verwendet. Durch
        die Anwendung wird es den Nutzern ermöglicht einzigartige digitale Fahrzeuge
        (im Folgenden „Crypto-Collectibles“ oder „Fahrzeuge“ genannt, technisch ein
        Token in der Blockchain Umgebung) zu erwerben, zu besitzen, zu verändern, zu übertragen, zu
        sammeln, zu handeln und in Videospielen einzusetzen. Cryptomotors UG stellt Ihnen hierzu
        eine App und eine Website zur Verfügung. Ebenso soll der Nutzergemeinschaft der Zugang zur
        Plattform gewährt werden, um an der Erstellung von Crypto-Collectibles teilzunehmen.
        <br />
        Wir nutzen das Netzwerk, um zum Start der Website und der App einmalig gen0 4375
        Crypto-Collectibles zur Verfügung zu stellen. In unregelmäßigen Abständen werden wir
        weitere Crypto- Collectibles veröffentlichen und zum Erwerb anbieten.
      </li>
      <li>
        Jedes Fahrzeug, das auf der Plattform zur Verfügung gestellt wird, ist ein einzigartiges
        und spezielles Fahrzeug, das dank des nicht fungiblen Tokens ERC-721 erworben werden kann.
        Das Fahrzeug kann von einem anderen Nutzer nicht zerstört oder weggenommen werden.
        <br />
        CryptoMotors sind digitale 3D-Fahrzeuge, die von Designprofis mit Hilfe der Community
        entwickelt und auf der Grundlage der Ethereum-Blockchain errichtet werden. Diese Fahrzeuge
        können mit Ether gekauft und verkauft werden, ebenso personalisiert, um neue
        kundenspezifische Varianten zu erstellen. Die Fahrzeuge eines Projektes werden nur in einer
        begrenzten Einheit mit einer eindeutigen Identifikationsnummer und Statistik angeboten;
        nach dem Grundsatz der digitalen Seltenheit, sind einige Gegenstände seltener als
        andere, wie in der realen Welt.
      </li>
      <li>
        Unterhaltung: CryptoMotors entwickelt ein Autorennspiel, bei dem die Nutzer mit ihren
        Crypto-Collectibles antreten können. Unser Team fördert auch die Integration und
        Zusammenarbeit mit verschiedenen Videospielen und Plattformen und erweitert so den Horizont
        für die Nutzer, damit diese entscheiden können, wie sie ihre Assets genießen möchten.
      </li>
      <li>
        CryptoMotors verpflichtet sich, an alle Erwerber der Crypto-Collectibles einer
        Fahrzeugreihe, im Falle einer realen Produktion des jeweiligen Fahrzeugs und dessen
        Verkauf anteilig eine Gewinnbeteiligung in Höhe von insgesamt 10 % für die Gesamtheit der
        Erwerber des Gesamtgewinns ausgeschüttet.
      </li>
    </ol>
    <h3>II. Ihr Konto</h3>
    <ol>
      <li>
        Der Zugang zu einigen (kostenpflichtigen) Diensten der Website und der App setzt die
        Erstellung eines Nutzerkontos voraus.
      </li>
      <li>
        Die Nutzung unserer Website und/oder App ist nur voll geschäftsfähigen Personen gestattet.
      </li>
      <li>
        Der Nutzer verpflichtet sich dazu, keine falschen Angaben zu seiner Person zu machen. Des
        Weiteren verpflichtet sich der Nutzer dazu, seine Angaben regelmäßig zu kontrollieren um
        die Richtigkeit dieser zu gewährleisten.
      </li>
      <li>
        Wenn Sie unsere Dienste nutzen, sind Sie für die Sicherstellung der Vertraulichkeit Ihres
        Kontos, Ihres Passworts und für die Beschränkung des Zugangs zu Ihrem Computer und Ihren
        mobilen Geräten sowie für die Sicherheit Ihrer elektronischen Geldbörse („Wallet“)
        verantwortlich. Sie verpflichten sich alle erforderlichen Schritte zu unternehmen, um
        sicherzustellen, dass Ihr Passwort geheim gehalten und sicher aufbewahrt wird.
      </li>
      <li>
        Sie verpflichten sich uns unverzüglich zu informieren, wenn Sie Kenntnis davon haben, dass
        ein Dritter Kenntnis von Ihrem Passwort erlangt hat oder das Passwort unautorisiert
        genutzt wird oder dies wahrscheinlich ist.
      </li>
      <li>
        Sie können Ihren Benutzernamen und Ihr Passwort nachträglich jederzeit ändern.
      </li>
      <li>
        Sie dürfen unsere Dienste nicht in einer Weise verwenden, die dazu geeignet ist, die
        Dienste zu unterbrechen, zu beschädigen oder in sonstiger Art zu beeinträchtigen. Des
        Weiteren dürfen Sie die Dienste nicht für betrügerische oder in Verbindung mit einer
        Straftat stehende oder rechtswidrige Aktivitäten verwenden.
      </li>
      <li>
        Wir behalten uns das Recht vor, Ihnen die Dienste auf der Website vorzuenthalten oder
        Mitgliedskonten nach billigem Ermessen zu schließen. Dies gilt insbesondere für den Fall,
        dass Sie gegen anwendbares Recht, vertragliche Vereinbarungen oder unsere
        Nutzungsbedingungen verstoßen. Dies hat keinen Einfluss auf Ihre Rechte an den
        Crypto-Collectibles.
      </li>
      <li>
        Die Nutzer der Website und/oder der App können ihr Konto jederzeit löschen. Nach dem
        Löschen des Kontos können Sie auf den Dienst nicht mehr zugreifen. Dies hat keinen Einfluss
        auf Ihre Rechte an den Crypto-Collectibles.
      </li>
    </ol>
    <h3>III. Gebühren und Bezahlung</h3>
    <ol>
      <li>
        Um Transaktionen auf der App und/oder der Website durchführen zu können, müssen Sie
        zunächst eine Browser Erweiterung namens MetaMask (oder eine andere
        Kryptowährung-kompatible Browser Erweiterung) installieren. Dies setzt voraus, dass Sie
        einen hierfür kompatiblen Webbrowser wie zum Beispiel den Google Chrome Webbrowser
        installiert haben. MetaMask ist eine elektronische Geldbörse („Wallet“), die es Ihnen
        ermöglicht, direkt über die Plattform Coinbase Kryptowährung zu kaufen, zu speichern und
        Transaktionen mit Kryptowährung durchzuführen. Sie können auf unserer Website und/oder App
        nur über MetaMask (oder andere Kryptowährung-kompatible Browser Erweiterungen)
        Transaktionen durchführen. Hierzu müssen Sie Ihre Wallet mit unserer Website und App
        verbinden.
      </li>
      <li>
        Für den Erwerb eines Crypto-Collectibles oder zusätzliche Funktionen, Erweiterungen oder
        Modifikationen sind die bei den jeweiligen Produkten ausgewiesenen Preise an die
        CryptoMotors UG zu entrichten.
      </li>
      <li>
        Wenn Sie Transaktionen auf der Website und/oder der App durchführen werden diese
        Transaktionen ausschließlich über das Kryptowährungs-Netzwerk durchgeführt.
      </li>
      <li>
        Kryptowährungen verlangen für Transaktionen, die in dem Netzwerk stattfinden, die Zahlung
        einer Transaktionsgebühr. Sie müssen also für jede Transaktion eine solche
        Transaktionsgebühr bezahlen.
      </li>
      <li>
        Wir bieten Ihnen auf der App oder Website eine Plattform, Ihre Crypto-Collectibles zu
        handeln („Resale“). Wir verlangen   für den Fall eines Resales über unsere Plattform eine
        Provision in Höhe von 5 %. Sie erklären sich damit einverstanden, dass diese Provision
        als Teil Ihrer Zahlung direkt über das Kryptowährungs-Netzwerk an uns weitergeleitet wird.
        Wir werden keine Provision für Transaktionen einziehen, welche nicht über unsere Website
        und/oder App getätigt wurden.
      </li>
      <li>
        Alle Preise enthalten bereits die gesetzliche Mehrwertsteuer, soweit nicht ausdrücklich
        etwas anderes vermerkt ist.
      </li>
    </ol>
    <h3>IV. Rechte; Urheberrecht</h3>
    <ol>
      <li>
        Die auf unserer Website und App veröffentlichten Inhalte, Werke und bereitgestellten
        Informationen unterliegen dem deutschen Urheberrecht und Leistungsschutzrecht.
      </li>
      <li>
        Cryptomotors UG ist alleiniger Inhaber aller Rechte und Eigentumsrechte an den auf
        unserer Website und App von Cryptomotors UG zur Verfügung gestellten Diensten und Inhalten.
      </li>
      <li>
        Sie können auf unserer Website und App Rechte an Crypto-Collectibles erwerben.
        Crypto-Collectibles sind immaterielle digitale Vermögenswerte, die nur aufgrund des im
        Kryptowährungs-Netzwerk geführten Nachweises existieren und nicht auf einem physischen
        Datenträger gespeichert sind. Unsere Website und/oder App selbst speichert, sendet oder
        empfängt keine Crypto-Collectibles. Dies liegt daran, dass Crypto-Collectibles nur auf
        Grund des Nachweises in der Blockchain im Kryptowährungs-Netzwerk existieren, konkret in
        der Ethereum-Blockchain.
      </li>
      <li>
        Wir gewähren Ihnen beim Erwerb eines Crypto-Collectibles beschränkte, nicht exklusive,
        übertragbare Rechte zur Nutzung  des Crypto-Collectibles für die folgenden Zwecke:
        <br />
        <br />
        a) Für den persönlichen, nicht kommerziellen Gebrauch;
        <br />
        <br />
        b) Zur Transaktion der Rechte auf Marktplätzen, vorausgesetzt, der Marktplatz überprüft
        kryptographisch die Rechte eines jeden Crypto-Collectibles Erwerbers, um sicherzustellen,
        dass nur der tatsächliche Rechteinhaber die Crypto-Collectibles anzeigen und übertragen
        kann. Insbesondere können die Crypto-Collectibles auf dem von uns zur Verfügung gestellten
        Marktplatz transferiert werden.
        <br />
        <br />
        c) Zur Aufnahme, Beteiligung oder Teilnahme an Rennspielen auf unserer Website und/oder
        App oder auf einer Drittanbieter-Website oder –Anwendung, vorausgesetzt, die
        Website/Anwendung überprüft kryptographisch die Rechte eines jeden Crypto-Collectibles
        Erwerbers, um sicherzustellen, dass nur der tatsächliche Rechteinhaber die
        Crypto-Collectibles anzeigen und benutzen kann.
        <br />
        <br />
        d) Zur Modifizierung der Crypto-Collectibles, vorausgesetzt, die Modifizierungen lassen
        sich kryptographisch überprüfen. Insbesondere können die Crypto-Collectibles durch die von
        uns zur Verfügung gestellten Dienste modifiziert werden.
      </li>
      <li>
        Die Crypto-Collectibles werden nur auf der Plattform von CryptoMotors oder den unserer
        Partner visualisierbar sein.
      </li>
      <li>
        Die gewährten Rechte gelten nur in dem Umfang, in dem Sie weiterhin Rechteinhaber der
        jeweiligen Crypto-Collectibles sind. Wenn sie diese transferieren erlöschen Ihre Rechte.
      </li>
      <li>
        Jede Art der Vervielfältigung, Bearbeitung, Verbreitung, Einspeicherung und jede Art der
        Verwertung außerhalb der Grenzen der Rechte bedarf unserer vorherigen schriftlichen
        Zustimmung. Insbesondere dürfen die Crypto-Collectibles nicht,
        <br />
        <br />
        a) verwendet werden, um Produkte oder Dienstleistungen Dritter zu bewerben;
        <br />
        <br />
        b) in Zusammenhang mit Bildern, Videos oder anderen Medien, die Hass, Intoleranz und/oder
        Gewalt darstellen oder die Rechte Dritter verletzen verwendet werden;
        <br />
        <br />
        c) zu kommerziellen Zwecken verwendet werden.
      </li>
    </ol>
    <h3>V. Design-Wettbewerbe</h3>
    <ol>
      <li>
        Die Nutzer unserer Website und/oder App können an den von uns in unregelmäßigen Abständen
        veranstalteten „Design-Wettbewerben“ teilnehmen. Für die Wettbewerbe gelten zusätzlich zu
        diesen Nutzungsbedingungen die jeweiligen Teilnahmebedingungen.
      </li>
    </ol>
    <h3>VI. Haftung</h3>
    <ol>
      <li>
        Cryptomotors wird sich bemühen, den Dienst möglichst unterbrechungsfrei zum Abruf
        anzubieten. Auch bei aller Sorgfalt können Ausfallzeiten nicht ausgeschlossen werden, in
        denen die Webserver auf Grund von technischen oder sonstigen Problemen, die nicht im
        Einflussbereich von Cryptomotors liegen, über das Internet nicht abrufbar ist. Der Nutzer
        erkennt an, dass eine 100%ige Verfügbarkeit der Website technisch nicht zu realisieren ist.
      </li>
      <li>
        Soweit sich aus unseren Nutzungsbedingungen einschließlich der nachfolgenden Bestimmungen
        nichts anderes ergibt, haften wir bei einer Verletzung von vertraglichen und
        außervertraglichen Pflichten nach den gesetzlichen Vorschriften.
      </li>
      <li>
        Auf Schadensersatz haften wir – gleich aus welchem Rechtsgrund – im Rahmen der
        Verschuldenshaftung bei Vorsatz und grober Fahrlässigkeit. Bei einfacher Fahrlässigkeit
        haften wir vorbehaltlich eines milderen Haftungsmaßstabs nach gesetzlichen Vorschriften
        (z.B. für Sorgfalt in eigenen Angelegenheiten) nur
        <br />
        <br />
        a) für Schäden aus der Verletzung des Lebens, des Körpers oder der Gesundheit,
        <br />
        <br />
        b) für Schäden aus der nicht unerheblichen Verletzung einer wesentlichen Vertragspflicht
        (Verpflichtung, deren Erfüllung die ordnungsgemäße Durchführung des Vertrags überhaupt
        erst ermöglicht und auf deren Einhaltung der Vertragspartner regelmäßig vertraut und
        vertrauen darf); in diesem Fall ist unsere Haftung jedoch auf den Ersatz des
        vorhersehbaren, typischerweise eintretenden Schadens begrenzt.
        <br />
        <br />
        c) Die sich aus 2. ergebenden Haftungsbeschränkungen gelten auch bei Pflichtverletzungen
        durch bzw. zugunsten von Personen, deren Verschulden wir nach gesetzlichen Vorschriften
        zu vertreten haben oder eine Garantie für die Beschaffenheit der Crypto-Collectibles
        übernommen haben und für Ansprüche des Käufers nach dem Produkthaftungsgesetz.
      </li>
      <li>
        Als Nutzer sind Sie verantwortlich für die Umsetzung angemessener Maßnahmen zur Sicherung
        des Wallets, Tresor oder eines anderen Speichermechanismus, den Sie zum Kauf, Tausch oder
        zur Nutzung von plattforminternen Elementen, Token und/oder anderen Objekten auf der
        CryptoMotors-Plattform, derzeit bestehend aus einer App und einer Website, verwenden,
        einschließlich aller erforderlichen privaten Passwörter oder anderer Zugangsdaten,
        die für den Zugriff auf diese Speichermechanismen erforderlich sind. CryptoMotors als
        Plattformorganisator ist und bleibt nicht verantwortlich für Verluste, die mit dem Verlust
        Ihrer Zugangsdaten oder dem Zugriff Dritter auf Ihre Zugangsdaten verbunden sind.
      </li>
      <li>
        Durch den Kauf von plattforminternen Artikeln und/oder den Erhalt von Token und die
        Nutzung der CryptoMotors-Plattform erklären und garantieren Sie dies:
        <br />
        <br />
        a) Sie verfügen über ausreichendes Verständnis und Erfahrung mit kryptographischen
        Token (insbesondere Ethereum), Token-Speichermechanismen (z.B. Token-Wallets) und
        Blockchain-Technologie, um diese Bedingungen zu verstehen und die Risiken und Auswirkungen
        des Erwerbs, der Beschaffung und des Austauschs von plattforminternen Elementen über
        Ethereum zu verstehen;
        <br />
        <br />
        b) Sie haben diese Bedingungen gelesen und verstanden (einschließlich aller Anlagen, falls
        vorhanden);
        <br />
        <br />
        c) Sie haben ausreichende Informationen über die CryptoMotors-Plattform erhalten, um eine
        fundierte Entscheidung zum Kauf, Erhalt oder Austausch von plattforminternen Artikeln
        und/oder Token zu treffen;
        <br />
        <br />
        d) Sie kaufen, erhalten oder tauschen keine plattforminternen Artikel und/oder Token für
        andere Zwecke als die Teilnahme an der CryptoMotors-Plattform bzw. derjenigen unserer
        Partner und die in diesen AGB erklärten Plattformfunktionen auf der Website und/oder auf
        der App;
        <br />
        <br />
        e) Ihr Kauf, Ihre Beschaffung und Ihr Umtausch von plattformgebundenen Artikeln und Token
        entsprechen den geltenden Gesetzen und Vorschriften in Ihrer Gerichtsbarkeit,
        einschließlich, aber nicht beschränkt auf (i) die Rechtsfähigkeit und alle anderen
        Schwellenanforderungen in Ihrer Gerichtsbarkeit für die Nutzung und Bezahlung von Ether
        und den Abschluss von Verträgen mit dem Plattformorganisator, (ii) alle für diesen Kauf
        geltenden Devisen- oder regulatorischen Beschränkungen und (iii) alle Regierungs- oder
        sonstigen Zustimmungen, die eingeholt werden müssen;
        <br />
        <br />
        f) Sie werden allen in Ihrer Gerichtsbarkeit geltenden Steuerpflichten nachkommen, die
        sich aus dem Kauf, der Beschaffung oder dem Austausch von plattformübergreifenden Artikeln
        ergeben, falls erforderlich;
        <br />
        <br />
        g) Wenn Sie plattformunabhängige Artikel oder Token im Namen einer natürlichen oder
        juristischen Person kaufen, erhalten oder tauschen, sind Sie berechtigt, diese Bedingungen
        im Namen dieser natürlichen oder juristischen Person anzunehmen, und dass diese natürliche
        oder juristische Person für einen Verstoß gegen diese Bedingungen durch Sie oder diese
        natürliche oder juristische Person oder einen anderen Mitarbeiter oder Vertreter dieser
        Person verantwortlich ist;
        <br />
        <br />
        h) Sie stimmen zu, dass bei der Nutzung der Plattform das Risiko besteht, digitale Assets
        in Form von Kryptowährung zu verlieren, insbesondere durch Verlust der Private Keys, und
        dass der Plattformorganisator Ihnen gegenüber keine Verantwortung für einen solchen
        Verlust trägt.
      </li>
    </ol>
    <h3>VII. Drittanbieter; Drittanwendungen; Wertschwankungen</h3>
    <ol>
      <li>
        Die Webseite und die App enthalten sog. „externe Links“ (Verlinkungen) zu anderen
        Webseiten, auf deren Inhalt wir keinen Einfluss haben. Aus diesem Grund übernehmen wir für
        diese Inhalte auch keine Gewähr. Für die Inhalte und Richtigkeit der bereitgestellten
        Informationen ist der jeweilige Anbieter der verlinkten Webseite verantwortlich. Zum
        Zeitpunkt der Verlinkung waren keine Rechtsverstöße erkennbar. Bei Bekanntwerden einer
        solchen Rechtsverletzung wird der Link umgehend entfernt.
      </li>
      <li>
        Weiter haben wir keinen Einfluss auf Drittanwendungen, Produkte oder Dienste Dritter oder
        andere Websites wie MetaMask, Coinbase, Google Chrome, Kryptowährungs-Netzwerke, auf die
        Sie zugreifen um Ihnen die Nutzung der verschiedenen Funktionen der Website und/oder App
        zu ermöglichen. Für die Anwendungen ist der jeweilige Anbieter selbst verantwortlich.
        Aus diesem Grund übernehmen wir für diese auch keine Haftung.
      </li>
      <li>
        Die Werte von Blockchain-Assets unterliegen extremen Schwankungen. Wir können nicht
        garantieren, dass Erwerber von Crypto-Collectibles kein Geld verlieren.
      </li>
    </ol>
    <h3>VIII. Freistellung</h3>
    <p>
      Sie verpflichten sich, die Cryptomotors UG im Hinblick auf sämtliche Ansprüche Dritter
      sowie für sämtliche Verbindlichkeiten, Zahlungspflichten, Kosten oder Schäden freizustellen,
      die aufgrund von oder im Zusammenhang mit
    </p>
    <ol>
      <li>
        von Ihnen begangenen Verletzungen der Nutzungsbedingungen,
      </li>
      <li>
        von Ihnen begangenen Verletzungen von gewerblichen Schutzrechten oder sonstigen Rechten oder
        Datenschutzbestimmungen Dritter, oder
      </li>
      <li>
        einem Missbrauch des Dienstes durch Dritte entstehen, sofern der Missbrauch dadurch möglich
        wurde, dass Sie versäumt haben, angemessene Maßnahmen zum Schutz Ihres Benutzernamens und
        Passworts gegen Missbrauch durch Dritte zu treffen.
      </li>
    </ol>
    <h3>IX. Datenschutz</h3>
    <p>
      Alle personenbezogenen Daten werden gemäß den geltenden datenschutzrechtlichen Bestimmungen
      sowie gemäß unserer Datenschutzerklärung erhoben, gespeichert, verarbeitet und genutzt.
      Um unsere Datenschutzerklärung zu lesen, klicken Sie bitte hier:
      {' '}
      <Link to={PRIVACY_POLICY}>Link</Link>
    </p>
    <h3>X. Änderung der Nutzungsbedingungen</h3>
    <p>
      Wir behalten uns das Recht vor, Änderungen an unseren Diensten wie der Website und der App,
      Regelwerken, Bedingungen, einschließlich dieser Nutzungsbedingungen jederzeit vorzunehmen.
      Sie unterliegen den jeweiligen Bedingungen, die zu dem Zeitpunkt in Kraft sind, an dem Sie
      unsere Dienste nutzen.
    </p>
    <h3>XI. Widerrufsrecht</h3>
    <p>
      Soweit Sie nicht wirksam auf Ihr Widerrufsrecht verzichtet haben, steht Ihnen, wenn Sie einen
      Vertrag als Verbraucher geschlossen haben und in Deutschland ansässig sind, ein gesetzliches
      Widerrufsrecht wie folgt zu:
    </p>
    <p>
      Sie haben das Recht, binnen vierzehn Tagen ohne Angaben von Gründen diesen Vertrag zu
      widerrufen.
    </p>
    <p>
      Die Widerrufsfrist beträgt vierzehn Tage ab dem Tag des Vertragsschlusses.
    </p>
    <p>
      Um Ihr Widerrufsrecht auszuüben, müssen Sie uns (Ihre Kontaktdaten) mittels einer
      eindeutigen Erklärung (z.B. ein mit der Post versandter Brief, Telefax oder E-Mail) über
      Ihren Entschluss, diesen Vertrag zu widerrufen, informieren. Sie können dafür das beigefügte
      Muster-Widerrufsformular verwenden, das jedoch nicht vorgeschrieben ist. Sie können das
      Muster-Widerrufsformular oder eine andere eindeutige Erklärung auch auf unserer Webseite
      elektronisch ausfüllen und übermitteln. Machen Sie von dieser Möglichkeit Gebrauch, so werden
      wir Ihnen unverzüglich (z.B. per Email) eine Bestätigung über den Eingang eines solchen
      Widerrufs übermitteln.
    </p>
    <p>
      Zur Wahrung der Widerrufsfrist reicht es aus, dass Sie die Mitteilung über die Ausübung des
      Widerrufsrechts vor Ablauf der Widerrufsfrist absenden.
    </p>
    <p>
      Folgen des Widerrufs
    </p>
    <p>
      Wenn Sie diesen Vertrag widerrufen, haben wir Ihnen alle Zahlungen, die wir von Ihnen
      erhalten haben, einschließlich der Lieferkosten (mit Ausnahme der zusätzlichen Kosten, die
      sich daraus ergeben, dass Sie eine andere Art der Lieferung als die von uns angebotene,
      günstige Standardlieferung gewählt haben), unverzüglich und spätestens binnen vierzehn Tagen
      ab dem Tag zurückzuzahlen, an dem die Mitteilung über Ihren Widerruf dieses Vertrages bei uns
      eingegangen ist. Für diese Rückzahlung verwenden wir dasselbe Zahlungsmittel, das Sie bei der
      ursprünglichen Transaktion eingesetzt haben, es sei denn, mit Ihnen wurde ausdrücklich etwas
      anderes vereinbart; in keinem Fall werden Ihnen wegen dieser Rückzahlung Entgelte berechnet.
    </p>
    <p>
      Muster-Widerrufsformular
    </p>
    <p>
      (Wenn Sie den Vertrag widerrufen wollen, dann füllen Sie bitte dieses Formular aus und senden
      Sie es zurück.)
    </p>
    <p>
      An die (Ihre Kontaktdaten):
    </p>
    <p>
      Hiermit widerrufe(n) ich/wir (*) den von mir/uns (*) abgeschlossenen Vertrag über den Kauf
      der folgenden Waren (*)/ die Erbringung der folgenden Dienstleistung (*)
    </p>
    <p>
      Bestellt am (*)/erhalten am (*)
    </p>
    <p>
      Name des/der Verbraucher(s)
    </p>
    <p>
      Anschrift des/der Verbraucher(s)
    </p>
    <p>
      Unterschrift des/der Verbraucher(s) (nur bei Mitteilung auf Papier)
    </p>
    <p>
      Datum
    </p>
    <p>
      (*) Unzutreffendes streichen
    </p>
    <h3>XII. Sonstiges</h3>
    <ol>
      <li>
        Dieser Vertrag und die gesamten Rechtsbeziehungen der Parteien unterliegen dem Recht der
        Bundesrepublik Deutschland unter Ausschluss des UN-Kaufrechts (CISG).
      </li>
      <li>
        Sollten einzelne Bestimmungen dieser Nutzungsbedingungen unwirksam sein, so berührt dies
        nicht die Gültigkeit der übrigen Regelungen. Ungültige Bestimmungen sind durch solche zu
        ersetzen, die der beabsichtigten Bedeutung der ungültigen Bestimmung am nächsten kommen.
        Gleiches gilt bei Auftreten eventueller ausfüllungsbedürftiger Lücken.
      </li>
      <li>
        Sofern es sich beim Nutzer um einen Kaufmann handelt, ist Gerichtsstand für alle
        Streitigkeiten aus Vertragsverhältnissen zwischen dem Nutzer und der Gesellschaft der Sitz
        der Gesellschaft.
      </li>
      <li>
        Die Cryptomotors UG nimmt nicht an Streitbeilegungsverfahren vor einer
        Verbraucherschlichtungsstelle zur Regelung von Auseinandersetzungen mit Verbrauchern teil.
      </li>
    </ol>
  </div>
);

export default TermsOfUse;
