import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';

import './notify.css';

const Notify = ({text, hideNotify}) => {
    return ( 
        <div className="notify">
            <span>Notice:<span className="notify-text">{text}</span></span>
            <FontAwesomeIcon onClick={()=> {hideNotify();}} className="close-notify" icon={faTimes}/>
        </div>
     );
}
 
export default Notify;