import React, {useContext} from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import UserDetails from '../Utils/UserDetails';
import { RaceContext } from '../../../../context/RaceContext';
import { PlayAudioContext } from '../../../../context/PlayAudioContext';
import {fakeTracks} from '../../FakeTracks';
import Notify from '../../../Notification/Notify';

const SelectTrack = ({
  circuits, selectedCircuit, onClickCircuit, onClickNextStage, loggedUserScore, statusNotify, hideNotify
}) => {
  
  const {playStopMusic} = useContext(PlayAudioContext);
  const {lastrace} = useContext(RaceContext);  
  const {username, rankingPoints, ranked, circuitName, racingLeftToRank} = loggedUserScore;  

  const handlePlayStopMusic = () => { 
    playStopMusic();  
  }
  
  return (
    <div className="banner__area game-page track-page">
      <div className="page-area">
        <div className="single__banner banner_bg single__banner2 bann-800">        
          <div className="container wi-1245 pt-100">
            {lastrace !== null && lastrace !== '' ? 
              <span className="d-lg-none last-race">Check last race <Link className="last-race-link" to={lastrace}>here</Link></span> 
            : null}                     
            <UserDetails
              showLastRace={lastrace !== null && lastrace !== ''}
              username={username}
              score={rankingPoints}
              handleMusicPlay={handlePlayStopMusic}              
              canPlayStop={true}    
              isInRank={ranked}
              circuitName={circuitName}
              racingsLeftToRank={racingLeftToRank}
            />
            {statusNotify?
              <Notify 
                text={`The race "${localStorage.getItem('raceExpiredUUID')}" has expired, please race again.`}
                hideNotify={hideNotify}
              />
            :
              null
            }
            <div className="row">
              <div className="col-12">
                <div className="all-heading">
                  <h2>Select a track</h2>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="select-box">
                  {circuits.map(circuit => (                    
                      <a className={`select-box-item ${circuit.name === selectedCircuit.name ? 'gm-active' : ''}`} key={circuit.name} onClick={() => onClickCircuit(circuit.name)}>
                        <div className="select-box-items">
                          <h5>{circuit.name}</h5>                          
                        </div>
                        <div className="select-box-img">
                          <img src={`/game-images/icon/${circuit.name}-track.png`} alt="circuit" />
                        </div>
                      </a>
                  ))}
                  {fakeTracks.map(fakeTrack => (
                    <a onClick={() => { }} className="select-box-item lk-game" key={fakeTrack.name}>
                      <div className="select-box-items">
                        <h5>{fakeTrack.name}</h5>                          
                      </div>
                      <div className="select-box-img">
                        <img src={`/game-images/icon/${fakeTrack.uuid}.png`} alt="Icon" />
                      </div>
                      <p>LOCKED</p>
                    </a>
                  ))}
                </div>
              </div>

              <div className="col-lg-5 offset-lg-1">
                <div className="misano-dinan-box-area">
                  <div className="misano-items">
                    <div className="misano-img"><img src={`/game-images/tracks-preview/${selectedCircuit.name}.png`} alt={selectedCircuit.name} /></div>
                    <div className="misano-content">
                      <h5>{selectedCircuit.name}</h5>                      
                    </div>
                  </div>
                </div>
                <div className="starts_wrap">
                  <div className="bordered_title">
                    <h4>TRACK STATS</h4>
                  </div>
                  <div className="lines">
                    <div className="single_line d-flex justify-content-between ">
                      <span className="left line_wrap">CIRCUIT LENGTH</span>
                      <span className="right line_wrap">{`${selectedCircuit.lapLength} KM`}</span>
                    </div>
                    <div className="single_line d-flex justify-content-between ">
                      <span className="left line_wrap">MAX CARS</span>
                      <span className="right line_wrap">{selectedCircuit.maxVehicleNumber}</span>
                    </div>
                    <div className="single_line d-flex justify-content-between ">
                      <span className="left line_wrap">LONGEST STRAIGHT LINE</span>
                      <span className="right line_wrap">{selectedCircuit.longestStraightLine}</span>
                    </div>
                    <div className="single_line d-flex justify-content-between ">
                      <span className="left line_wrap">CURVES</span>
                      <span className="right line_wrap">{selectedCircuit.totalCurves}</span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="center padding-vertical-small">
                <button className="boxed_btn" onClick={onClickNextStage}>START GAME</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

SelectTrack.propTypes = {
  circuits: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
    }),
  ).isRequired,
  loggedUserScore: PropTypes.shape({
    username: PropTypes.string.isRequired,
  }).isRequired,
  selectedCircuit: PropTypes.shape({
    name: PropTypes.string.isRequired,
  }).isRequired,

  onClickCircuit: PropTypes.func.isRequired,
  onClickNextStage: PropTypes.func.isRequired,
};

export default SelectTrack;
