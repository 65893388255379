import React from 'react';
import './Vision.css';

const Vision = () => (
  <div className="vision-wrapper">
    <div className="vision">
      <div className="header-image">
        <img alt="vision-header" src="/images/vision/1-960.jp2" style={{ visibility: 'hidden' }} />
      </div>
      <div className="center">
        <h1>Our vision</h1>
        <p>
          Our main goal at CryptoMotors is to put together the highest quality standards of the
          automotive industry with the latest VR, AR and Blockchain technology. As a result, we aim
          to create professional digital vehicles that users are pleased to own, collect and be
          entertained with.
        </p>
      </div>
      <div className="second-image">
        <p className="center">
          We look forward to a future where people own digital assets just like they own goods in
          the real world. A future where users have the power to decide where to buy, trade, or
          use these items.
        </p>
      </div>
    </div>
    <div className="creation-hub">
      <div>
        <div className="quote">
          <h2>Open creation hub</h2>
          <hr />
          <p>
            We want CryptoMotors to be a decentralized platform where anyone can be a part of the
            amazing journey of designing a car. We want the community to have a say on how our cars
            look, with no boundaries for creativity.
          </p>
        </div>
        <div className="image">
          <img alt="vision3" src="/images/vision/3-560.jp2" className="shadow" />
        </div>
      </div>
    </div>
    <div className="professional-work">
      <div>
        <div className="quote">
          <h2>Professional work</h2>
          <hr />
          <p>
            Our team strongly believes that skilled hands can add a lot of value and personality
            to the crypto-collectibles scene. We want to promote this philosophy and make it an
            industry standard.
          </p>
        </div>
        <div className="image">
          <img alt="vision4" src="/images/vision/4-560.jp2" className="shadow" />
        </div>
      </div>
    </div>
  </div>
);

export default Vision;
