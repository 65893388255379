import React, { Component } from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { GAME_API_URL } from '../../../../config/Config';
import axios from 'axios';
import AuthContext from '../../../../context/AuthContext';
import UserDetails from '../Utils/UserDetails';
import Tooltip from '../Utils/Tooltip';
import '../../css/style.css';
import 'react-tabs/style/react-tabs.css';


class Score extends Component {
  static contextType = AuthContext;

  state = {
    isLoading: true,
    ranking: [],
    loggedUserScore: {},
    cuircuits: [],
    score: [],
  };

  async componentDidMount() {
    
    let userScore = { username: "", score: 0, rankingPoints: 0};
    let scores = [];

    if(this.context.username !== null){
      const userData = (await axios({
        method: 'GET',
        url: `${GAME_API_URL}/score`,
        headers: { Authorization: this.context.token },
      })).data;

      scores = userData.scores;
      if(userData.scores.length > 0) {
        const defaultScore = userData.scores[0];
        userScore = {
          username: this.context.username,
          score: defaultScore.score === null ? 0 : defaultScore.score,
          rankingPoints: defaultScore.rankingPoints === null ? 0 : defaultScore.rankingPoints,
          ranked: defaultScore.ranked,
          circuitName: defaultScore.circuitName,
          racingsLeftToRank: defaultScore.racingsLeftToRank !== null ? userScore.racingsLeftToRank : 0,
        };     
      }else{
        userScore = {
          username: this.context.username,
        }; 
      }
    }

    const circuitsData = (await axios({
      method: 'GET',
      url: `${GAME_API_URL}/circuits`,
    })).data;

    const ranking = await this.getRankingByCircuitUuid(circuitsData[0].uuid);

    this.setState({ 
      ranking: ranking,
      isLoading: false,  
      loggedUserScore: userScore,
      cuircuits: circuitsData,
      score: scores
    });
    
  }

  getRankingByCircuitUuid = async uuid => {
    const ranking = (await axios({      
      method: 'GET',
      url: `${GAME_API_URL}/users/ranking/${uuid}`,      
    })).data;
    return ranking;
  }

  handleTabClick = async index => {
    const ranking = await this.getRankingByCircuitUuid(this.state.cuircuits[index].uuid);
    this.setState({ 
      ranking: ranking,
    });

    if(this.context.username !== null){ 
      const userScore = this.state.score.find(s => s.circuitUuid === this.state.cuircuits[index].uuid);
      const newScore = {
        score: userScore && userScore.score ? userScore.score : 0,
        rankingPoints: userScore && userScore.rankingPoints ? userScore.rankingPoints : 0,
        ranked: userScore && userScore.ranked ? userScore.ranked : null,
        circuitName: userScore && userScore.circuitName ? userScore.circuitName : null,
        username: this.context.username,
        racingsLeftToRank: userScore && userScore.racingsLeftToRank ? userScore.racingsLeftToRank : null,
      };  

      this.setState({ 
        loggedUserScore: newScore,
      });
    }
  }
  
  ordinalSufix = (i) => {
    const j = i % 10;

    const k = i % 100;
    if (j === 1 && k !== 11) {
      return `${i}st`;
    }
    if (j === 2 && k !== 12) {
      return `${i}nd`;
    }
    if (j === 3 && k !== 13) {
      return `${i}rd`;
    }
    return `${i}th`;
  }  

  render() {
    const { ranking, isLoading, loggedUserScore, cuircuits } = this.state;
    if (isLoading) {
      return (
        <div className="game game-page">
          <div className="banner__area race-summary-page-area monthly-page">
            <div className="page-area">
              <div className="single__banner banner_bg single__banner2  bann-800">
                <div className="container wi-1000 pt-100">
                  <i className="fas fa-spinner fa-spin standalone-loading-white" />
                </div>
              </div>
            </div>
          </div>
        </div>
      ); 
    }    

    const modifiers = [
      {
        name: 'offset',
        enabled: true,
        options: {
          offset: [0, 15],          
        },
      },
    ];
  
    return (
      <div className="game game-page">
        <div className="banner__area race-summary-page-area monthly-page">
          <div className="page-area">
            <div className="single__banner banner_bg single__banner2  bann-800">
              <div className="container wi-1000 pt-100">              
              {this.context.username !== null ?
                <UserDetails
                  username={loggedUserScore.username}
                  score={loggedUserScore.rankingPoints}  
                  isInRank={loggedUserScore.ranked}
                  circuitName={loggedUserScore.circuitName}    
                  racingsLeftToRank={loggedUserScore.racingsLeftToRank}                                                             
                />
              :
                null
              }             
               
                <div className="row">
                  <div className="col-12">
                    <div className="all-heading">
                      <h2>Leaderboard</h2>
                    </div>
                  </div>
                  <div className="col-12 lines">
                    <div className="starts_wrap wi-900">
                      <Tabs direction="ltr" defaultIndex={0} onSelect={index => this.handleTabClick(index)}>
                        <TabList>
                          {cuircuits.map(circuit => <Tab key={circuit.uuid}>{circuit.name}</Tab>)}
                        </TabList>                    
                          {cuircuits.map(circuit => 
                            <TabPanel
                              key={circuit.uuid}
                            >                              
                                <div className="lines-mid">
                                  <div className="single_line bor-flex d-flex justify-content-between">                        
                                    <span className="left line_wrap">RANKING</span>
                                    <span className="crnter line_wrap">USER</span>
                                    <span className="crnter line_wrap">RACINGS</span>
                                    <span className="crnter line_wrap">WINS</span>
                                    <span className="right line_wrap">
                                      <Tooltip 
                                        className="tooltip"
                                        placement="top" 
                                        trigger="hover" 
                                        tooltip="CryptoMotors Ranking Unit"                            
                                        modifiers={modifiers}
                                      >   
                                        CMR
                                      </Tooltip> 
                                    </span>                          
                                  </div>
                                  {ranking.users.map(({ username, racings, wonRacings, points }, index) => (
                                    <div className="single_line d-flex justify-content-between" key={username}>
                                      <span className="left line_wrap">{this.ordinalSufix(index + 1)}</span>
                                      <span className="crnter line_wrap">{username}</span>
                                      <span className="crnter line_wrap">{racings}</span>
                                      <span className="crnter line_wrap">{wonRacings}</span>
                                      <span className="right line_wrap">{points}</span>
                                    </div>
                                  ))}
                                </div>                                
                            </TabPanel>
                          )}                    
                      </Tabs> 
                    </div>          
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Score;
