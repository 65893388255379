import React, {useState, useEffect, useContext} from 'react';
import { Redirect } from 'react-router-dom';
import UserDetails from '../Utils/UserDetails';
import PropTypes from 'prop-types';
import moment from 'moment';
import Clipboard from '../Utils/Clipboard';
import Tooltip from '../Utils/Tooltip';
import useWindowDimensions from '../Utils/hook/useWindowsDimensions';
import { GAME_SUMMARY } from '../../../../config/Routes';
import { PlayAudioContext } from '../../../../context/PlayAudioContext';
import { RaceContext } from '../../../../context/RaceContext';
import '../../css/style.css';
import '../../css/bootstrap.min.css';

const getTopStat = stats => Object.keys(stats).reduce((a, b) => (stats[a] > stats[b] ? a : b));

const RaceStart = ({ selectedCircuit, selectedVehicle, race, loggedUserScore }) => {  
  const topStat = getTopStat({
    maxSpeed: selectedVehicle.maxSpeed,
    acceleration: selectedVehicle.acceleration,
    grip: selectedVehicle.grip,
    braking: selectedVehicle.braking,
    handling: selectedVehicle.handling,
  });

  localStorage.setItem('simulationFinished', false);  
  localStorage.setItem('raceStatus', JSON.stringify({
    status: "lobby", 
    race,
    selectedCircuit,
    selectedVehicle 
  })); 
  
  const {playStopMusic} = useContext(PlayAudioContext);
  const {saveLastRace} = useContext(RaceContext);
  const [copySucess, setCopySuccess] = useState("");
  const [tooltipShown, setTooltipShown] = useState(false);
  const [placement, setPlacement] = useState("");
  const [countdown, setCountdown] = useState({
    hours: "00",
    minutes: "00",
    seconds: "00",
    milliseconds: null
  });
  
  const { width } = useWindowDimensions();
  
  useEffect(() => {
        
    saveLastRace(`${GAME_SUMMARY.replace(':uuid', race.uuid)}`);        

    if(width <= 991){
      setPlacement("top");
    }else{
      setPlacement("right");
    }
        
    const counter = setInterval(function(){
      const now = moment.duration(moment(`${race.startAt}-0000`).diff(moment()));
      setCountdown({
        hours: now.hours().toString().padStart(2, '0'),
        minutes: now.minutes().toString().padStart(2, '0'),
        seconds: now.seconds().toString().padStart(2, '0'),
        milliseconds: now.milliseconds()
      });
    }, 1000);
  
    return () => clearTimeout(counter);
        
  });

  const modifiers = [
    {
      name: 'offset',
      enabled: true,
      options: {
        offset: [0, 15],
      },
    },
  ];

  const handlePlayStopMusic = () => { 
    playStopMusic();  
  }

  const copyToClipboard = e => {    

    try{
      Clipboard.copy(`${window.location.origin.toString()}${GAME_SUMMARY.replace(':uuid', race.uuid)}`);
      setCopySuccess('Copied!');     
      setTooltipShown(true);       
      setTimeout(function(){
        setTooltipShown(false);
      }, 3000);
    }catch(error){            
    }
    
  };

  const {hours, minutes, seconds, milliseconds} = countdown;  
  const {username, rankingPoints, ranked, circuitName, racingLeftToRank} = loggedUserScore;
  
  return (
    <div className="game-page">
      <div className="single__banner banner_bg single__banner2 bann-800 race-starts-page-area">
        <div className="race-start container wi-1245 pt-100">
          <UserDetails
            showLastRace={false}            
            username={username}
            score={rankingPoints}
            handleMusicPlay={handlePlayStopMusic}
            canPlayStop={true} 
            isInRank={ranked}
            circuitName={circuitName}
            racingsLeftToRank={racingLeftToRank}             
          />
          <div className="row">
            <div className="col-12">
              <div className="all-heading">
                {milliseconds < 0 ?
                  (<Redirect to={`${GAME_SUMMARY.replace(':uuid', race.uuid)}`} />)
                :
                  <div>
                    <h2>
                      Race starts in:
                    {' '}
                      <br />
                      <span>{`${hours}:${minutes}:${seconds}`}</span>
                    </h2>
                    <p className="waiting-players">Waiting for other players to join the lobby</p>
                  </div>
                }
              </div>
            </div>
            <div className="col-lg-7 mt-100">
              <div className="race-starts-area">
                <div className="misano-items">
                  <div className="misano-img">
                    <img 
                      src={`/game-images/tracks-preview/${selectedCircuit.name}.png`} 
                      alt={selectedCircuit.name}                    
                    /></div>
                  <div className="misano-content">
                    <h5>{selectedCircuit.name}</h5>                    
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-5 mt-120">
              <div className="starts_wrap wi-290">
                <div className="bordered_title">
                  <h4>TRACK STATS</h4>
                </div>
                <div className="lines">
                  <div className="single_line d-flex justify-content-between ">
                    <span className="left line_wrap">CIRCUIT LENGTH</span>
                    <span className="right line_wrap">{`${selectedCircuit.lapLength} KM`}</span>
                  </div>
                  <div className="single_line d-flex justify-content-between ">
                    <span className="left line_wrap">MAX CARS</span>
                    <span className="right line_wrap">{selectedCircuit.maxVehicleNumber}</span>
                  </div>
                  <div className="single_line d-flex justify-content-between ">
                    <span className="left line_wrap">LONGEST STRAIGHT LINE</span>
                    <span className="right line_wrap">{selectedCircuit.longestStraightLine}</span>
                  </div>
                  <div className="single_line d-flex justify-content-between ">
                    <span className="left line_wrap">CURVES</span>
                    <span className="right line_wrap">{selectedCircuit.totalCurves}</span>
                  </div>
                </div>

                <div className="mt-10">
                  <div className="bordered_title">
                    <h4>CAR INFORMATION</h4>
                  </div>
                  <div className="lines">
                    <div className="single_line d-flex justify-content-between ">
                      <span className="left line_wrap">{selectedVehicle.model.name.toUpperCase()}</span>
                      <span className="right line_wrap">
                        #
                        {selectedVehicle.ethId.padStart(6, '0')}
                      </span>
                    </div>
                    <div className="single_line d-flex justify-content-between">
                      <span className="left line_wrap">{topStat.replace(/([A-Z])/g, ' $1').toUpperCase()}</span>
                      <span className="right line_wrap">{selectedVehicle[topStat] / 10 }</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="mo-footer">
          <p>
            Can’t wait? You can check results later in
            {' '}       
            <Tooltip 
              placement={placement} 
              trigger="click" 
              tooltip={copySucess}
              tooltipShown={tooltipShown}
              modifiers={modifiers}
            >   
              <span onClick={copyToClipboard}>here</span>
            </Tooltip>  
          </p>     
        </div>
      </div>
    </div>
  );
};

RaceStart.propTypes = {
  selectedVehicle: PropTypes.shape({
    ethId: PropTypes.string.isRequired,
    acceleration: PropTypes.number.isRequired,
    braking: PropTypes.number.isRequired,
    grip: PropTypes.number.isRequired,
    handling: PropTypes.number.isRequired,
    maxSpeed: PropTypes.number.isRequired,
    color: PropTypes.number.isRequired,
    model: PropTypes.shape({
      name: PropTypes.string.isRequired,
    }),
    files: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string.isRequired,
        type: PropTypes.number.isRequired,
      }),
    ),
  }).isRequired,
  loggedUserScore: PropTypes.shape({
    username: PropTypes.string.isRequired,
  }).isRequired,
  selectedCircuit: PropTypes.shape({
    name: PropTypes.string.isRequired,
  }).isRequired,
};

export default RaceStart;
