import GameObject from "../../Engine/GameObject";
import SpriteRenderer from "../../Engine/Components/SpriteRenderer";
import Sprite from "../../Engine/Sprite";

export const CIRCUIT_TAG = "Circuit";

export default class Circuit extends GameObject {
  loaded = false;

  width = 900; // todo: use canvas width
  height = 502; // todo: use canvas height
  spots = [];
  deltaTime = 0;
  length = 0;

  spriteRenderer = null;

  constructor(data) {
    super();

    this.tag = CIRCUIT_TAG;
    this.spots = data.spots;
    this.deltaTime = data.deltaTime;

    this.length = Math.floor(
      this.spots.reduce((length, spot, index) => {
        const nextIndex = index + 1 === this.spots.length ? 0 : index + 1;
        const nextSpot = this.spots[nextIndex];
        return length + Math.hypot(nextSpot.x - spot.x, nextSpot.y - spot.y);
      }, 0)
    );

    console.log("Circuit length: " + this.length);

    const image = new Image();
    image.src = data.image;

    this.transform.position.x = 0;
    this.transform.position.y = 0;

    this.addComponent(
      () =>
        new SpriteRenderer({
          sprite: new Sprite({
            image: image,
            width: this.width,
            height: this.height,
          }),
        }),
      "SpriteRenderer"
    );
    this.spriteRenderer = this.getComponent("SpriteRenderer");
    this.spriteRenderer.active = false;
  }

  start() {
    this.update();
  }

  update() {
    this.loaded = this.spriteRenderer.sprite.loaded;
    this.spriteRenderer.active = this.loaded;
  }
}
