import Vector2 from "../../Helper/Vector2";

export default class RenderData {
  // general
  ui = false;
  layer = null;
  position = new Vector2(0, 0);

  // for images
  image = null;
  width = 0;
  height = 0;
  slice = null;
  flipHorizontal = false;
  flipVertical = false;
  smooth = false;
  rotation = null;

  // for text
  text = null;
  font = null;
  color = null;
  textSize = null;
  lineSeparation = null;
  bold = null;
  italic = null;

  // for line
  line = false;
  lineFrom = new Vector2(0, 0);
  lineTo = new Vector2(0, 0);
  lineWidth = null;
  lineColor = null;
}
