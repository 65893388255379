import axios from 'axios';
import { API_URL } from '../config/Config';

export const getWallet = address => axios({
  method: 'GET',
  url: `${API_URL}/wallets/${address}`,
});


export const linkWallet = (token, publicAddress, signedMessage) => axios({
  method: 'POST',
  headers: { Authorization: token },
  url: `${API_URL}/wallets/eth-link`,
  data: {
    publicAddress,
    signedMessage,
  },
});
