import React from 'react';
import PropTypes from 'prop-types';
import './StatBar.css';

const StatBar = ({
  name, value, min, max,
}) => (
  <div className="stat-component">
    <div className="stat-name">{ name }</div>
    <div className="stat-bar">
      <div className="colored" style={{ width: `${max}%` }}>
        <div className="cover" style={{ width: `${max === min ? 100 : min}%` }} />
      </div>
      {value !== 0 && <div className="stat-circle" style={{ left: `${value}%` }} />}
    </div>
    <div className="stat-value">{ value === 0 ? (max / 10).toFixed(1) : (value / 10).toFixed(1) }</div>
  </div>
);

StatBar.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.number,
  min: PropTypes.number.isRequired,
  max: PropTypes.number.isRequired,
};

StatBar.defaultProps = {
  value: 0,
};

export default StatBar;
