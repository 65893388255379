import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import './Headline.css';
import { MARKET, SIGN_UP } from '../../config/Routes';
import AuthContext from '../../context/AuthContext';

const Headline = ({ showArrow }) => {
  const authContext = useContext(AuthContext);

  const { authenticated } = authContext;

  return (
    <div className="headline">
      <div>
        <h1>Start your collection</h1>
        <div>
          <Link to={authenticated ? MARKET : SIGN_UP} className="button primary big">
            {authenticated ? 'See our cars' : 'Join Now'}
          </Link>
        </div>
        {showArrow && (
        <div>
          <i className="fas fa-angle-down" />
        </div>
        )}
      </div>
    </div>
  );
};

Headline.propTypes = {
  showArrow: PropTypes.bool,
};

Headline.defaultProps = {
  showArrow: true,
};

export default Headline;
