import React from 'react';
import { DefaultToast } from 'react-toast-notifications';
import './Style.css';

export const CustomToast = ({ children, ...props }) => {    
    const {appearance} = props;
    return (    
        <DefaultToast 
            className={`toast-container ${appearance === "success" ? "" : "error"}`}
            style={{ 
                background: "#ffffff", 
                color: "#000000",                                          
            }}
            {...props}
        >
            {children}
        </DefaultToast>
    )
};

export default CustomToast;