export const ABOUT = "/about";
export const AUCTIONS = "/market/auctions";
export const CONFIRM_EMAIL = "/confirm-email/:uuid";
export const DESIGN_CHALLENGE = "/design-challenge";
export const DESIGN_CHALLENGE_DASHBOARD = "/design-challenge/vote";
export const DESIGN_CHALLENGE_DASHBOARD_MODAL =
  "/design-challenge/vote/:design";
export const DESIGN_CHALLENGE_SUCCESS = "/design-challenge/registered";
export const DESIGN_CHALLENGE_TERMS = "/design-challenge/terms-and-conditions";
export const DESIGN_CHALLENGE_UPLOAD = "/design-challenge/upload";
export const FAQ = "/faq";
export const GAME = "/stat-game";
export const GAME_SCORE = "/stat-game/leaderboard";
export const GAME_SUMMARY = "/stat-game/summary/:uuid";
export const GARAGE = "/garage/:user";
export const HOME = "/";
export const IMPRESSUM = "/impressum";
export const FACEBOOK_AUTH = "/facebook";
export const LINK_WALLET = "/link-wallet";
export const LOG_IN = "/log-in";
export const MARKET = "/market/from-studio";
export const MARKET_CAR = "/market/:model";
export const NOT_FOUND = "/404";
export const PRIVACY_POLICY = "/privacy-policy";
export const RECOVER_PASSWORD = "/password-recovery";
export const RESET_PASSWORD = "/reset-password/:token";
export const SETTINGS = "/settings";
export const SIGN_UP = "/sign-up";
export const TERMS_OF_USE = "/terms-of-use";
export const VIEW_CAR = "/vehicle/:uuid";
export const VISION = "/vision";
export const EVENT_DROP = "/event-drop";

export const MODEL_SEDAN = "sedan";
export const MODEL_SPORT_COUPE = "sportcoupe";
export const MODEL_SUV = "suv";
export const MODEL_ABYSSUS = "abyssus";
export const MODEL_NEON_ROADSTER = "neonroadster";
export const MODEL_RETRO_ROCKET = "retrorocket";

// origin redirects
export const ORIGIN_DESIGN_CHALLENGE = "design-challenge";

// OpenSea
// export const AUCTIONS = 'https://opensea.io/assets/cryptomotors?toggle%5Bon_auction%5D=true';

export const OPENSEA_VIEW_CAR =
  "https://opensea.io/assets/0x30a2fa3c93fb9f93d1efeffd350c6a6bb62ba000/:eth-id";
export const SELL_CAR =
  "https://opensea.io/assets/0x30a2fa3c93fb9f93d1efeffd350c6a6bb62ba000/:eth-id/sell";
export const GIFT_CAR =
  "https://opensea.io/assets/0x30a2fa3c93fb9f93d1efeffd350c6a6bb62ba000/:eth-id/transfer";
