import axios from 'axios';
import { API_URL } from '../config/Config';

export const login = (email, password) => axios({
  method: 'POST',
  url: `${API_URL}/authentication/token`,
  data: { email, password },
});

export const loginFacebook = code => axios({
  method: 'POST',
  url: `${API_URL}/authentication/facebook`,
  data: {
    code,
  },
});
