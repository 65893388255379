import React from 'react';
import LinkWalletLayout from './LinkWalletLayout';

const NoMetamask = () => (
  <LinkWalletLayout>
    <p>
      It looks like you don&apos;t have Metamask installed. Download it
      {' '}
      <a href="//metamask.io/" className="secondary bold" target="_blank" rel="noopener noreferrer">here</a>
      {' '}
      in order to proceed.
    </p>
  </LinkWalletLayout>
);

export default NoMetamask;
