import React, {useState, useEffect, useContext} from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import UserDetails from '../Utils/UserDetails';
import { Carousel } from 'react-responsive-carousel';
import ItemsCarousel from 'react-items-carousel';
import { PlayAudioContext } from '../../../../context/PlayAudioContext';
import { RaceContext } from '../../../../context/RaceContext';
import '../../../../../node_modules/react-responsive-carousel/lib/styles/carousel.css';

const getTopStat = stats => Object.keys(stats).reduce((a, b) => (stats[a] > stats[b] ? a : b));

const SelectCar = ({
  onClickNextStage, selectedCircuit, vehicles, selectedVehicle, onClickVehicle, loggedUserScore, onClickBackStage
}) => {
  const topStat = getTopStat({
    maxSpeed: selectedVehicle.maxSpeed,
    acceleration: selectedVehicle.acceleration,
    grip: selectedVehicle.grip,
    braking: selectedVehicle.braking,
    handling: selectedVehicle.handling,
  });
  
  const {playStopMusic} = useContext(PlayAudioContext);  
  const {lastrace} = useContext(RaceContext);
  const selectedIndex = vehicles.findIndex(vehicle => vehicle.ethId === selectedVehicle.ethId);
  const [activeItemIndex, setActiveItemIndex] = useState(selectedIndex);

  useEffect(() => {
    setActiveItemIndex(selectedIndex);
  }, [selectedIndex]);

  const handlePlayStopMusic = () => { 
    playStopMusic();  
  }

  const chevronWidth = 40;
  const numberOfCards = vehicles.length > 8 ? 8 : vehicles.length;
  const {username, rankingPoints, ranked, circuitName, racingLeftToRank} = loggedUserScore;
  
  return (
    <div className="game-page">
      <div className="banner__area mm-t">
        <div id="select-car" className="single__banner banner_bg single__banner2">
          <div className="container pt-100"> 
            {lastrace !== null && lastrace !== '' ? 
              <span className="d-lg-none last-race">Check last race <Link className="last-race-link" to={lastrace}>here</Link></span> 
            : null}            
            <UserDetails
              showLastRace={lastrace !== null && lastrace !== ''}           
              username={username}
              score={rankingPoints}
              handleMusicPlay={handlePlayStopMusic}              
              canPlayStop={true}
              canGoBack={true}
              backToStage={onClickBackStage}   
              isInRank={ranked} 
              circuitName={circuitName}
              racingsLeftToRank={racingLeftToRank}
            />            
            <div className="row">
              <div className="col-12">
                <div className="banner_content">                  
                  <div className="single_content">
                    <div className="prev_button_content d-none d-lg-block">
                      <span className="fa fa-chevron-circle-left fa-2x" onClick={onClickBackStage}></span>
                    </div>
                    <div className="left_content_header">
                      <h4 className="pc-none">
                        #
                        {selectedVehicle.ethId.padStart(6, '0')}
                      </h4>
                      <h4>{selectedVehicle.model.name}</h4>
                      <span>
                        {topStat.replace(/([A-Z])/g, ' $1')}
                      </span>
                    </div>
                    <h1>{selectedVehicle[topStat] / 10}</h1>
                    <div className="starts_wrap">
                      <div className="bordered_title">
                        <h4>STATS</h4>
                      </div>
                      <div className="lines">
                        <div className="single_line d-flex justify-content-between ">
                          <span className="left line_wrap">MAX SPEED</span>
                          <span className="right line_wrap">
                            {'I'.repeat((Math.floor(selectedVehicle.maxSpeed / 10)) * 2)}
                            {' '}
                            {(selectedVehicle.maxSpeed / 10).toFixed(1)}
                          </span>
                        </div>
                        <div className="single_line d-flex justify-content-between ">
                          <span className="left line_wrap">ACCELERATION</span>
                          <span className="right line_wrap">
                            {'I'.repeat((Math.floor(selectedVehicle.acceleration / 10)) * 2)}
                            {' '}
                            {(selectedVehicle.acceleration / 10).toFixed(1)}
                          </span>
                        </div>
                        <div className="single_line d-flex justify-content-between ">
                          <span className="left line_wrap">GRIP</span>
                          <span className="right line_wrap">
                            {'I'.repeat((Math.floor(selectedVehicle.grip / 10)) * 2)}
                            {' '}
                            {(selectedVehicle.grip / 10).toFixed(1)}
                          </span>
                        </div>
                        <div className="single_line d-flex justify-content-between ">
                          <span className="left line_wrap">BRAKING</span>
                          <span className="right line_wrap">
                            {'I'.repeat((Math.floor(selectedVehicle.braking / 10)) * 2)}
                            {' '}
                            {(selectedVehicle.braking / 10).toFixed(1)}
                          </span>
                        </div>
                        <div className="single_line d-flex justify-content-between ">
                          <span className="left line_wrap">HANDLING</span>
                          <span className="right line_wrap">
                            {'I'.repeat((Math.floor(selectedVehicle.handling / 10)) * 2)}
                            {' '}
                            {(selectedVehicle.handling / 10).toFixed(1)}
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="race_btn d-sm-block d-lg-none">
                      <a className="boxed_btn boxed_btn2" onClick={onClickNextStage}>RACE NOW!</a>
                    </div>
                  </div>
                  <div className="big_car_thumb">
                    <h4>Select Your Car</h4>
                    <div className="carouseL-warpper">
                      <div id="big" className="owl-carousel">
                        <div className="item">
                          <div className="thumb">
                            <img src={`/images/full_vehicle_body/${selectedVehicle.files[1].name}`} alt="" className="mob-non"/>
                            <Carousel
                              useKeyboardArrows
                              emulateTouch
                              showStatus={false}
                              showArrows
                              selectedItem={selectedIndex}
                              showThumbs={false}
                              showIndicators={false}
                              onChange={(e) => { onClickVehicle(vehicles[e].ethId); }}
                            >
                              {vehicles.map(vehicle => (
                                <img src={`/images/full_vehicle_body/${vehicle.files[0].name}`} alt="" className="pc-none" key={vehicle.ethId} />
                              ))}
                            </Carousel>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="race_btn d-none d-lg-block mt-5">
                      <button className="boxed_btn" onClick={onClickNextStage}>RACE NOW!</button>
                    </div>
                  </div>
                  <div className="single_content single_content-right d-none d-lg-block">
                    <div className="speed_thumb">
                      <div className="thumb">
                        <img src={`/game-images/tracks-preview/small-${selectedCircuit.name}.png`} alt={selectedCircuit.name}/>
                      </div>
                      <div className="speed_info">
                        <h4>{selectedCircuit.name}</h4>                  
                      </div>
                    </div>
                    <div className="starts_wrap">
                      <div className="bordered_title">
                        <h4>TRACK STATS</h4>
                      </div>
                      <div className="lines">
                        <div className="single_line d-flex justify-content-between">
                          <span className="left line_wrap">CIRCUIT LENGTH</span>
                          <span className="right line_wrap">{`${selectedCircuit.lapLength} KM`}</span>
                        </div>
                        <div className="single_line d-flex justify-content-between">
                          <span className="left line_wrap">MAX CARS</span>
                          <span className="right line_wrap">{selectedCircuit.maxVehicleNumber}</span>
                        </div>
                        <div className="single_line d-flex justify-content-between">
                          <span className="left line_wrap">LONGEST STRAIGHT LINE</span>
                          <span className="right line_wrap">{selectedCircuit.longestStraightLine}</span>
                        </div>
                        <div className="single_line d-flex justify-content-between">
                          <span className="left line_wrap">CURVES</span>
                          <span className="right line_wrap">{selectedCircuit.totalCurves}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="slider_controller_wrap d-none d-lg-block">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div id="thumbs" className="owl-carousel">

                <div className="item-carousel">
                  <ItemsCarousel
                    requestToChangeActive={setActiveItemIndex}                    
                    activePosition={'center'}
                    activeItemIndex={activeItemIndex}
                    numberOfCards={numberOfCards}
                    gutter={20}
                    leftChevron={<button className="left-arrow"></button>}
                    rightChevron={<button className="right-arrow"></button>}
                    outsideChevron
                    chevronWidth={chevronWidth}
                  >                                    
                    {vehicles.map( vehicle => (
                      <div className="item" onClick={() => onClickVehicle(vehicle.ethId)} key={vehicle.ethId}>
                        <div className="thumb_info">
                          <div className="img_thumb">
                            <img src={`/images/full_vehicle_body/${vehicle.files[0].name}`} alt={vehicle.model.name} width="116" />
                          </div>
                          <h4>
                            #
                            {vehicle.ethId.padStart(6, '0')}
                          </h4>
                        </div>
                      </div>
                    ))}
                  </ItemsCarousel>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

SelectCar.propTypes = {
  selectedVehicle: PropTypes.shape({
    ethId: PropTypes.string.isRequired,
    acceleration: PropTypes.number.isRequired,
    braking: PropTypes.number.isRequired,
    grip: PropTypes.number.isRequired,
    handling: PropTypes.number.isRequired,
    maxSpeed: PropTypes.number.isRequired,
    color: PropTypes.number.isRequired,
    model: PropTypes.shape({
      name: PropTypes.string.isRequired,
    }),
    files: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string.isRequired,
        type: PropTypes.number.isRequired,
      }),
    ),
  }).isRequired,
  vehicles: PropTypes.arrayOf(
    PropTypes.shape({
      ethId: PropTypes.string.isRequired,
      acceleration: PropTypes.number.isRequired,
      braking: PropTypes.number.isRequired,
      grip: PropTypes.number.isRequired,
      handling: PropTypes.number.isRequired,
      maxSpeed: PropTypes.number.isRequired,
      color: PropTypes.number.isRequired,
      files: PropTypes.arrayOf(
        PropTypes.shape({
          name: PropTypes.string.isRequired,
          type: PropTypes.number.isRequired,
        }),
      ),
    }),
  ).isRequired,
  loggedUserScore: PropTypes.shape({
    username: PropTypes.string.isRequired,
  }).isRequired,
  selectedCircuit: PropTypes.shape({
    name: PropTypes.string.isRequired,
  }).isRequired,

  onClickNextStage: PropTypes.func.isRequired,
  onClickVehicle: PropTypes.func.isRequired,
};

export default SelectCar;
