import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import './SplitSection.css';

const SplitSection = ({
  title, description, link, linkText, linkInImage, image, theme, inverted,
}) => (
  <div className={`split padding-vertical-large ${theme}${inverted ? ' inverted' : ''}`}>
    <div className="center">
      <div className="description-block">
        <p className="title">{title}</p>
        <hr />
        <p className="description">
          {description}
        </p>
        {linkText && <Link to={link} className="button secondary">{linkText}</Link>}
      </div>
      <div className="image-container">
        {linkInImage
          ? (
            <a target="_blank" rel="noopener noreferrer" href={link}>
              <img src={image} alt="car" />
            </a>
          )
          : <img src={image} alt="car" />
        }
      </div>
    </div>
  </div>
);

SplitSection.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  link: PropTypes.string,
  linkText: PropTypes.string,
  linkInImage: PropTypes.bool,
  image: PropTypes.string.isRequired,
  inverted: PropTypes.bool,
  theme: PropTypes.string,
};

SplitSection.defaultProps = {
  inverted: false,
  theme: '',
  link: '',
  linkText: '',
  linkInImage: false,
};

export default SplitSection;
