import React from 'react';

const authContext = React.createContext({
  authenticated: false,
  token: null,
  refreshToken: null,
  expiresAt: null,
  username: '',
  checkToken: () => {},
  login: () => {},
  logout: () => {},
  updateUsername: () => {},
});

export default authContext;
