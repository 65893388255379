const PARSED_URL = window.location.href.split("/");

let API_URL_TMP = "//api.cryptomotors.io";
let GAME_API_URL_TMP = "//bg.cryptomotors.io";
let DESIGN_IMAGE_URL_TMP = "//storage.googleapis.com/whitepaper";
if (PARSED_URL[2].indexOf("localhost") > -1) {
  API_URL_TMP = "//staging-api.cryptomotors.io";
  DESIGN_IMAGE_URL_TMP = "//localhost:8000";
  GAME_API_URL_TMP = "//staging-bg.cryptomotors.io";
} else if (PARSED_URL[2].indexOf("cm.nftstudios.io") > -1) {
  API_URL_TMP = "//staging-api.cryptomotors.io";
  DESIGN_IMAGE_URL_TMP = "//staging-api.cryptomotors.io";
  GAME_API_URL_TMP = "//staging-bg.cryptomotors.io";
} else if (PARSED_URL[2].indexOf("prod") > -1) {
  API_URL_TMP = "//prod-api.cryptomotors.io";
  DESIGN_IMAGE_URL_TMP = "//storage.googleapis.com/whitepaper";
  GAME_API_URL_TMP = "//bg.cryptomotors.io";
}

const VEHICLES_IMG_PATH = "/images/full_vehicle_body/";

const API_URL = API_URL_TMP;
const GAME_API_URL = GAME_API_URL_TMP;
const DESIGN_IMAGE_URL = DESIGN_IMAGE_URL_TMP;
const FB_CLIENT_ID = "1352092981594315";
const FB_REDIRECT_URL = `${PARSED_URL[0]}//${PARSED_URL[2]}/facebook`;
const FB_AUTH_URL = `https://www.facebook.com/v3.3/dialog/oauth?client_id=${FB_CLIENT_ID}&redirect_uri=${FB_REDIRECT_URL}&state=:state&scope[]=email`;

export const OPENSEA_API_KEY = "b3c20a09f96e4512983c5b7f9505743b";

export const METAMASK_MESSAGE =
  "Sign below to authenticate with CryptoMotors and get ready to race!";

export const CHALLENGE_UUID = "5eb6c12c-9989-454f-b026-24799def4095";

export {
  API_URL,
  FB_AUTH_URL,
  VEHICLES_IMG_PATH,
  DESIGN_IMAGE_URL,
  GAME_API_URL,
};
