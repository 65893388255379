import GameObject from "../../Engine/GameObject";
import CIRCUITS from "../Config/Circuits";
import { POSITION_EVENT } from "../Scenes/Race";
import { TAG_VEHICLE } from "./Vehicle";

export default class RaceManager extends GameObject {
  circuit = null;
  raceData = null;
  playerStats = null;
  vehicles = [];

  circuitLoaded = false;
  vehiclesLoaded = false;

  ready = false;
  finished = false;
  direction = 0;
  totalDistance = 0;
  positions = new Map();

  constructor(raceData) {
    super();

    // console.log(CIRCUITS[raceData.circuitUuid]);
    this.raceData = raceData;
    this.direction = CIRCUITS[raceData.circuitUuid].direction;
  }

  get laps() {
    return this.raceData.lapsNumber;
  }

  start() {
    this.vehicles = this.scene.getGameObjectsByTag(TAG_VEHICLE);
    this.circuit = this.scene.getGameObject("Circuit");
    this.playerStats = this.scene.getGameObject("PlayerStats");

    this.totalDistance = this.circuit.length * this.laps;
  }

  update() {
    this.updateGameObjectsLoadingStatus();
    this.ready = this.circuitLoaded && this.vehiclesLoaded;

    if (this.ready) {
      this.updatePositions();

      this.finished = this.vehicles.reduce(
        (prev, vehicle) => prev && vehicle.finished,
        true
      );
    }
  }

  updateGameObjectsLoadingStatus() {
    this.circuitLoaded =
      this.circuitLoaded === false ? this.circuit.loaded : this.circuitLoaded;
    this.vehiclesLoaded =
      this.vehiclesLoaded === false
        ? this.vehicles.reduce(
            (loaded, vehicle) => loaded && vehicle.loaded,
            true
          )
        : this.vehiclesLoaded;
  }

  updatePositions() {
    const sorted = this.vehicles
      .filter((vehicle) => vehicle.finished === false)
      .sort((v1, v2) => {
        if (v1.distanceTraveled < v2.distanceTraveled) return 1;
        if (v1.distanceTraveled > v2.distanceTraveled) return -1;
        return 0;
      });

    const basePosition = this.vehicles.length - sorted.length;
    sorted.forEach((vehicle, index) => {
      const position = basePosition + index + 1;

      this.positions.set(position, {
        position: position,
        username: vehicle.username,
        isPlayer: vehicle.isPlayer,
      });
    });

    window.dispatchEvent(
      new CustomEvent(POSITION_EVENT, { detail: this.positions })
    );

    this.playerStats.positions = this.positions;
  }
}
