import React from 'react';
import './Faq.css';

const Faq = () => (
  <div className="center faq">
    <h1>FAQs</h1>
    <details>
      <summary>
        What are CryptoMotors?
        <i className="fas fa-chevron-down" />
      </summary>
      <p>
        CryptoMotors are crypto-collectible digital cars made by professional automotive designers.
        When you buy a CryptoMotors, it becomes exclusively yours. You can collect it, trade it, or
        drive it in different games and platforms.
      </p>
    </details>
    <details>
      <summary>
        Why are CryptoMotors so special?
        <i className="fas fa-chevron-down" />
      </summary>
      <p>
        Every model has a limited amount of units. Once a model is completely sold, we won&apos;t
        produce any other unit of it. Since every car is unique, it becomes more valuable
        throughout time.⁣⁣ This principle is known as Digital Scarcity.
      </p>
    </details>
    <details>
      <summary>
        Is CryptoMotors a cryptocurrency like Bitcoin?
        <i className="fas fa-chevron-down" />
      </summary>
      <p>
        No. CryptoMotors are crypto-collectibles that can be used in different video games and
        platforms.
      </p>
    </details>
    <details>
      <summary>
        What does GEN0 mean?⁣⁣
        <i className="fas fa-chevron-down" />
      </summary>
      <p>
        GEN 0 (generation zero) is the first lineup of crypto-collectible vehicles created entirely
        by the CryptoMotors design team. Given that CryptoMotors is a decentralized car production
        platform, all the following generations of cars will be created in collaboration with the
        community.⁣⁣ Anyone who wants to take part in the process of building a car can do it.
      </p>
    </details>
    <details>
      <summary>
        If I buy a car, what can I do with it?⁣⁣⁣⁣
        <i className="fas fa-chevron-down" />
      </summary>
      <p>
        As you’d do with any other collectibles out there, you can collect or trade your
        CryptoMotors. Soon you will be able to drive them in a great variety of 2D, 3D and VR
        games and earn rewards while playing. At the same time, you will have access to the
        exclusive content of each car. ⁣
      </p>
    </details>
    <details>
      <summary>
        But how can the car be 100% my property?⁣⁣⁣⁣⁣⁣
        <i className="fas fa-chevron-down" />
      </summary>
      <p>
        Every CryptoMotors is a non-fungible token (NFT). Each vehicle is unique. It can’t
        be destroyed, replicated, or taken away. The owner is the only one that has access
        to its private key.
      </p>
    </details>
    <details>
      <summary>
        ⁣⁣⁣⁣What are the advantages of NFTs?⁣⁣
        <i className="fas fa-chevron-down" />
      </summary>
      <p>
        The two most significant advantages of NFTs are its immutability (nobody can destroy the
        asset) and its interoperability, meaning that players can use their items in different
        video games and platforms that follow the same development standard.
      </p>
    </details>
    <details className="last">
      <summary>
        Why should I consider buying a crypto-collectible car?⁣⁣⁣⁣
        <i className="fas fa-chevron-down" />
      </summary>
      <p>
        Our cars can be held or traded easily in our marketplace. In case that the vehicle model
        is featured in a game, movie or is physically produced, the token holders will be 
        rewarded with a percentage of the earnings generated by the vehicle during this time.
        Also you can enjoy your car in different games and platform!
      </p>
    </details>
    <h2>Getting started</h2>
    <details>
      <summary>
        What do I need to create an account in CryptoMotors?
        <i className="fas fa-chevron-down" />
      </summary>
      <p>
        To sign up go to cryptomotors.io. In order to buy or trade cars, you just need a valid
        email address and a user name.
      </p>
    </details>
    <details>
      <summary>
        Can I log into my account from different devices?
        <i className="fas fa-chevron-down" />
      </summary>
      <p>
        Yes, if you have created an account you can log into our website from any device, mobile 
        or desktop.
      </p>
    </details>
    <details>
      <summary>
        Can I use my CryptoMotors account on my phone?
        <i className="fas fa-chevron-down" />
      </summary>
      <p>
        Yes, you can. If you want to buy or trade cars from a mobile device you’ll need to install
         in your phone a digital wallet like Coinbase or Trust (or any other wallet that supports
         dapps and collectibles). If you already purchased a CryptoMotors from a desktop device,
         and you want to take a look at your cars from a mobile device, you can without any extra app.
      </p>
    </details>
    <details>
      <summary>
        Can I link a wallet with dapper? Or I need metamask?
        <i className="fas fa-chevron-down" />
      </summary>
      <p>
        No, right now you can not link a Dapper wallet. If you have Metamask and Dapper installed it won’t
        work propperly. You need to uninstall Dapper for now. We are working on a quick fix, that will 
        be available soon.
      </p>
    </details>
    <details>
      <summary>
        What else do I need to buy a car?
        <i className="fas fa-chevron-down" />
      </summary>
      <p>
        You need to have Ether in your digital wallet. We currently don’t accept USD/EUR/GBP/CAD
        as a payment method. You can buy Ether from a digital currency exchange like Coinbase.
        You must be careful never to lose access to your wallet. Keep in mind that it’s your own
        responsibility.
      </p>
    </details>
    <details>
      <summary>
        What is Ether (ETH)?
        <i className="fas fa-chevron-down" />
      </summary>
      <p>
        Ether is a digital currency. You need ETH to buy CryptoMotors and to use all the
        functions of our platform. If you live in Europe or North America, you can buy ETH
        straight away from your Metamask wallet or Coinbase wallet.
        <br />
        <br />
        If you can’t buy ETH from your Metamask, you will have to purchase ETH from a digital
        exchange and transfer them to your wallet.
      </p>
    </details>
    <details>
      <summary>
        How do I get my first car?
        <i className="fas fa-chevron-down" />
      </summary>
      <p>
        You can buy a car from us or from another user in our Marketplace. You can also buy our
        cars on external platforms like Opensea.
      </p>
    </details>
    <details>
      <summary>
        How many cars do I need to get started?
        <i className="fas fa-chevron-down" />
      </summary>
      <p>
        You can join our Design Challenges without having to purchase a car. However, you’ll need
        at least one car to play our upcoming racing games.
      </p>
    </details>
    <details>
      <summary>
        Where can I see my cars?
        <i className="fas fa-chevron-down" />
      </summary>
      <p>
        You can access and see all your cars at your Garage. To have your own Garage, you have to
        create an account on CryptoMotors.io. Your cars will also always be in your wallet as long
        as you have access to it.
      </p>
    </details>
    <details>
      <summary>
        How do I sell a car?
        <i className="fas fa-chevron-down" />
      </summary>
      <p>
        When selling a car you can choose between fixed price or auctions.
        <br />
        <br />
        When you place your car in auction sale, users can bid in your car. You can set a minimum 
        bid price. Because our integration with Opensea you don’t spend Gas to start a sale.  
      </p>
    </details>
    <details>
      <summary>
        I just sold a car, where’s my ETH?
        <i className="fas fa-chevron-down" />
      </summary>
      <p>
        Congratulations on your first sale! The Ethereum of the transaction will be deposited on
        your wallet. Transactions take some time, so you will have to wait a bit. Just check the
        status in Ether scan and when it displays “success” the transaction will be completed.
      </p>
    </details>
    <details>
      <summary>
        Is there any fee for selling a car?
        <i className="fas fa-chevron-down" />
      </summary>
      <p>
        CryptoMotors charges a 5% fee from every sale executed in Opensea. Opensea accounts
        for a 2.5% fee. 
      </p>
    </details>
    <details className="last">
      <summary>
        I lost access to my wallet. Can I recover my cars?
        <i className="fas fa-chevron-down" />
      </summary>
      <p>
        You’re responsible for taking care of your own private keys and seed phrases. Your wallet
        is your digital money bank. If you lose access to it, nobody would be able to unlock it
        for you. Your cars will exist forever, but you won’t be able to access them anymore.
        Be smart, save your keys!
      </p>
    </details>
    <h2>Design Challenges</h2>
    <details>
      <summary>
        Who can join design challenges?
        <i className="fas fa-chevron-down" />
      </summary>
      <p>
        Anyone who signs up to CryptoMotors.io can join our Design Challenges. Just remember that
        we always aim for high-quality productions.
      </p>
    </details>
    <details>
      <summary>
        How is CryptoMotors’ design process?
        <i className="fas fa-chevron-down" />
      </summary>
      <p>
        At CryptoMotors we have a three-step design process, including Sketching, PSD renderings,
        and 3D modeling. You can join any of our projects as a designer, or help us pick the best
        designs with your vote.
      </p>
    </details>
    <details>
      <summary>
        What are the steps of the design challenges?
        <i className="fas fa-chevron-down" />
      </summary>
      <p>
        Every challenge includes a design brief explaining in detail what’s the product
        participants will have to design. Designers who join the competition will submit
        their sketches for the pre-selection stage. We’ll review all the works and choose the
        participants that will go on to the second stage of the challenge. Finally, the 3D modeling
        and tokenization of the car will be done by the CryptoMotors team.
      </p>
    </details>
    <details className="last">
      <summary>
        What will I earn if I win a Design Challenge?
        <i className="fas fa-chevron-down" />
      </summary>
      <p>
        You will have the privilege of getting your design built by CryptoMotors professional 3D
        modelers. We&apos;ll tokenize the car model and put it on sale on our marketplace. You’ll
        receive one digital copy of the vehicle (token) and a percentage of the total sales of the
        model.
      </p>
    </details>
    <h2>Racing</h2>
    <details>
      <summary>
        Where can I use my car?
        <i className="fas fa-chevron-down" />
      </summary>
      <p>
        You will be able to use your car on our platform, games and applications. Also, thanks to
        the nature of NFTs, you will be able to drive your own crypto-collectible car in third
        party games and platforms.
      </p>
    </details>
    <details>
      <summary>
        What else can I do with my CryptoMotors?
        <i className="fas fa-chevron-down" />
      </summary>
      <p>
        We have a lot of plans for the cars! Competitive races, 3D/VR visualization, customization,
        integration with third-party racing games and more.
        <br />
        <br />
        We’ll also offer awesome 3D printed cars very soon! And a lot of exclusive content for you
        to enjoy your car every day.
      </p>
    </details>
    <details>
      <summary>
        When will I be able to race my car?
        <i className="fas fa-chevron-down" />
      </summary>
      <p>
        Soon we plan to release the beta version of our decentralized car racing game.
        Stay tuned for news!
      </p>
    </details>
    <details>
      <summary>
        How much will it cost to join a race?
        <i className="fas fa-chevron-down" />
      </summary>
      <p>
        We want to make races accessible to anyone! So, during the beta, you can expect no charges
        for racing in the arcade mode, but, as in every dapp, there&apos;s always a network fee to
        be paid.
      </p>
    </details>
    <details className="last">
      <summary>
        What can I win by racing?
        <i className="fas fa-chevron-down" />
      </summary>
      <p>
        We plan to offer different rewards. There will be ETH, special packages, cars and more!
      </p>
    </details>
    <h2>Tuning</h2>
    <details>
      <summary>
        Will I be able to customize my car?
        <i className="fas fa-chevron-down" />
      </summary>
      <p>
        Yes, before starting a race, you will be able to customize your car in your Garage.
      </p>
    </details>
    <details>
      <summary>
        Can I buy parts for my car?
        <i className="fas fa-chevron-down" />
      </summary>
      <p>
        Yes. You’ll be able to buy auto parts in our marketplace and in other crypto-collectible
        exchanges.
      </p>
    </details>
    <details className="last">
      <summary>
        How much will it cost to buy a part?
        <i className="fas fa-chevron-down" />
      </summary>
      <p>
        It will depend on the type of part. Like cars, parts will also have a limited amount of
        units. So, some parts will be rarer than others and, hence, more expensive.
      </p>
    </details>
    <h2>Transactions and Technical Questions</h2>
    <details>
      <summary>
        What&apos;s Etherscan?
        <i className="fas fa-chevron-down" />
      </summary>
      <p>
        Etherscan is the interface that reads all the transactions happening in the Ethereum
        network. It allows you to verify if transactions were successful.
      </p>
    </details>
    <details>
      <summary>
        My transaction failed, why?
        <i className="fas fa-chevron-down" />
      </summary>
      <p>
        Transactions may fail for various reasons: Another user may have purchased the last
        available unit of a car before you. The price of the car might have had increased before
        you had the chance of bidding in the auction, or you may have set up the gas limit too low.
      </p>
    </details>
    <details className="last">
      <summary>
        What is ‘Gas’?
        <i className="fas fa-chevron-down" />
      </summary>
      <p>
        ‘Gas’ is the cost of powering the Ethereum blockchain where CryptoMotors are built on.
        In the Ethereum blockchain, every transaction is distributed across different computers
        around the world, without a central server or authority. This is the reason why each
        crypto-collectible is unique and completely secure. It takes a lot of computational power
        to validate each transaction, which is paid with Gas.
        <br />
        <br />
        There is a Gas Price and a Gas Limit. Gas Price is what you’re willing to pay to the miners
        for every operation. Gas Limit is the number of operations the miners are allowed to
        execute before they run out of gas and abandon the transaction.
        <br />
        <br />
        A “Gwei” is a small measurement of ETH that is paid to the miners. 1
        Gwei = 1/1,000,000,000th of an Ether.
      </p>
    </details>
    <h2>Community</h2>
    <details>
      <summary>
        How can I join the CryptoMotors community?
        <i className="fas fa-chevron-down" />
      </summary>
      <p>
        You can join our Telegram chat, Telegram channel and Discord where we generally share
        ideas, updates and exclusive content.
      </p>
    </details>
    <details>
      <summary>
        How can I contact the CryptoMotors team?
        <i className="fas fa-chevron-down" />
      </summary>
      <p>
        If you have any questions please send us your inquiries to info@cryptomotors.io
        You can also join our Telegram and Discord channels where we will be happy to assist you.
      </p>
    </details>
    <details className="last">
      <summary>
        Where can I find the latest updates?
        <i className="fas fa-chevron-down" />
      </summary>
      <p>
        We will keep you updated through our newsletter, Instagram, Discord and other channels!
      </p>
    </details>
  </div>
);

export default Faq;
