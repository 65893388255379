import React, { Component } from 'react';
import axios from 'axios';
import { API_URL } from '../../config/Config';
import './RecoverPassword.css';

class RecoverPassword extends Component {
  state = {
    isLoading: false,
    errorMessage: '',
    isSuccess: false,
  };

  sendRecoverPasswordRequest = (e) => {
    e.preventDefault();
    if (this.state.isLoading) {
      return;
    }

    this.setState({ isLoading: true });

    const email = e.target.email.value;

    axios({
      method: 'POST',
      url: `${API_URL}/password-recovery/mail`,
      data: {
        email,
      },
    })
      .then(() => this.setState({
        isLoading: false,
        isSuccess: true,
      }))
      .catch((error) => {
        this.setState({
          errorMessage: error.response.data,
          isLoading: false,
        });
      });
  };

  render() {
    const { errorMessage, isLoading, isSuccess } = this.state;

    return (
      <div className="recover-password" onSubmit={this.sendRecoverPasswordRequest}>
        <div>
          <h1>Forgot your password?</h1>
          <hr />
          {!isSuccess ? (
            <div>
              <p>
          Don&apos;t worry. Resetting your password is easy, just tell us the email address
          you have used to register.
              </p>
              <form>
                <div>
                  <input type="email" name="email" placeholder="Email Address" required />
                </div>
                <div className="error-message">
                  { errorMessage }
                </div>

                <div>
                  <button type="submit" className="primary">
                    {isLoading
                      ? <i className="fas fa-spinner fa-spin" />
                      : 'Send'
                    }
                  </button>
                </div>
              </form>
            </div>
          )
            : (
              <p>
                An email has been sent to your address. Please follow the provided instructions to
                reset your password.
              </p>
            )}
        </div>
      </div>
    );
  }
}

export default RecoverPassword;
