import React, {useEffect, useRef, useContext} from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { PlayAudioContext } from '../../context/PlayAudioContext';
import {
  ABOUT, FAQ, HOME, IMPRESSUM, MARKET, PRIVACY_POLICY, TERMS_OF_USE, VISION, GAME, GAME_SUMMARY
} from '../../config/Routes';
import './Footer.css';

const Footer = ({ pathname }) => {

  const GAMESTAT_MUSIC = "/game-stats/music/game-music.mpeg";
  const audioRef = useRef();   
  const {playpause} = useContext(PlayAudioContext);   

  useEffect(() => {    
    const audio  = audioRef.current;
    
    if(pathname === GAME || pathname.includes(GAME_SUMMARY.replace('/:uuid', ''))){        
        startMusic(audio);    
      if(playpause){
        audio.play();        
      }else{
        audio.pause();
      }
    }else{
      stopMusic(audio);
    }
    
  }, [playpause, pathname]);

  const startMusic = audio => {
    audio.play();
    audio.volume = 0.1;
  }

  const stopMusic = audio => {
    audio.pause();
    audio.currentTime = 0;
  }

  return (
    <div className="center footer-desktop">
      <div className="isologotype">
        <img src="/images/isologotype.svg" alt="isologo" />
      </div>
      <div className="links-left">
        <ul>
          <li>
            <Link
              to={HOME}
              className={pathname === HOME ? 'active' : ''}
            >
              Home
            </Link>
          </li>
          <li>
            <Link
              to={MARKET}
              className={pathname.includes('/market/') ? 'active' : ''}
            >
              Market
            </Link>
          </li>
          <li>
            <Link
              to={ABOUT}
              className={pathname === ABOUT ? 'active' : ''}
            >
              About
            </Link>
          </li>
          <li>
            <Link
              to={VISION}
              className={pathname === VISION ? 'active' : ''}
            >
              Vision
            </Link>
          </li>
          <li>
            <a
              href="https://storage.googleapis.com/whitepaper/whitepaper.pdf"
              target="_blank"
              rel="noopener noreferrer"
            >
              Whitepaper
            </a>
          </li>
        </ul>
      </div>
      <div className="links-right">
        <ul>
          <li>
            <Link
              to={IMPRESSUM}
              className={pathname === IMPRESSUM ? 'active' : ''}
            >
              Impressum
            </Link>
          </li>
          <li>
            <Link
              to={TERMS_OF_USE}
              className={pathname === TERMS_OF_USE ? 'active' : ''}
            >
              Terms of use
            </Link>
          </li>
          <li>
            <Link
              to={PRIVACY_POLICY}
              className={pathname === PRIVACY_POLICY ? 'active' : ''}
            >
              Privacy policy
            </Link>
          </li>
          <li>
            <Link
              to={FAQ}
              className={pathname === FAQ ? 'active' : ''}
            >
              Faq
            </Link>
          </li>
        </ul>
      </div>
      <div className="copyright">
        Copyright © 2019 CryptoMotors
        <br />
        UG (haftungsbeschränkt). All rights reserved.
      </div>
      <div className="social">
        <a href="//www.instagram.com/CryptoMotors/" target="_blank" rel="noopener noreferrer"><i className="fab fa-instagram" /></a>
        <a href="//twitter.com/cryptomotors_io" target="_blank" rel="noopener noreferrer"><i className="fab fa-twitter" /></a>
        <a href="//www.facebook.com/CryptoMotors/" target="_blank" rel="noopener noreferrer"><i className="fab fa-facebook-f" /></a>
        <a href="//t.me/cryptomotors" target="_blank" rel="noopener noreferrer"><i className="fab fa-telegram-plane" /></a>
      </div>
      <div className="game_stat_music_container">
        <audio controls loop ref={audioRef}>            
          <source src={GAMESTAT_MUSIC} type="audio/mpeg"/>
        </audio>
      </div>
    </div>
  )
};

Footer.propTypes = {
  pathname: PropTypes.string.isRequired,
};

export default Footer;
