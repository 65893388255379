import React, { Component } from "react";
import { bufferToHex } from "ethereumjs-util";
import { Redirect } from "react-router";
import "./LinkWallet.css";
import { METAMASK_MESSAGE } from "../../config/Config";
import NoMetamask from "./NoMetamask";
import NoAddress from "./NoAddress";
import NoMainNetwork from "./NoMainNetwork";
import { getWallet, linkWallet } from "../../requests/Wallet";
import WalletTaken from "./WalletTaken";
import AuthContext from "../../context/AuthContext";
import WalletLinked from "./WalletLinked";
import { HOME } from "../../config/Routes";
import Web3 from "web3";

const SIGN_MESSAGE =
  "Your signature has been requested, please sign to proceed.";
const SIGN_CANCEL_MESSAGE =
  'You did not sign the message, if you change your mind click "Link" again.';

class LinkWallet extends Component {
  static contextType = AuthContext;

  state = {
    hasMetamask: true,
    isMainNetwork: true,
    isLoading: false,
    currentAddress: null,
    isCurrentAddressTaken: false,
    message: "",
    isSignatureRequested: false,
    success: false,
  };

  async componentDidMount() {
    if (!window.ethereum) {
      this.setState({ hasMetamask: false });

      return;
    }

    const provider = window.ethereum;
    const web3 = new Web3(provider);
    console.log(
      web3.eth.net.getId((err, netId) => {
        if (netId !== 1) {
          this.setState({ isMainNetwork: false });
        }
      })
    );

    provider.enable();

    const accounts = await web3.eth.getAccounts();

    if (accounts[0]) {
      this.setState({ currentAddress: accounts[0] }, this.checkAddressRequest);
    }

    provider.on("accountsChanged", (accounts) => {
      if (!accounts[0]) {
        this.setState({ currentAddress: null });

        return;
      }

      this.setState({ currentAddress: accounts[0] }, this.checkAddressRequest);
    });

    provider.on("networkChanged", (netId) => {
      this.setState({ isMainNetwork: parseInt(netId) === 1 });
    });

    provider.on("chainChanged", (netId) => {
      this.setState({ isMainNetwork: parseInt(netId) === 1 });
    });
  }

  linkWalletHandler = () => {
    if (this.state.isSignatureRequested) {
      return;
    }

    this.setState({
      message: SIGN_MESSAGE,
      isSignatureRequested: true,
    });

    window.ethereum.sendAsync(
      {
        method: "personal_sign",
        params: [
          bufferToHex(Buffer.from(METAMASK_MESSAGE, "utf8")),
          this.state.currentAddress,
        ],
        from: this.state.currentAddress,
      },
      (error, result) => {
        if (error) {
          this.setState({ message: SIGN_CANCEL_MESSAGE });

          return;
        }

        if (result.error) {
          this.setState({ message: SIGN_CANCEL_MESSAGE });
        }

        this.setState({ isSignatureRequested: false });
        if (result.result) {
          this.setState({ message: null });
          this.linkRequest(result.result);
        }
      }
    );
  };

  checkAddressRequest = () => {
    this.setState({ isLoading: true });
    getWallet(this.state.currentAddress)
      .then(({ data: { linked } }) => {
        this.setState({ isCurrentAddressTaken: linked, isLoading: false });
      })
      .catch(({ response }) =>
        this.setState({
          isCurrentAddressTaken: response.status !== 404,
          isLoading: false,
        })
      );
  };

  linkRequest = (signedMessage) => {
    this.setState({ isLoading: true });
    const { token } = this.context;
    const { currentAddress } = this.state;

    linkWallet(token, currentAddress, signedMessage)
      .then(({ data: { address } }) =>
        this.setState({
          success: address === currentAddress,
          isLoading: false,
        })
      )
      .catch(() =>
        this.setState({
          isLoading: false,
        })
      );
  };

  render() {
    const { authenticated } = this.context;
    if (!authenticated) {
      return <Redirect to={HOME} />;
    }

    const {
      currentAddress,
      hasMetamask,
      isLoading,
      isMainNetwork,
      message,
      isCurrentAddressTaken,
      success,
    } = this.state;

    if (isLoading) {
      return <i className="fas fa-spinner fa-spin standalone-loading" />;
    }

    if (success) {
      return <WalletLinked address={currentAddress} />;
    }

    if (!hasMetamask) {
      return <NoMetamask />;
    }

    if (!currentAddress) {
      return <NoAddress />;
    }

    if (!isMainNetwork) {
      return <NoMainNetwork />;
    }

    if (isCurrentAddressTaken) {
      return <WalletTaken address={currentAddress} />;
    }

    return (
      <div className="link-wallet">
        <div className="center">
          <h1>Link wallet</h1>
          <hr className="gray double" />
          You are about to link to your account the address:{" "}
          <div className="bold">{currentAddress}</div>
          <p>
            We need to verify that this address belongs to you. Please sign the
            message with your MetaMask once you click &quot;Link&quot;.
          </p>
          <p>
            <span className="bold">
              Attention: This action can not be undone.
            </span>{" "}
            Once you link a wallet to an account it will be{" "}
            <span className="bold">permanent</span>.
          </p>
          {message && <p>{message}</p>}
          <button
            type="button"
            className="primary"
            onClick={this.linkWalletHandler}
          >
            Link
          </button>
        </div>
      </div>
    );
  }
}

export default LinkWallet;
