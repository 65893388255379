export const fakeTracks = [    
      {
        uuid: "icon-4",
        name: "Airlands",
        description: "",
        difficulty: "BEGINNER",
        lapsNumber: 4,
        length: 2.48,
        maxVehicleNumber: 8,
        fastestLap: "",
        longestStraightLine: 4.65,
        curves: 8,
        locked: true,
        features: {
            acell: 4,
            speed: 3,
            handling: 5,
            grip: 4,
            breaking: 4
        }
      },
      {
        uuid: "icon-5",
        name: "Green Spring",
        description: "",
        difficulty: "INTERMEDIAT",
        lapsNumber: 4,
        length: 2.48,
        maxVehicleNumber: 8,
        fastestLap: "",
        longestStraightLine: 4.65,
        curves: 8,
        locked: true,
        features: {
            acell: 4,
            speed: 3,
            handling: 5,
            grip: 4,
            breaking: 4
        }
      },
      {
        uuid: "icon-6",
        name: "Cyberspeed",
        description: "",
        difficulty: "EXPERT",
        lapsNumber: 4,
        length: 2.48,
        maxVehicleNumber: 8,
        fastestLap: "",
        longestStraightLine: 4.65,
        curves: 8,
        locked: true,
        features: {
            acell: 4,
            speed: 3,
            handling: 5,
            grip: 4,
            breaking: 4
        }
      },
      {
        uuid: "icon-7",
        name: "NightFall",
        description: "",
        difficulty: "BEGINNER",
        lapsNumber: 4,
        length: 2.48,
        maxVehicleNumber: 8,
        fastestLap: "",
        longestStraightLine: 4.65,
        curves: 8,
        locked: true,
        features: {
            acell: 4,
            speed: 3,
            handling: 5,
            grip: 4,
            breaking: 4
        }
      },
      {
        uuid: "icon-8",
        name: "Mauvestone",
        description: "",
        difficulty: "INTERMEDIAT",
        lapsNumber: 4,
        length: 2.48,
        maxVehicleNumber: 8,
        fastestLap: "",
        longestStraightLine: 4.65,
        curves: 8,
        locked: true,
        features: {
            acell: 4,
            speed: 3,
            handling: 5,
            grip: 4,
            breaking: 4
        }
      },
      {
        uuid: "icon-9",
        name: "Marble coast",
        description: "",
        difficulty: "EXPERT",
        lapsNumber: 4,
        length: 2.48,
        maxVehicleNumber: 8,
        fastestLap: "",
        longestStraightLine: 4.65,
        curves: 8,
        locked: true,
        features: {
            acell: 4,
            speed: 3,
            handling: 5,
            grip: 4,
            breaking: 4
        }
      },
];