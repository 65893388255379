import React from 'react';
import Member from './Member';
import MEMBERS from './ListMembers';

const Team = () => (
  <div className="our-team">
    <div className="team-title">
      <h2>Our Team</h2>
    </div>
    <div className="members">
      {
        MEMBERS.map(member => ( 
          <Member 
            key={member.name}
            name={member.name}
            image={member.image}
            title={member.title}
          />
        ))
      }
    </div>
  </div>
);

export default Team;
