import React, { createContext, useState } from 'react';

export const PlayAudioContext = createContext();

const PlayAudioProvider = (props) => {
    
    const [playpause, setPlayPause] = useState(true);        
    
    const playStopMusic = () => {            
        setPlayPause(!playpause);                
    }

    return (
        <PlayAudioContext.Provider
            value={{
                playpause,                
                playStopMusic            
            }}
        >
            {props.children}
        </PlayAudioContext.Provider>
    )
}

export default PlayAudioProvider;