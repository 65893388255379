import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { HOME } from '../../config/Routes';
import './ConfirmEmail.css';
import axios from 'axios';
import { API_URL } from '../../config/Config';

const confirmEmailCall = (uuid) => {
  axios({
    method: 'GET',
    url: `${API_URL}/users/confirm-email/${uuid}`,
  });
};

const ConfirmEmail = ({ match: { params: { uuid } } }) => {
  confirmEmailCall(uuid);

  return (
    <div className="confirm-email">
      <i className="fas fa-check-circle" />
      <h1>Welcome to CryptoMotors</h1>
      <p>Thank you, your email address is confirmed.</p>
      <Link className="button primary" to={HOME}>Go to home</Link>
    </div>
  );
};

ConfirmEmail.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({ uuid: PropTypes.string.isRequired }).isRequired,
  }).isRequired,
};

export default ConfirmEmail;
