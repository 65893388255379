const CIRCUITS = {
  "f046d645-93be-43b0-8a4b-ce1cfc6abb26": {
    image: "/game-stats/sunpeak.png",
    spots: [
      { x: -38, y: -155 },
      { x: -225, y: -135 },
      { x: -264, y: -131 },
      { x: -310, y: -129 },
      { x: -343, y: -123 },
      { x: -374, y: -114 },
      { x: -380, y: -96 },
      { x: -369, y: -84 },
      { x: -344, y: -79 },
      { x: -172, y: -80 },
      { x: -126, y: -81 },
      { x: -83, y: -82 },
      { x: -46, y: -68 },
      { x: -37, y: -54 },
      { x: -46, y: -39 },
      { x: -70, y: -31 },
      { x: -108, y: -27 },
      { x: -157, y: -29 },
      { x: -222, y: -23 },
      { x: -252, y: -14 },
      { x: -271, y: 10 },
      { x: -263, y: 28 },
      { x: -233, y: 52 },
      { x: -192, y: 96 },
      { x: -167, y: 111 },
      { x: -143, y: 113 },
      { x: -118, y: 100 },
      { x: 4, y: 17 },
      { x: 26, y: 15 },
      { x: 54, y: 27 },
      { x: 78, y: 53 },
      { x: 88, y: 83 },
      { x: 90, y: 131 },
      { x: 111, y: 160 },
      { x: 141, y: 171 },
      { x: 172, y: 174 },
      { x: 258, y: 171 },
      { x: 302, y: 160 },
      { x: 316, y: 135 },
      { x: 308, y: 95 },
      { x: 234, y: -94 },
      { x: 206, y: -135 },
      { x: 168, y: -163 },
      { x: 127, y: -173 },
      { x: 93, y: -173 },
    ],
    deltaTime: 9,
    direction: 1, // clockwise
  },
  "e010316c-13dd-4b9e-be64-99153a9bc4f5": {
    image: "/game-stats/breachfall.png",
    spots: [
      { x: -17, y: 163 },
      { x: -87, y: 163 },
      { x: -173, y: 170 },
      { x: -217, y: 162 },
      { x: -229, y: 151 },
      { x: -238, y: 122 },
      { x: -243, y: 59 },
      { x: -253, y: 21 },
      { x: -269, y: -8 },
      { x: -291, y: -29 },
      { x: -325, y: -47 },
      { x: -365, y: -75 },
      { x: -373, y: -89 },
      { x: -375, y: -107 },
      { x: -370, y: -130 },
      { x: -358, y: -155 },
      { x: -341, y: -176 },
      { x: -318, y: -193 },
      { x: -293, y: -202 },
      { x: -267, y: -202 },
      { x: -238, y: -188 },
      { x: -197, y: -155 },
      { x: -169, y: -140 },
      { x: -154, y: -137 },
      { x: -142, y: -138 },
      { x: -126, y: -145 },
      { x: -96, y: -166 },
      { x: -82, y: -173 },
      { x: -73, y: -169 },
      { x: -66, y: -159 },
      { x: -70, y: -137 },
      { x: -132, y: -10 },
      { x: -150, y: 25 },
      { x: -169, y: 57 },
      { x: -168, y: 78 },
      { x: -157, y: 91 },
      { x: -140, y: 93 },
      { x: -126, y: 88 },
      { x: -113, y: 69 },
      { x: -104, y: 42 },
      { x: -84, y: 7 },
      { x: 35, y: -188 },
      { x: 43, y: -198 },
      { x: 54, y: -203 },
      { x: 76, y: -205 },
      { x: 114, y: -205 },
      { x: 163, y: -207 },
      { x: 210, y: -207 },
      { x: 259, y: -205 },
      { x: 276, y: -193 },
      { x: 282, y: -176 },
      { x: 282, y: -143 },
      { x: 273, y: -114 },
      { x: 261, y: -85 },
      { x: 244, y: -49 },
      { x: 216, y: -15 },
      { x: 177, y: 6 },
      { x: 59, y: 45 },
      { x: 48, y: 57 },
      { x: 48, y: 69 },
      { x: 55, y: 81 },
      { x: 72, y: 83 },
      { x: 93, y: 78 },
      { x: 246, y: 21 },
      { x: 276, y: 21 },
      { x: 306, y: 30 },
      { x: 324, y: 47 },
      { x: 335, y: 71 },
      { x: 333, y: 97 },
      { x: 326, y: 117 },
      { x: 312, y: 138 },
      { x: 288, y: 160 },
      { x: 261, y: 177 },
      { x: 225, y: 186 },
      { x: 196, y: 186 },
      { x: 117, y: 165 },
      { x: 78, y: 162 },
      { x: 30, y: 163 },
    ],
    deltaTime: 8,
    direction: -1, // counter-clockwise
  },
  "438cbfed-7a3c-4eea-b3ed-823a6aed97f3": {
    image: "/game-stats/mudlands.png",
    spots: [
      { x: -45, y: -159 },
      { x: -176, y: -159 },
      { x: -220, y: -157 },
      { x: -231, y: -156 },
      { x: -238, y: -155 },
      { x: -249, y: -153 },
      { x: -273, y: -145 },
      { x: -301, y: -135 },
      { x: -319, y: -124 },
      { x: -333, y: -117 },
      { x: -345, y: -108 },
      { x: -357, y: -99 },
      { x: -367, y: -88 },
      { x: -376, y: -77 },
      { x: -381, y: -64 },
      { x: -382, y: -47 },
      { x: -381, y: -37 },
      { x: -378, y: -31 },
      { x: -370, y: -24 },
      { x: -363, y: -18 },
      { x: -354, y: -16 },
      { x: -343, y: -18 },
      { x: -328, y: -24 },
      { x: -309, y: -37 },
      { x: -292, y: -51 },
      { x: -276, y: -63 },
      { x: -265, y: -67 },
      { x: -255, y: -69 },
      { x: -247, y: -64 },
      { x: -238, y: -55 },
      { x: -231, y: -42 },
      { x: -226, y: -30 },
      { x: -223, y: -11 },
      { x: -216, y: 15 },
      { x: -213, y: 25 },
      { x: -206, y: 33 },
      { x: -195, y: 44 },
      { x: -188, y: 56 },
      { x: -178, y: 75 },
      { x: -172, y: 92 },
      { x: -165, y: 105 },
      { x: -160, y: 119 },
      { x: -153, y: 127 },
      { x: -145, y: 127 },
      { x: -134, y: 123 },
      { x: -124, y: 117 },
      { x: -114, y: 110 },
      { x: -104, y: 103 },
      { x: -94, y: 99 },
      { x: -87, y: 102 },
      { x: -79, y: 107 },
      { x: -70, y: 115 },
      { x: -60, y: 133 },
      { x: -54, y: 144 },
      { x: -46, y: 150 },
      { x: -37, y: 151 },
      { x: -25, y: 145 },
      { x: -16, y: 134 },
      { x: -10, y: 122 },
      { x: -4, y: 104 },
      { x: -1, y: 89 },
      { x: 8, y: 78 },
      { x: 16, y: 74 },
      { x: 24, y: 77 },
      { x: 34, y: 85 },
      { x: 39, y: 101 },
      { x: 42, y: 119 },
      { x: 44, y: 139 },
      { x: 47, y: 147 },
      { x: 54, y: 156 },
      { x: 60, y: 158 },
      { x: 69, y: 153 },
      { x: 75, y: 144 },
      { x: 81, y: 123 },
      { x: 89, y: 85 },
      { x: 100, y: 71 },
      { x: 113, y: 62 },
      { x: 130, y: 62 },
      { x: 147, y: 68 },
      { x: 164, y: 80 },
      { x: 184, y: 96 },
      { x: 200, y: 104 },
      { x: 218, y: 108 },
      { x: 234, y: 108 },
      { x: 251, y: 101 },
      { x: 274, y: 90 },
      { x: 303, y: 68 },
      { x: 340, y: 43 },
      { x: 360, y: 24 },
      { x: 370, y: 6 },
      { x: 371, y: -4 },
      { x: 371, y: -27 },
      { x: 370, y: -43 },
      { x: 365, y: -58 },
      { x: 355, y: -65 },
      { x: 341, y: -71 },
      { x: 327, y: -72 },
      { x: 315, y: -67 },
      { x: 305, y: -54 },
      { x: 298, y: -34 },
      { x: 284, y: -12 },
      { x: 271, y: 2 },
      { x: 257, y: 12 },
      { x: 242, y: 18 },
      { x: 227, y: 14 },
      { x: 219, y: 8 },
      { x: 209, y: -6 },
      { x: 201, y: -23 },
      { x: 195, y: -43 },
      { x: 189, y: -69 },
      { x: 190, y: -88 },
      { x: 191, y: -100 },
      { x: 191, y: -114 },
      { x: 190, y: -123 },
      { x: 184, y: -131 },
      { x: 173, y: -136 },
      { x: 159, y: -141 },
      { x: 140, y: -148 },
      { x: 119, y: -151 },
      { x: 97, y: -153 },
      { x: 76, y: -154 },
      { x: 47, y: -155 },
      { x: 4, y: -156 },
    ],
    deltaTime: 8,
    direction: 1,
  },
};

export default CIRCUITS;
