import React from 'react';
import PropTypes from 'prop-types';

const LinkWalletLayout = ({ children }) => (
  <div className="link-wallet">
    <div className="center">
      <h1>Link wallet</h1>
      {children}
    </div>
  </div>
);

LinkWalletLayout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default LinkWalletLayout;
