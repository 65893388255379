import React, { useContext } from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import {
  ABOUT,
  DESIGN_CHALLENGE_DASHBOARD,
  GARAGE,
  HOME,
  MARKET,
  SETTINGS,
  LOG_IN,
  GAME,
  GAME_SCORE,
  GAME_SUMMARY,
} from "../../config/Routes";
import AuthContext from "../../context/AuthContext";
import { PlayAudioContext } from "../../context/PlayAudioContext";
import "./Menu.css";
import "../Game/css/bootstrap.min.css";

const Menu = ({ pathname }) => {
  const authContext = useContext(AuthContext);

  const { playpause, playStopMusic } = useContext(PlayAudioContext);

  const handlePlayStopMusic = () => {
    playStopMusic();
  };

  return (
    <nav className="center desktop-menu">
      <div className="logo">
        <Link to={HOME}>
          <img src="/images/isologotype.svg" alt="isologo" />
        </Link>
      </div>
      <div className="menu">
        <ul>
          <li>
            <Link to={HOME} className={pathname === HOME ? "active" : ""}>
              Home
            </Link>
          </li>
          <li>
            <Link
              to={MARKET}
              className={pathname.includes("/market/") ? "active" : ""}
            >
              Market
            </Link>
          </li>
          {/* <li>
            <Link
              to={VISION}
              className={pathname === VISION ? 'active' : ''}
            >
            Vision
            </Link>
          </li> */}
          <li>
            <Link
              to={DESIGN_CHALLENGE_DASHBOARD}
              className={pathname.includes("/design-challenge") ? "active" : ""}
            >
              Challenge
            </Link>
          </li>
          {authContext.authenticated && (
            <li>
              <Link to={GAME} className={pathname === GAME ? "active" : ""}>
                Stat Game
              </Link>
            </li>
          )}
          <li>
            <Link
              to={GAME_SCORE}
              className={pathname === GAME_SCORE ? "active" : ""}
            >
              Leaderboard
            </Link>
          </li>
          <li>
            <Link to={ABOUT} className={pathname === ABOUT ? "active" : ""}>
              About
            </Link>
          </li>
        </ul>
      </div>
      <div className="sign-in-header">
        {pathname === GAME ||
        pathname.includes(GAME_SUMMARY.replace("/:uuid", "")) ? (
          <div className="game_playpause_container">
            <span
              className={
                playpause
                  ? "fa fa-pause-circle fa-2x d-lg-block d-none controls"
                  : "fa fa-play-circle fa-2x d-lg-block d-none controls"
              }
              onClick={() => handlePlayStopMusic()}
            />
          </div>
        ) : null}
        {!authContext.authenticated ? (
          <Link to={LOG_IN} className="button secondary">
            Login
          </Link>
        ) : (
          <div>
            <Link
              to={GARAGE.replace(":user", authContext.username)}
              className={pathname.includes("/garage/") ? "active" : ""}
            >
              Garage
            </Link>
          </div>
        )}
      </div>
      {authContext.authenticated && (
        <div className="avatar">
          <Link to={SETTINGS}>
            <img src="/images/avatar.png" alt="avatar" />
          </Link>
        </div>
      )}
    </nav>
  );
};

Menu.propTypes = {
  pathname: PropTypes.string.isRequired,
};

export default Menu;
