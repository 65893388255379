import React from 'react';
import './About.css';
import Contact from './Contact';
import Team from './Team';

const About = () => (
  <div className="about">
    <div className="leandro">
      <div>
        <div className="quote">
          <h1>Leandro Bellone, CEO</h1>
          <hr />
          <p>
            „We want to give designers from all around the world the possibility of showing
            their talent without any physical or creative limitation. We want them to take part in
            the exciting process of designing a car for the digital world and get rewarded for
            it.“
          </p>
        </div>
        <div className="image">
          <img alt="leandro" src="/images/leandro.png" />
        </div>
      </div>
    </div>
    <div className="whitepaper">
      <div>
        <div className="image">
          <img alt="whitepaper" src="/images/about/whitepaper.jp2" />
        </div>
        <div className="quote">
          <h1>Rethinking the way we create and enjoy cars</h1>
          <hr />
          <p>
            CryptoMotors is revolutionizing the way vehicles are made by giving professional
            designers and enthusiasts from all around the world the opportunity of taking part in
            the amazing journey of building a car. Here’s how the first decentralized digital
            automaker powered by the Ethereum blockchain works.
          </p>
          <div>
            <a
              className="button secondary"
              href="https://storage.googleapis.com/whitepaper/whitepaper.pdf"
              target="_blank"
              rel="noopener noreferrer"
            >
              White Paper
            </a>
          </div>
        </div>
      </div>
    </div>
    <div>
      <p>

      </p>
    </div>
    <Team />
    <Contact />
  </div>
);

export default About;
