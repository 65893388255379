import React, { Component } from "react";
import PropTypes from "prop-types";
import axios from "axios";
import SplitSection from "../SplitSection/SplitSection";
import "./ViewCar.css";
import MODELS from "../../config/Models";
import StatBar from "../StatBar/StatBar";
import Borrow from "../Garage/Borrow";
import EventModal from "../EventModal";
import { useToasts } from "react-toast-notifications";

import "../../../node_modules/react-responsive-carousel/lib/styles/carousel.css";

import { API_URL, VEHICLES_IMG_PATH } from "../../config/Config";
import { GIFT_CAR, OPENSEA_VIEW_CAR, SELL_CAR } from "../../config/Routes";
import AuthContext from "../../context/AuthContext";
import ImageGallery from "../ImageGallery/ImageGallery";

function withToast(Component) {
  return function WrappedComponent(props) {
    const toastFuncs = useToasts();
    return <Component {...props} {...toastFuncs} />;
  };
}

class ViewCar extends Component {
  static contextType = AuthContext;

  static propTypes = {
    match: PropTypes.shape({
      params: PropTypes.shape({ uuid: PropTypes.string.isRequired }).isRequired,
    }).isRequired,
  };

  state = {
    isLoading: true,
    vehicle: {},
    showModal: false,
    luckyModal: false,
    special: false,
    clicked: false,
    modalError: false,
    forceReload: false,
    selectedCar: {
      uuid: "",
      ethId: "",
      name: "",
      fileName: "",
      borrower: "",
    },
  };

  componentDidMount() {
    this.fetchCar();
  }

  componentDidUpdate() {
    if (this.state.forceReload) {
      this.setState({ forceReload: false });
      this.fetchCar();
    }
  }

  fetchCar = () => {
    axios({
      method: "GET",
      url: `${API_URL}/vehicles/${this.props.match.params.uuid}`,
    })
      .then(({ data }) => {
        this.setState({
          vehicle: data,
          isLoading: false,
        });
      })
      .catch(() =>
        this.setState({
          isLoading: false,
        })
      );
  };

  getTopStat = (stats) =>
    Object.keys(stats).reduce((a, b) => (stats[a] > stats[b] ? a : b));

  truncate = (fullStr, strLen) => {
    if (fullStr.length <= strLen) return fullStr;

    const separator = "...";

    const sepLen = separator.length;
    const charsToShow = strLen - sepLen;
    const frontChars = Math.ceil(charsToShow / 2);
    const backChars = Math.floor(charsToShow / 2);

    return (
      fullStr.substr(0, frontChars) +
      separator +
      fullStr.substr(fullStr.length - backChars)
    );
  };

  getBadge = (gfb) => {
    switch (gfb) {
      case "Silver Select":
        return "silver-select";
      case "Black Elite":
        return "black-elite";
      default:
        return "";
    }
  };

  handleBorrowCar = (username, vehicleuuid) => {
    if (!username) {
      this.setState({
        modalError: true,
      });
      return;
    }

    axios({
      method: "POST",
      url: `${API_URL}/borrow-vehicles`,
      headers: { Authorization: this.context.token },
      data: {
        vehicle: vehicleuuid,
        borrower: username,
      },
    })
      .then(() => {
        this.props.addToast("Lent Successfully", {
          appearance: "success",
          autoDismiss: true,
        });
        this.setState({
          showModal: false,
          forceReload: true,
        });
      })
      .catch(() => {
        this.props.addToast("Incorrect Username", {
          appearance: "error",
          autoDismiss: true,
        });
      });
  };

  handleTakeBackCar = (vehicleuuid) => {
    axios({
      method: "POST",
      url: `${API_URL}/borrow-vehicles/cancel`,
      headers: { Authorization: this.context.token },
      data: {
        vehicle: vehicleuuid,
      },
    })
      .then(() => {
        this.props.addToast("Successfully Returned", {
          appearance: "success",
          autoDismiss: true,
        });
        this.setState({ forceReload: true });
      })
      .catch(() => {
        this.props.addToast("There was an error, please try again later", {
          appearance: "error",
          autoDismiss: true,
        });
      });
  };

  handleShowModalAndSetData = (uuid, ethId, name, fileName, borrower) => {
    this.setState({
      showModal: !this.state.showModal,
      modalError: false,
      selectedCar: {
        uuid,
        ethId,
        name,
        fileName,
        borrower,
      },
    });
  };

  handleCloseModal = () => {
    this.setState({
      showModal: !this.state.showModal,
    });
  };

  handleTryYourLuck = () => {
    this.setState({
      clicked: !this.state.clicked,
    });

    axios({
      method: "POST",
      url: `${API_URL}/vehicles/${this.props.match.params.uuid}/reveal`,
      headers: { Authorization: this.context.token },
    })
      .then(({ data }) => {
        this.setState(
          {
            special: data.special,
            luckyModal: true,
          },
          () => {
            this.setState({
              luckyModal: true,
            });
          }
        );
      })
      .catch((error) => console.log(error.message));
  };

  hideLuckyModal = () => {
    this.fetchCar();
    this.setState({
      luckyModal: false,
    });
  };

  render() {
    if (this.state.isLoading) {
      return <i className="fas fa-spinner fa-spin standalone-loading" />;
    }

    const {
      acceleration,
      braking,
      ethId,
      files,
      grip,
      handling,
      maxSpeed,
      model: {
        name,
        gen,
        minMaxSpeed,
        maxMaxSpeed,
        minAcceleration,
        maxAcceleration,
        minGrip,
        maxGrip,
        minHandling,
        maxHandling,
        minBraking,
        maxBraking,
      },
      wallet: { address, user },
      borrower,
      attributes,
      color,
      eventDrop,
    } = this.state.vehicle;

    let username = this.truncate(address, 10);
    if (user) {
      username = user.username;
    }

    let sanitizedName = name
      .toLowerCase()
      .replace(" ", "")
      .replace("é", "e");
    if (name === "Virtual Racer²") {
      sanitizedName = "vr2";
    }

    const topStat = this.getTopStat({
      acceleration,
      braking,
      handling,
      grip,
      maxSpeed,
    });

    const {
      showModal,
      special,
      luckyModal,
      selectedCar,
      modalError,
      clicked,
    } = this.state;

    return (
      <div className={`view-car ${sanitizedName}`}>
        {luckyModal ? (
          <EventModal
            color={color}
            special={special}
            hideLuckyModal={this.hideLuckyModal}
            clicked={clicked}
          />
        ) : null}
        {showModal ? (
          <Borrow
            error={modalError}
            car={selectedCar}
            onClickClose={this.handleCloseModal}
            onClickBorrow={this.handleBorrowCar}
            onClickTakeBack={this.handleTakeBackCar}
          />
        ) : null}
        <div className="header">
          <div>
            <i className="far fa-user" />
            {username}
          </div>
          {this.context.username === username && (
            <div className="certificate">
              <a
                href={`${API_URL}/vehicles/certificate/${
                  this.props.match.params.uuid
                }`}
              >
                <img alt="certificate" src="/images/certificate.svg" />
              </a>
            </div>
          )}
        </div>
        <div className="view-car-main-image">
          <img
            src={`${VEHICLES_IMG_PATH}${files[0].name
              .replace("abyssus_01_11_1_1", "abyssus_badge")
              .replace("abyssus_01_17_1_1", "abyssus_somnium_badge")
              .replace("vr2_01_11_1_1", "vr2_badge")
              .replace("neonroadster_01_36_1_1", "neonroadster_badge")}`}
            alt="main"
          />
        </div>
        <div className="view-car-header">
          <h1>{name}</h1>
          {attributes.find(
            (attr) => attr.trait_type === "Genesis Founder Badge"
          ) && (
            <div className="badge-container mb-5">
              <img
                className="badge-pc mt-2"
                src={`/images/badges/big-${this.getBadge(
                  attributes.find(
                    (attr) => attr.trait_type === "Genesis Founder Badge"
                  ).value
                )}.png`}
                alt="Genesis Founder Badge"
              />
              <img
                className="badge-mobile"
                src={`/images/badges/small-${this.getBadge(
                  attributes.find(
                    (attr) => attr.trait_type === "Genesis Founder Badge"
                  ).value
                )}.png`}
                alt="Genesis Founder Badge"
              />
            </div>
          )}
          <h2>
            <span>
              #{ethId.padStart(6, "0")} / Gen {parseInt(gen, 10) === 100 ? "Design Legends" : gen}
            </span>
          </h2>
          {this.context.username === username &&
            !borrower &&
            name === "Neon Roadster" &&
            eventDrop &&
            color !== 30 && (
              <>
                <div className="try-luck">
                  <p>
                    You can check out if you picked the ultra-rare Neon Roadster
                    Pink Beast car.
                  </p>
                </div>
                <button
                  className="big mb-4 mt-5 try-luck-btn"
                  onClick={this.handleTryYourLuck}
                >
                  Try your luck!
                </button>
              </>
            )}
          <hr className={`${name === "Abyssus" ? "gold-hr" : ""}`} />
          {this.context.username === username && !borrower && (
            <a
              href={SELL_CAR.replace(":eth-id", ethId)}
              target="_blank"
              rel="noopener noreferrer"
              className="button primary big"
            >
              Sell this car
            </a>
          )}
          {this.context.username === username && !borrower && (
            <a
              href={GIFT_CAR.replace(":eth-id", ethId)}
              target="_blank"
              rel="noopener noreferrer"
              className="button primary big gift"
            >
              Send as a gift
            </a>
          )}
          {this.context.username === username && (
            <div className="lend-container">
              {borrower ? (
                <button
                  className="button primary big lend"
                  onClick={() =>
                    this.handleTakeBackCar(this.props.match.params.uuid)
                  }
                >
                  Take Back
                </button>
              ) : (
                <button
                  className="button primary big lend"
                  onClick={() =>
                    this.handleShowModalAndSetData(
                      this.props.match.params.uuid,
                      ethId,
                      name,
                      files[0].name
                    )
                  }
                >
                  Lend
                </button>
              )}
            </div>
          )}
          {this.context.username !== username && (
            <a
              href={OPENSEA_VIEW_CAR.replace(":eth-id", ethId)}
              target="_blank"
              rel="noopener noreferrer"
              className="button primary big"
            >
              View in OpenSea
            </a>
          )}
        </div>
        <SplitSection
          title={MODELS[sanitizedName].title1}
          description={MODELS[sanitizedName].description1}
          image={`${VEHICLES_IMG_PATH}${files[1].name}`}
          theme="white"
          inverted
        />
        <SplitSection
          title={MODELS[sanitizedName].title2}
          description={MODELS[sanitizedName].description2}
          image={`${VEHICLES_IMG_PATH}${files[2].name}`}
          theme="white"
        />
        <div className="statistics">
          <h3>Statistics</h3>
          <div className="white left padding-vertical-small">
            <div className="center">
              <div className="stats">
                <div className="stats-image">
                  <span className="title">top view</span>
                  <hr />
                  <img alt="stats" src={MODELS[sanitizedName].images.stats} />
                </div>
                <div className="stats-chart">
                  <span className="title">stats</span>
                  <hr />
                  <StatBar
                    min={minMaxSpeed}
                    max={maxMaxSpeed}
                    name="Max Speed"
                    value={maxSpeed}
                  />
                  <StatBar
                    min={minAcceleration}
                    max={maxAcceleration}
                    name="acceleration"
                    value={acceleration}
                  />
                  <StatBar
                    min={minGrip}
                    max={maxGrip}
                    name="grip"
                    value={grip}
                  />
                  <StatBar
                    min={minBraking}
                    max={maxBraking}
                    name="braking"
                    value={braking}
                  />
                  <StatBar
                    min={minHandling}
                    max={maxHandling}
                    name="handling"
                    value={handling}
                  />
                </div>
              </div>
              <div className="description-block">
                <p className="title">
                  <span className="nominator">
                    {Math.floor(this.state.vehicle[topStat] / 10).toFixed(0)}.
                    {
                      `${(this.state.vehicle[topStat] / 10).toFixed(1)}`.split(
                        "."
                      )[1]
                    }
                  </span>
                  <span className="stat view-car mb-5">
                    {topStat.replace(/([A-Z])/g, " $1")}
                  </span>
                </p>
                <hr />
                <p className="description">
                  {MODELS[sanitizedName].description3}
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="gallery">
          <ImageGallery images={MODELS[sanitizedName].gallery} />
        </div>
      </div>
    );
  }
}

export default withToast(ViewCar);
