import React, { Component } from 'react';
import axios from 'axios';
import { RadioButton } from '@trendmicro/react-radio';
import { Link } from 'react-router-dom';
import { Redirect } from 'react-router';
import '@trendmicro/react-radio/dist/react-radio.css';
import Checkbox from 'react-simple-checkbox';
import './SignUp.css';
import { API_URL, 
  //FB_AUTH_URL 
} from '../../config/Config';
import {
  HOME, LOG_IN, MARKET, PRIVACY_POLICY, TERMS_OF_USE,
  ORIGIN_DESIGN_CHALLENGE, DESIGN_CHALLENGE_DASHBOARD,
} from '../../config/Routes';
import AuthContext from '../../context/AuthContext';
import SuccessMessage from '../SuccessMessage/SuccessMessage';

class SignUp extends Component {
  static contextType = AuthContext;

  state = {
    email: '',
    password: '',
    isCheckedTermsOfService: false,
    isCheckedMarketingUpdates: false,
    isCheckedEuResident: false,
    isLoading: false,
    errorMessage: '',
    registeredSuccess: false,
  };

  componentDidMount() {
    const urlParams = new URLSearchParams(window.location.search);
    const origin = urlParams.get('origin');
    if (origin !== null) {
      localStorage.setItem('origin', origin);
    }
  }

  isValidForm = () => {
    const validations = {
      isValidEmail: true,
      isValidPassword: true,
      isTrueTermsOfService: true,
    };

    if (this.state.email === '') {
      validations.isValidEmail = false;
    }

    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    if (!re.test(this.state.email)) {
      validations.isValidEmail = false;
    }

    if (this.state.isCheckedTermsOfService === false) {
      validations.isTrueTermsOfService = false;
    }

    if (this.state.password.length < 8) {
      validations.isValidPassword = false;
    }

    return !(Object.values(validations).indexOf(false) > -1);
  };

  registerUserHandler = (e) => {
    e.preventDefault();
    if (this.state.isLoading) {
      return;
    }

    this.setState({ errorMessage: '' });
    if (!this.isValidForm()) {
      this.setState({ errorMessage: 'Please provide a valid email address, password and accept the terms and condition' });

      return;
    }

    const origin = localStorage.getItem('origin');

    const data = {
      password: this.state.password,
      email: this.state.email,
      marketingUpdates: this.state.isCheckedMarketingUpdates,
      isEuResident: this.state.isCheckedEuResident,
      designChallenge: origin === ORIGIN_DESIGN_CHALLENGE,
    };

    this.setState({ isLoading: true });

    axios({
      method: 'POST',
      url: `${API_URL}/users`,
      data,
    })
      .then(() => this.setState({
        isLoading: false,
        registeredSuccess: true,
      }))
      .catch((error) => {
        this.setState({
          errorMessage: error.response.data.message,
          isLoading: false,
        });
      });
  };

  handleChangeEmail = e => this.setState({ email: e.target.value });

  handleChangePassword = e => this.setState({ password: e.target.value });

  handleChangeEuResident = e => this.setState({
    isCheckedEuResident: e.target.value === 'true',
  });

  toggleTermsOfService = () => this.setState(prevState => ({
    isCheckedTermsOfService: !prevState.isCheckedTermsOfService,
  }));

  toggleMarketingUpdates = () => this.setState(prevState => ({
    isCheckedMarketingUpdates: !prevState.isCheckedMarketingUpdates,
  }));

  render() {
    if (this.context.authenticated) {
      return <Redirect to={HOME} />;
    }

    const origin = localStorage.getItem('origin');
    if (this.state.registeredSuccess && origin === ORIGIN_DESIGN_CHALLENGE) {
      return <Redirect to={DESIGN_CHALLENGE_DASHBOARD} />;
    }

    if (this.state.registeredSuccess) {
      return (
        <SuccessMessage
          title="Welcome to CryptoMotors!"
          message={[
            <p key="p1">Check your email address to confirm your account.</p>,
          ]}
          link={MARKET}
          linkText="Get my first CryptoMotor!"
        />
      );
    }

    return (
      <div className="sign-in">
        <div className="center">
          <h1>Sign up</h1>
          <form onSubmit={this.registerUserHandler}>
            <p>
              <input
                type="email"
                name="email"
                placeholder="Email"
                onChange={this.handleChangeEmail}
                defaultValue={this.state.email}
                required="required"
              />
            </p>
            <p>
              <input
                type="password"
                name="password"
                placeholder="Password (at least 8 characters long)"
                onChange={this.handleChangePassword}
                defaultValue={this.state.password}
                minLength={8}
              />
            </p>
            <p className="eu-resident">
              Are you an EU resident?
            </p>
            <RadioButton
              label="No"
              name="eu"
              value="false"
              onChange={this.handleChangeEuResident}
              defaultChecked
            />
            <RadioButton
              label="Yes"
              name="eu"
              value="true"
              onChange={this.handleChangeEuResident}
            />
            <hr />
            <div className="legal">
              <Checkbox
                name="terms"
                size={2}
                borderThickness={1}
                tickSize={3}
                color="#B18F4F"
                checked={this.state.isCheckedTermsOfService}
                onChange={this.toggleTermsOfService}
              />
                  I agree to the
              {' '}
              <Link to={PRIVACY_POLICY}>privacy</Link>
              {' '}
                  and
              {' '}
              <Link to={TERMS_OF_USE}>policy terms</Link>
                  .
            </div>
            <div>
              <Checkbox
                name="marketing"
                size={2}
                borderThickness={1}
                tickSize={3}
                color="#B18F4F"
                checked={this.state.isCheckedMarketingUpdates}
                onChange={this.toggleMarketingUpdates}
              />
              I want to get marketing updates.
            </div>
            <p className="error-message">
              {this.state.errorMessage}
            </p>
            <div className="sign-in-div">              
              <button type="submit" className="primary" onClick={this.registerUserHandler}>
                {this.state.isLoading
                  ? <i className="fas fa-spinner fa-spin" />
                  : 'Sign up'
                    }
              </button>
            </div>
          </form>
          <div className="already-have-account">
            Already have an account? Log in
            {' '}
            <Link className="tertiary" to={LOG_IN}>here</Link>
          </div>
        </div>
      </div>
    );
  }
}

export default SignUp;
