import React, { useContext } from 'react';
import { Redirect } from 'react-router';
import axios from 'axios';
import AuthContext from '../../context/AuthContext';
import SuccessMessage from '../SuccessMessage/SuccessMessage';
import {
  DESIGN_CHALLENGE,
  DESIGN_CHALLENGE_DASHBOARD,
  ORIGIN_DESIGN_CHALLENGE
} from '../../config/Routes';
import './RegisterSuccess.css';
import { API_URL } from '../../config/Config';

const sendWelcomeEmail = token => axios({
  method: 'GET',
  headers: { Authorization: token },
  url: `${API_URL}/users/challenges/welcomeEmail`,
});

const RegisterSuccess = () => {
  const authContext = useContext(AuthContext);
  const { authenticated, token } = authContext;

  if (!authenticated && localStorage.getItem('origin') !== ORIGIN_DESIGN_CHALLENGE) {
    return <Redirect to={DESIGN_CHALLENGE_DASHBOARD} />;
  }

  if (authenticated) {
    sendWelcomeEmail(token);
  }

  localStorage.removeItem('origin');
  localStorage.setItem('designChallengeRegistered', 'true');

  return (
    <div className="design-challenge-register-success">
      <SuccessMessage
        title="Welcome to our Design Challenge!"
        message={[
          <p key="p1" className="first-p">
            We&apos;re glad to have you on board.
            {!authenticated && ' We\'ve sent you an email to confirm your identity.'}
          </p>,
          <p key="p2" className="text-left">
          You are now registered to the CryptoMotors Design Challenge.
          The uploading of the designs will start the
            {' '}
            <b>15th of July</b>
          . We’ll send you an email to
          remind you and with the link to upload your files
          </p>,
        ]}
        link={DESIGN_CHALLENGE}
        linkText="Go back to challenge"
      />
    </div>
  );
};

export default RegisterSuccess;
