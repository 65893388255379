import React, { useEffect, useRef } from "react";
import { isMobile } from "react-device-detect";
import "./EventModal.css";

const EventModal = ({ color, special, hideLuckyModal, clicked }) => {
  const videoRef = useRef();
  useEffect(() => {
    handlePlayVideo();
  }, [clicked]);

  const handlePlayVideo = () => {
    if (isMobile) {
      if (videoRef.current) {
        videoRef.current.webkitEnterFullscreen();
      }
    }
  };

  const BASE_URL_NOT_SPECIAL =
    "https://gateway.pinata.cloud/ipfs/QmRdQ5FyNtHFhnVRBbzJucCwHSbamsmqoss6Uwhgh8jnmd/";
  const BASE_URL_SPECIAL =
    "https://gateway.pinata.cloud/ipfs/QmRdQ5FyNtHFhnVRBbzJucCwHSbamsmqoss6Uwhgh8jnmd/special_";

  const handleEndVideo = () => {
    hideLuckyModal();
  };

  return (
    <div className="video-modal-container">
      <div className="background-modal" />
      <div className="modal-inner-box">
        <video
          ref={videoRef}
          onEnded={handleEndVideo}
          autoPlay
          poster={`${
            special ? BASE_URL_SPECIAL : BASE_URL_NOT_SPECIAL
          }${color}.mp4`}
          id="bgvid"
        >
          <source
            src={`${
              special ? BASE_URL_SPECIAL : BASE_URL_NOT_SPECIAL
            }${color}.mp4`}
            type="video/mp4"
          />
        </video>
      </div>
    </div>
  );
};

export default EventModal;
