import React from 'react';
import './NotFound.css';

const NotFound = () => (
  <div className="not-found">
    <h1>404</h1>
    <h2>Page not found</h2>
    <h3>Sorry, the page your are trying to load can not be found</h3>
  </div>
);

export default NotFound;
