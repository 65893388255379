import React from 'react';
import styled from 'styled-components';
import MarketMenu from '../MarketMenu/MarketMenu';
import Loading from '../Loading/Loading';

const Container = styled.div`
  background-color: #F0EFEF;
  height: 100%;
  display: flex;
  flex-direction: column;
`;

const IframeContainer = styled.div`
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

class Auctions extends React.Component {
  state = {
    loading: true,
  };

  hideLoading = () => {
    this.setState({ loading: false });
  };

  //OLD IFRAME SRC: "//opensea.io/assets/cryptomotors?embed=true&ref=0xe8bf704e1e27067c664177a851166021e96c1071&toggle%5Bon_auction%5D=true"
  // FROM -> (old) "toggle%5Bon_auction%5D=true" -> TO -> (new) "search[toggles][0]=ON_AUCTION"
  render() {
    return (
      <Container>
        <MarketMenu isAuctions />
        <IframeContainer>
          {this.state.loading && <Loading />}
          <iframe
            src="//opensea.io/assets/cryptomotors?embed=true&ref=0xe8bf704e1e27067c664177a851166021e96c1071&search[toggles][0]=ON_AUCTION"
            width="100%"
            height="100%"
            frameBorder="0"
            title="market"
            onLoad={this.hideLoading}
            style={{ display: this.state.loading ? 'none' : 'flex' }}
            allowFullScreen
          />
        </IframeContainer>
      </Container>
    );
  }
}

export default Auctions;
