import React, { useState } from 'react';
import '../Game/css/bootstrap.min.css';
import { VEHICLES_IMG_PATH } from '../../config/Config';

const Borrow = ({car, onClickClose, onClickBorrow, error}) => {

    const [username, setUsername] = useState('');

    const handleUsernameState = e => {
        setUsername(e.target.value);
    }
    
    const {uuid, ethId, name, fileName} = car;
    
    return(
        <div>
            <div className="modal-back"  onClick={() => onClickClose()}>
            </div>            
            <div className="modal">
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">Lend Car</h5>
                            <button 
                                type="button" 
                                className="close" 
                                data-dismiss="modal" 
                                aria-label="Close"
                                onClick={() => onClickClose()}
                            >
                            <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <div className="modal-body-container">                                
                                <input 
                                    type="text" 
                                    name="username" 
                                    placeholder="User name"
                                    onChange={(e) => handleUsernameState(e)}
                                    value={username}
                                    className={`${error ? "required" : null}`}
                                />
                                <div className="modal-body-text">
                                    You can lend your car to other user.
                                    The NFT will never leave your wallet.                                    
                                </div>
                            </div>
                            <div className="modal-body-img">
                                <span className="car-name">{name}</span>
                                <img alt={fileName} src={`${VEHICLES_IMG_PATH}${fileName}`}/>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <div className="button-container">
                                <button 
                                    type="button" 
                                    className="btn btn-secondary"
                                    onClick={() => onClickBorrow(username, uuid)}
                                >Lend</button>
                            </div>
                            <div className="model-number-container">
                                <div className="model-number">
                                    #
                                    {ethId.padStart(6, '0')}
                                </div>
                            </div>                 
                        </div>
                    </div>
                </div>
            </div>                 
        </div>
    );     
};

export default Borrow;