import { PIVOT_CENTER } from "./RenderPivots";
import Rectangle from "../../Helper/Rectangle";
import Vector2 from "../../Helper/Vector2";

export default class WorldSapceRenderer {
  renderManager = null;
  canvasContext = null;

  renderPosition = new Vector2(0, 0);
  imagePosition = new Vector2(0, 0);
  cacheRect = new Rectangle(0, 0, 0, 0);

  constructor(renderManager, canvasContext) {
    this.renderManager = renderManager;
    this.canvasContext = canvasContext;
  }

  render(renderLayers, worldSpaceRect) {
    this.renderManager.getRenderStack().forEach((renderData) => {
      if (
        renderLayers.includes(renderData.layer) === false ||
        renderData.ui === true
      ) {
        return;
      }

      if (renderData.image) {
        this.renderImageInWorldSpace(renderData, worldSpaceRect);
      }

      if (renderData.text) {
        this.renderTextInWorldSpace(renderData, worldSpaceRect);
      }

      if (renderData.line) {
        this.renderLineInWorldSpace(renderData, worldSpaceRect);
      }
    });
    this.renderManager.clearRenderStack();
  }

  renderImageInWorldSpace(renderData, worldSpaceRect) {
    if (this.isInsideWorldSpaceRect(worldSpaceRect, renderData) === false) {
      return;
    }

    this.updateRenderPosition(renderData, worldSpaceRect);
    this.imagePosition.set(0, 0);
    this.canvasContext.save();

    if (renderData.rotation) {
      this.canvasContext.translate(
        this.renderPosition.x + renderData.width / 2,
        this.renderPosition.y + renderData.height / 2
      );
      this.imagePosition.set(-renderData.width / 2, -renderData.height / 2);
      this.canvasContext.rotate((renderData.rotation * Math.PI) / 180);
    } else {
      this.canvasContext.translate(
        this.renderPosition.x,
        this.renderPosition.y
      );
      this.imagePosition.x = renderData.flipHorizontal
        ? -renderData.width
        : this.imagePosition.x;
      this.imagePosition.y = renderData.flipVertical
        ? -renderData.height
        : this.imagePosition.y;
    }

    this.canvasContext.imageSmoothingEnabled = renderData.smooth;

    this.canvasContext.scale(
      renderData.flipHorizontal ? -1 : 1,
      renderData.flipVertical ? -1 : 1
    );

    if (renderData.slice !== undefined && renderData.slice !== null) {
      this.canvasContext.drawImage(
        renderData.image,
        renderData.slice.x,
        renderData.slice.y,
        renderData.slice.width,
        renderData.slice.height,
        this.imagePosition.x,
        this.imagePosition.y,
        renderData.width,
        renderData.height
      );
    } else {
      this.canvasContext.drawImage(
        renderData.image,
        this.imagePosition.x,
        this.imagePosition.y,
        renderData.width,
        renderData.height
      );
    }

    this.canvasContext.restore();
  }

  renderTextInWorldSpace(renderData, worldSpaceRect) {
    this.updateRenderPosition(renderData, worldSpaceRect);

    this.canvasContext.save();

    const font = [
      renderData.bold ? "bold" : "",
      renderData.italic ? "italic" : "",
      renderData.textSize + "px",
      renderData.font,
    ];

    this.canvasContext.font = font.join(" ");
    this.canvasContext.fillStyle = renderData.color;

    if (Array.isArray(renderData.text)) {
      renderData.text.forEach((text, index) => {
        this.canvasContext.fillText(
          text,
          this.renderPosition.x,
          this.renderPosition.y +
            (renderData.lineSeparation + renderData.textSize) * index
        );
      });
    } else {
      this.canvasContext.fillText(
        renderData.text,
        this.renderPosition.x,
        this.renderPosition.y
      );
    }

    this.canvasContext.restore();
  }

  renderLineInWorldSpace(renderData, worldSpaceRect) {
    this.canvasContext.save();

    this.canvasContext.beginPath();
    this.canvasContext.strokeStyle = renderData.lineColor;
    this.canvasContext.lineWidth = renderData.lineWidth;

    this.renderPosition.set(renderData.lineFrom.x, renderData.lineFrom.y);
    this.updatePositionForViewport(this.renderPosition, worldSpaceRect);
    this.canvasContext.moveTo(this.renderPosition.x, this.renderPosition.y);

    this.renderPosition.set(renderData.lineTo.x, renderData.lineTo.y);
    this.updatePositionForViewport(this.renderPosition, worldSpaceRect);
    this.canvasContext.lineTo(this.renderPosition.x, this.renderPosition.y);

    this.canvasContext.stroke();

    this.canvasContext.restore();
  }

  isInsideWorldSpaceRect(worldSpaceRect, renderData) {
    this.cacheRect.set(
      renderData.position.x,
      renderData.position.y,
      renderData.width,
      renderData.height
    );

    return worldSpaceRect.overlappingRectangle(this.cacheRect);
  }

  updateRenderPosition(renderData, worldSpaceRect) {
    this.renderPosition.set(renderData.position.x, renderData.position.y);
    this.updatePositionForViewport(this.renderPosition, worldSpaceRect);
  }

  updatePositionForViewport(position, worldSpaceRect) {
    position.x = Number((position.x - worldSpaceRect.x).toFixed(0));
    position.y = Number((worldSpaceRect.y - position.y).toFixed(0));
  }
}
