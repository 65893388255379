import React, { Component } from 'react';
import axios from 'axios';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { API_URL } from '../../config/Config';
import { LOG_IN } from '../../config/Routes';
import './RecoverPassword.css';


class ResetPassword extends Component {
  static propTypes = {
    match: PropTypes.shape({
      params: PropTypes.shape({ token: PropTypes.string.isRequired }).isRequired,
    }).isRequired,
  };

  state = {
    isLoading: false,
    errorMessage: '',
    isSuccess: false,
  };

  sendRecoverPasswordRequest = (e) => {
    e.preventDefault();
    if (this.state.isLoading) {
      return;
    }

    this.setState({ isLoading: true });

    const password = e.target.password.value;
    const confirmPassword = e.target.confirmPassword.value;

    if (password !== confirmPassword) {
      e.target.password.setCustomValidity('The given passwords don\'t match.');

      return;
    }

    const { token } = this.props.match.params;

    axios({
      method: 'POST',
      url: `${API_URL}/password-recovery/reset`,
      data: {
        password,
        passwordToken: token,
      },
    })
      .then(() => this.setState({
        isLoading: false,
        isSuccess: true,
      }))
      .catch(() => {
        this.setState({
          errorMessage: 'Your reset password link is expired',
          isLoading: false,
        });
      });
  };

  render() {
    const { errorMessage, isLoading, isSuccess } = this.state;

    return (
      <div className="recover-password" onSubmit={this.sendRecoverPasswordRequest}>
        {!isSuccess ? (
          <div>
            <h1>Reset your password</h1>
            <hr />
            <p>
            Please enter your new password
            </p>
            <form>
              <div>
                <input type="password" name="password" required placeholder="New Password" minLength={8} />
              </div>
              <div>
                <input type="password" name="confirmPassword" required placeholder="Confirm Password" minLength={8} />
              </div>
              <div className="error-message">
                { errorMessage }
              </div>
              <div>
                <button type="submit" className="primary">
                  {isLoading
                    ? <i className="fas fa-spinner fa-spin" />
                    : 'Send'
                  }
                </button>
              </div>
            </form>
          </div>
        ) : (
          <div>
            <h1>Password updated!</h1>
            <hr />
            <p>
            Your password has been successfully updated. Click the button below to log in.
            </p>
            <Link to={LOG_IN} className="button primary">Log in now</Link>
          </div>
        )}
      </div>
    );
  }
}

export default ResetPassword;
