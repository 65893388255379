import React from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import "./Video.css";
import { BrowserView } from "react-device-detect";
import { EVENT_DROP } from "../../config/Routes";

const VIDEO_HOME_TEASER = "/video/home-video-teaser.mp4";

const Video = ({ showArrow }) => (
  <div id="videoDiv" className="video">
    <div id="videoBlock">
      <BrowserView>
        <video loop autoPlay muted poster={VIDEO_HOME_TEASER} id="bgvid">
          <source src={VIDEO_HOME_TEASER} type="video/mp4" />
        </video>
      </BrowserView>
      <div id="videoMessage">
        <div className="desktop">
          <h1 id="videoTitle">Design Legends GEN: Retro Rocket</h1>
          <h3>AVAILABLE ON OPENSEA</h3>

          {showArrow && (
            <div>
              <i className="fas fa-angle-down" />
            </div>
          )}
        </div>
        <div className="mobile">
          <h1>Design Legends GEN</h1>
          <h2>Retro Rocket</h2>
          {showArrow && (
            <div>
              <i className="fas fa-angle-down" />
            </div>
          )}
        </div>
      </div>
    </div>
  </div>
);

Video.propTypes = {
  showArrow: PropTypes.bool,
};

Video.defaultProps = {
  showArrow: true,
};

export default Video;
