import { EVENT_UPDATE } from "./Game";

export default class Component {
  id = null;
  active = true;
  gameObject = null;
  firstFrame = true;

  constructor() {
    window.addEventListener(EVENT_UPDATE, this.gameLoopEventHandler);
  }

  gameLoopEventHandler = (event) => {
    if (this.active === false) {
      return;
    }

    if (this.firstFrame === true) {
      this.start(event.detail);
      this.firstFrame = false;
    } else {
      this.update(event.detail);
    }
  };

  start() {
    // do nothing
  }

  update() {
    // do nothing
  }

  _destroy() {
    window.removeEventListener(EVENT_UPDATE, this.gameLoopEventHandler);

    Object.keys(this).forEach((key) => delete this[key]);
  }
}
